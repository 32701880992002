import _ from "lodash";
import * as yup from "yup";

export default t => yup.object().shape({
  oldPassword: yup.string().trim()
    .required(t("field_is_required"))
    .max(1000, "text_too_long"),
  newPassword: yup.string().trim()
    .required(t("field_is_required"))
    .max(1000, "text_too_long"),
  repeatNewPassword: yup.string().trim()
    .required(t("field_is_required"))
    .when("newPassword", (newPassword, schema) => schema.test("samePasswords", t("new_passwords_dont_match"), repeatNewPassword => _.isEqual(newPassword, repeatNewPassword)))
    .max(1000, "text_too_long"),
});
