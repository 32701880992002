/* eslint-disable no-restricted-syntax */
import _ from "lodash";

/**
 * Calls an array of passed funcDefs (check create-func-def)
 * @param {Array} funcDefs array of objects
 *
 * @returns {void} nothing
 */
export default funcDefs => {
  for (const item of funcDefs) {
    if (_.isNil(item.func) || !_.isFunction(item.func)) {
      return;
    }
    item.func(...item.args);
  }
};
