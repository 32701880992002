import {
  Alert, AlertTitle, Box,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { Trans, useTranslation } from "react-i18next";

import { userMeGet } from "../../../services/api/user";
import { GenericError, Loading } from "../../shared/other";
import StripeConnectButton from "./_stripe-connect-button";

export default React.memo(() => {
  const { t } = useTranslation();
  const [user, setUser] = useState({});
  const {
    loading, payload, error, status,
  } = useQuery(userMeGet());

  useEffect(() => {
    if (!loading && payload) {
      setUser(payload.data);
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  if (loading) {
    return <Box position="relative"><Loading /></Box>;
  }

  return (
    <Box display="flex" flexWrap="wrap">
      <Box flex="1 0 100%">
        <Alert severity="info">
          <AlertTitle>{t("finance_info_title")}</AlertTitle>
          <Box flex="1 0 100%">
            <Trans i18nKey="finance_info_desc">
              ...
              <a href="https://stripe.com/en-nl/pricing" target="_blank" rel="noreferrer">...</a>
              ...
            </Trans>
            <Box width="100%" marginTop="10px">
              {(_.isNil(user.stripe) || !user.stripe.onboardingSuccess) && (
                <StripeConnectButton />
              )}
            </Box>
          </Box>
        </Alert>
      </Box>
    </Box>
  );
});
