import { Box } from "@mui/material";
import React from "react";

import { MediaPreviewPanelItem } from "..";

export default ({ data, remove, coach }) => (
  <Box position="relative" width="100%">
    <Box display="flex" width="100%" flexWrap="wrap">
      {data.length > 0 && data.map(
        item => <MediaPreviewPanelItem item={item} key={item._id} remove={remove} coach={coach} />,
      )}
    </Box>
  </Box>
);
