import { Alert } from "@mui/material";
import React from "react";
import { Trans } from "react-i18next";

import { userMeGet, userMePut } from "../../../../services/api/user";
import { useFormEngine } from "../../../../services/hooks";
import { onSuccessCallbacks } from "../../../../services/utils";
import { MultilineTextField } from "../../../shared/fields";
import { FormDialogTemplate } from "../../../shared/templates";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";

const UserProfileAboutMeFormDialog = React.memo(({ actions, callback }) => {
  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userMePut, userMeGet, { aboutMe: "" }, formSchema(), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="about_me"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <MultilineTextField
            minRows={5}
            maxRows={15}
            label="your_description"
            fieldName="aboutMe"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <Alert severity="info"><Trans i18nKey="text_alert_info">test</Trans></Alert>
        </>
      )}
    />
  );
});

export default UserProfileAboutMeFormDialog;
