import AttachFileIcon from "@mui/icons-material/AttachFile";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({ func }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t("select")} disableTouchListener>
      <IconButton color="primary" size="small" onClick={func}>
        <AttachFileIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
};
