import EqualizerIcon from "@mui/icons-material/Equalizer";
// import NoteIcon from "@mui/icons-material/Note";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box, Paper, Tooltip, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import { entryDelete } from "../../../services/api/entry";
import { models } from "../../../services/enums";
// import { NoteEntryPreviewDialog } from "../../note";
import { ScoreEntryPreviewDialog } from "../../score";
import { ModelActionButton, PreviewButton } from "../../shared/buttons";
import callbackAfterDelete from "./_callback-after-delete";
import callbackWithEntry from "./_callback-with-entry";

export default React.memo(({
  entry, plan, idx, callback, chip = {}, canEdit = true, isShared = false,
}) => {
  const { t } = useTranslation();

  // const planIsNotCompetition = () => plan.interval !== intervals.COMPETITION;

  return (
    <Draggable
      key={`${entry._id}_${idx}`}
      draggableId={JSON.stringify({ entry, idx })}
      index={idx}
      style={{ width: "100%" }}
    >
      {provided => (
        <Box
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
        >
          <Paper style={{
            padding: 10, width: "calc(100% - 4px)", marginTop: 4, margin: 2,
          }}
          >
            <Typography variant="body1" style={{ width: "100%", fontWeight: 600, wordBreak: "break-word" }}>{entry.title}</Typography>
            <Typography variant="caption" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
              {entry.content}
            </Typography>
            <Box flexBasis="100%" flexGrow="1" />
            <Box style={{ marginTop: 10 }} display="flex" flexWrap="wrap">
              <Box flex="1">
                {chip}
              </Box>
              <Box width="100%" display="flex" flex="1" justifyContent="flex-end">
                {!_.isNil(entry.isHidden) && entry.isHidden && (
                <Tooltip title={t("entry_visible_only_for_you")} placement="bottom" disableTouchListener>
                  <VisibilityOffIcon size="small" color="disabled" />
                </Tooltip>
                )}
                <>
                  {!entry.isInfo && (
                  <PreviewButton
                    args={[entry, plan, 0, canEdit, isShared]}
                    previewDialog={ScoreEntryPreviewDialog}
                    icon={EqualizerIcon}
                    tooltipTitle="scores"
                  />
                  )}
                  {/* {planIsNotCompetition() && !isShared && !entry.isInfo && (
                  <PreviewButton
                    args={[entry._id]}
                    previewDialog={NoteEntryPreviewDialog}
                    icon={NoteIcon}
                    tooltipTitle="notes"
                  />
                  )} */}
                </>
                <ModelActionButton
                  type="duplicate"
                  variant="iconbutton"
                  model={models.ENTRY}
                  tooltip="create_duplicate"
                  modelData={entry}
                  fontSize="inherit"
                  buttonSize="small"
                  callback={callbackWithEntry(entry, callback)}
                />
                {canEdit && (
                <ModelActionButton
                  type="edit"
                  variant="iconbutton"
                  model={models.ENTRY}
                  tooltip="edit"
                  modelId={entry._id}
                  fontSize="inherit"
                  buttonSize="small"
                  callback={callbackWithEntry(entry, callback)}
                />
                )}
                {canEdit && (
                <ModelActionButton
                  remove={entryDelete}
                  modelId={entry._id}
                  fontSize="inherit"
                  buttonSize="small"
                  callback={callbackAfterDelete(entry, { planId: entry.planId }, callback)}
                />
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </Draggable>
  );
});
