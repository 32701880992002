import { useTheme } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../services/api";
import { entryDataPost, entryDraggablePut } from "../../../services/api/entry";
import { confirmOption } from "../../../services/utils";

export default reload => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { mutate, loading } = useMutation(apiAction);

  const update = async result => {
    if (_.isNil(result.destination)) {
      return;
    }
    const source = JSON.parse(result.source.droppableId);
    source.index = result.source.index;
    const destination = JSON.parse(result.destination.droppableId);
    destination.index = result.destination.index;
    const entry = JSON.parse(result.draggableId);
    let date = null;
    let options = null;

    if (_.isNil(destination.col)) {
      date = entry.entry.date;
      date.setHours(12);
      date.setUTCHours(12);
    } else {
      date = destination.col.iso;
      options = {
        isoStart: destination.col.isoStart,
        isoEnd: destination.col.isoEnd,
      };
    }
    const c = await confirmOption("choose", { t, theme, buttons: ["move", "copy"] });

    if (c === 0) {
      return;
    }
    let submitData = {};
    let params = {};

    if (c === 1) {
      submitData = {
        _id: entry.entry._id,
        date,
        order: destination.index + 1,
        planId: destination.plan._id,
        options,
      };
      params = entryDraggablePut(submitData);
    } else if (c === 2) {
      submitData = entry.entry;
      delete submitData._id;
      submitData.date = date;
      submitData.order = destination.index + 1;
      submitData.planId = destination.plan._id;
      params = entryDataPost(submitData);
    }
    const { error, status } = await mutate(...params);

    if (error && status === 403) {
      enqueueSnackbar(t("access_denied"), { variant: "error" });
    } else if (error) {
      enqueueSnackbar(t("generic_error"), { variant: "error" });
    } else {
      reload({ planId: source.plan._id }, { planId: destination.plan._id });
    }
  };

  const isUpdating = () => loading;

  return { update, isUpdating };
};
