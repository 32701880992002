import React from "react";
import { useTranslation } from "react-i18next";

import { competitorFilterGet } from "../../../services/api/competitor";
import { models } from "../../../services/enums";
import { DataPanelTemplate } from "../../shared/templates";
import { CompetitorsPanelItem } from "..";

export default React.memo(({ bgFrom, initData = {} }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      filterGet={competitorFilterGet}
      dataPrefix="competitors"
      addTooltip="add_competitor"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      filterDescs={{
        plan: { type: "hidden", value: initData.planId },
      }}
      model={models.COMPETITOR}
      panelItem={CompetitorsPanelItem}
      bgFrom={bgFrom}
      initData={initData}
    />
  );
});
