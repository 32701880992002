import { FormControl, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, min, max, fieldName, label, helper, size = "medium", sx = null,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={sx || inputStyle.select()}>
      <TextField
        type="number"
        error={isFieldError(fieldName)}
        helperText={fieldErrorMessage(fieldName) || t(helper)}
        label={t(label)}
        name={fieldName}
        value={form[fieldName]}
        onChange={e => setFieldValue(fieldName, e.target.value)}
        InputProps={{ inputProps: { max, min } }}
        size={size}
      />
    </FormControl>
  );
};
