import {
  Box, DialogActions, useMediaQuery, useTheme,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";

import { useOpenCloseControl } from "../../../../services/hooks";
import { FormDialogActions } from "../../other";

export default ({
  actions = {},
  title,
  content,
  buttons,
  titleButtons,
  maxWidth = "lg",
  forceFullScreen = false,
  breakpointPixels = null,
  onOpenCallbacks = [],
  onCloseCallbacks = [],
  confirmClose = false,
  isBoldTitle = false,
  titleOptions = {},
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(breakpointPixels || maxWidth));
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl(onOpenCallbacks, onCloseCallbacks, confirmClose);

  actions.open = () => handleOpen();
  actions.close = () => handleClose();

  return (
    <Dialog
      fullScreen={forceFullScreen ? true : fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>
        <Box display="flex">
          <Box flex="1 1 auto" fontWeight={isBoldTitle ? 800 : 200} sx={{ wordBreak: "break-word" }}>
            {t(title, titleOptions)}
          </Box>
          <Box>
            {titleButtons}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        {buttons}
        <FormDialogActions close={handleClose} />
      </DialogActions>
    </Dialog>
  );
};
