import HelpIcon from "@mui/icons-material/Help";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({ tooltip = "", buttonSize = "medium", fontSize = "small" }) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(tooltip)} placement="bottom" disableTouchListener>
      <div style={{ display: "inline-flex" }}>
        <IconButton disabled size={buttonSize}>
          <HelpIcon fontSize={fontSize} />
        </IconButton>
      </div>
    </Tooltip>
  );
};
