import { Box, Typography, useTheme } from "@mui/material";
import _ from "lodash";
import React from "react";
import Highlighter from "react-highlight-words";

export default ({ item, highlight = [] }) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography variant="body1" style={{ width: "100%", fontWeight: 600, wordBreak: "break-word" }}>
          <Highlighter
            highlightStyle={{ backgroundColor: theme.palette.action.selected }}
            searchWords={highlight}
            autoEscape
            textToHighlight={item.title}
          />
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
          <Highlighter
            highlightStyle={{ backgroundColor: theme.palette.action.selected }}
            searchWords={highlight}
            autoEscape
            textToHighlight={item.content}
          />
        </Typography>
      </Box>
      {!_.isEmpty(item.comment) && (
      <Box style={{ backgroundColor: theme.palette.divider }}>
        <Box style={{ margin: 5 }}>
          <Typography
            variant="caption"
            style={{
              whiteSpace: "pre-line", width: "100%", fontStyle: "italic", wordBreak: "break-word",
            }}
          >
            <Highlighter
              highlightStyle={{ backgroundColor: theme.palette.action.selected }}
              searchWords={highlight}
              autoEscape
              textToHighlight={item.comment}
            />
          </Typography>
        </Box>
      </Box>
      )}
    </>
  );
};
