import React from "react";

import { NotesPanel } from "../../../note";
import { PreviewDialogTemplate } from "../../../shared/templates";

export default React.memo(({ actions = {} }) => (
  <PreviewDialogTemplate
    actions={actions}
    title="notes"
    maxWidth="xs"
    breakpointPixels={450}
    content={<NotesPanel bgFrom="MuiDialog" overrideSm />}
  />
));
