/* eslint-disable camelcase */
import * as yup from "yup";

export default t => yup.object().shape({
  link__instagram: yup.string().trim()
    .url(t("invalid_url")),
  link__facebook: yup.string().trim()
    .url(t("invalid_url")),
  link__twitter: yup.string().trim()
    .url(t("invalid_url")),
  link__website: yup.string().trim()
    .url(t("invalid_url")),
});
