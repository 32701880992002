export default {
  USER: "user",
  PLAN: "plan",
  PROGRAM: "program",
  ENTRY: "entry",
  SCORE: "score",
  SUBSCRIPTION: "subscription",
  CUSTOMER: "customer",
  STATISTIC: "statistic",
  MEDIA: "media",
  BILL: "bill",
  MESSAGE: "message",
  NOTE: "note",
  COMPETITOR: "competitor",
  PLAN_SHARE: "planshare",
};
