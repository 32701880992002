import { Typography, useTheme } from "@mui/material";
import React from "react";
import Highlighter from "react-highlight-words";

export default ({ item, highlight = [] }) => {
  const theme = useTheme();

  return (
    <Typography variant="caption" style={{ width: "100%" }}>
      <Highlighter
        highlightStyle={{ backgroundColor: theme.palette.action.selected }}
        searchWords={highlight}
        autoEscape
        textToHighlight={item.competitor.email}
      />
    </Typography>
  );
};
