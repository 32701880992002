import _ from "lodash";

import { periods } from "../../../services/enums";
import { createFuncDef, formatPrice } from "../../../services/utils";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      order: data.order,
      name: data.name,
      interval: data.interval,
      shift: _.isNil(data.shift) ? 0 : data.shift,
      scoreShift: _.isNil(data.scoreShift) ? 0 : data.scoreShift,
      hidden: _.isNil(data.hidden) ? false : data.hidden,
      competitionNameHidden: _.isNil(data.competitionNameHidden) ? false : data.competitionNameHidden,
      hiddenDays: _.isNil(data.hiddenDays) ? false : data.hiddenDays,
      competitionDesc: _.isNil(data.competitionDesc) ? "" : data.competitionDesc,
      competitionCategories: _.isNil(data.competitionCategories) ? [] : data.competitionCategories,
      periodName: _.isNil(data.periodName) ? periods.DAY : data.periodName,
      terms: _.isNil(data.terms) ? null : data.terms,
      bg: _.isNil(data.bg) ? null : data.bg,
      mobileBg: _.isNil(data.mobileBg) ? null : data.mobileBg,
      price: _.isNil(data.price) ? 0 : formatPrice(data.price),
      inShop: _.isNil(data.inShop) ? false : data.inShop,
      shopDesc: _.isNil(data.shopDesc) ? "" : data.shopDesc,
    });
  }),
];
