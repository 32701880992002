import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { customerFilterGet } from "../../../services/api/customer";
import { models } from "../../../services/enums";
import { DataPanelTemplate } from "../../shared/templates";
import { CustomersPanelItem } from "..";

export default React.memo(({ use, bgFrom, options = { isCoach: false } }) => {
  const { t } = useTranslation();
  const metadata = {};

  return (
    <Box position="relative" width="100%">
      <DataPanelTemplate
        use={use}
        filterGet={customerFilterGet}
        dataPrefix="customers"
        addTooltip="add_customer"
        sortDescs={[
          { label: t("from_newest"), desc: "-createdAt" },
          { label: t("from_oldest"), desc: "createdAt" },
        ]}
        filterDescs={{
          isCoach: { label: t("show_only_coaches"), type: "boolean", value: options.isCoach },
        }}
        model={models.CUSTOMER}
        panelItem={CustomersPanelItem}
        bgFrom={bgFrom}
        parentMetadata={metadata}
      />
    </Box>
  );
});
