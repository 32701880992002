import React from "react";

/**
 * Custom anchor hook eg. for dropdowns.
 * @returns {Object} { anchorEl, setAnchor, removeAnchor }
 */
export default () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const setAnchor = event => {
    setAnchorEl(event.currentTarget);
  };
  const removeAnchor = () => {
    setAnchorEl(null);
  };

  return { anchorEl, setAnchor, removeAnchor };
};
