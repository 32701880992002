import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { MessageButton } from "../../message";
import { HelpButton, ModelActionButton } from "../../shared/buttons";

export default ({
  entry, user, plan, reload, queryArgs, canEdit = true,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography variant="caption" color={user.itsMe ? "primary" : "inherit"}>
          {t("tie_break")}
        </Typography>
      </Box>
      {canEdit && (
        <Box justifyContent="flex-end" display="flex">
          <HelpButton tooltip={entry.tieBreakDesc} buttonSize="small" fontSize="small" />
          <ModelActionButton
            type="add"
            buttonSize="small"
            fontSize="small"
            variant="iconbutton"
            model={models.SCORE}
            tooltip="add"
            callback={() => reload(queryArgs)}
            initData={{
              entryId: entry._id, scoreUnit: entry.tieBreakUnit, customerUserId: user._id, isTieBreak: true,
            }}
            options={{
              planInterval: plan?.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTimeCap: entry.isTimeCap,
            }}
          />
          <MessageButton entryId={entry._id} userName={createUserDisplayName(user)} subordinateId={user._id} />
        </Box>
      )}
    </Box>
  );
};
