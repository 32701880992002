import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Tooltip, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  enabled, titleEnabled = "", titleDisabled = "", disabled = false, buttonSize = "medium", fontSize = "small",
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (enabled === true) {
    return (
      <Tooltip title={t(titleEnabled)} placement="bottom" disableTouchListener>
        <div style={{ display: "inline-flex" }}>
          <IconButton disabled={disabled} size={buttonSize}>
            <CheckIcon fontSize={fontSize} style={{ color: theme.palette.success.light }} />
          </IconButton>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={t(titleDisabled)} placement="bottom" disableTouchListener>
      <div style={{ display: "inline-flex" }}>
        <IconButton disabled={disabled} size={buttonSize}>
          <CloseIcon fontSize={fontSize} style={{ color: theme.palette.error.light }} />
        </IconButton>
      </div>
    </Tooltip>
  );

};
