import _ from "lodash";

import { localStorageDict, roles } from "../../../services/enums";
import { rootUrl } from "../../../services/routes";
import { writeStorage } from "../../../services/utils";

export default (callback, history, roleBack) => () => {
  if (_.isFunction(callback)) {
    callback();
  }
  history.push(rootUrl());

  if (_.isNil(roleBack)) {
    writeStorage(localStorageDict.ROLE, roles.CUSTOMER);
    writeStorage(localStorageDict.ROLE_BACK, roles.COACH);
  } else {
    writeStorage(localStorageDict.ROLE, roles.COACH);
    writeStorage(localStorageDict.ROLE_BACK, null);
  }
};
