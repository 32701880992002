import { Chip } from "@mui/material";
import _ from "lodash";
import React from "react";

import { mediaDelete } from "../../../services/api/media";
import { models } from "../../../services/enums";
import { safeUpperCase } from "../../../services/utils";
import { PreviewButton } from "../../shared/buttons";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import { MediaPreviewDialog } from "..";

export default ({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={item.name} highlight={highlight} />}
    footer={(
      <ItemFooter
        item={item}
        callback={callback}
        use={use}
        model={models.MEDIA}
        modelDelete={mediaDelete}
        buttons={<PreviewButton args={[item]} previewDialog={MediaPreviewDialog} />}
        chip={<Chip padding="0" color="secondary" size="small" label={safeUpperCase(item.file.mime)} />}
      />
)}
  />
);
