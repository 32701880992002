import { Typography, useTheme } from "@mui/material";
import React from "react";
import Highlighter from "react-highlight-words";

export default ({
  name, highlight = [], fontWeight = 600, fontVariant = "body1",
}) => {
  const theme = useTheme();

  return (
    <Typography
      variant={fontVariant}
      style={{ width: "100%", fontWeight }}
    >
      <Highlighter
        highlightStyle={{ backgroundColor: theme.palette.action.selected }}
        searchWords={highlight}
        autoEscape
        textToHighlight={name}
      />
    </Typography>
  );
};
