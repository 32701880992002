import HelpIcon from "@mui/icons-material/Help";
import { Chip, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { userStripeStatusGet } from "../../../services/api/user";
import { GenericError, Loading } from "../../shared/other";

const ChipEnabled = ({ title }) => <Chip variant="filled" color="success" label={title} sx={{ margin: "2px" }} />;
const ChipDisabled = ({ title, tooltip }) => <Chip variant="filled" color="error" label={title} sx={{ margin: "2px" }} icon={<Tooltip placement="bottom" title={tooltip} disableTouchListener><HelpIcon /></Tooltip>} />;

export default () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const {
    loading, error, status, payload,
  } = useQuery(userStripeStatusGet());

  useEffect(() => {
    if (!loading && payload) {
      setData(payload);
    }
  }, [payload, loading]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <>
      {data && data.data.chargesEnabled && <ChipEnabled title={t("payments_enabled")} />}
      {data && data.data.payoutsEnabled && <ChipEnabled title={t("payouts_enabled")} />}
      {data && !data.data.chargesEnabled && <ChipDisabled title={t("payments_disabled")} tooltip={t("stripe_payments_issue_desc")} />}
      {data && !data.data.payoutsEnabled && <ChipDisabled title={t("payouts_disabled")} tooltip={t("stripe_payments_issue_desc")} />}
    </>
  );
};
