import {
  addMinutes, addWeeks, endOfWeek, format, isWithinInterval, startOfWeek,
} from "date-fns";
import _ from "lodash";
import * as R from "ramda";

export default (data, rows, locale) => {
  const sow = val => R.pipe(addWeeks, date => startOfWeek(date, { weekStartsOn: 1 }))(new Date(), val);
  const eow = val => R.pipe(addWeeks, date => endOfWeek(date, { weekStartsOn: 1 }))(new Date(), val);

  const schema = _(rows).map(val => ({
    "isoStart": sow(val).toISOString(),
    "isoEnd": eow(val).toISOString(),
    "iso": addMinutes(sow(val), 1).toISOString(),
    "startOfWeek": format(sow(val), "dd.MM.yyyy", { locale }),
    "endOfWeek": format(eow(val), "dd.MM.yyyy", { locale }),
    "sow": sow(val),
    "eow": eow(val),
    "present": val === 0,
    "items": [],
  }))
    .value();

  _(data).each(item => {
    _(schema).each(val => {
      if (isWithinInterval(new Date(item.date), { start: val.sow, end: val.eow })) {
        val.items.push(item);
      }
    });
  });

  _(schema).each(item => {
    item.items = _.orderBy(item.items, ["order"], ["asc"]);
  });

  Reflect.deleteProperty(schema, "sow");
  Reflect.deleteProperty(schema, "eow");

  return schema;
};
