import _ from "lodash";

import { createFuncDef } from "../../../services/utils";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      email: _.isNil(data.customer) ? "" : data.customer.email,
      note: _.isNil(data.note) ? "" : data.note,
    });
  }),
];
