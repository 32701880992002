import { createTheme } from "@mui/material";
import * as C from "@mui/material/colors";
import _ from "lodash";

import figureOutTheme from "./_figure-out-theme";

export default currentTheme => {
  const mode = figureOutTheme(currentTheme);

  return createTheme({
    palette: {
      mode,
      grey: {
        bg: _.isEqual(mode, "dark") ? "black" : "black",
      },
      primary: C.blue,
      secondary: C.pink,
      error: C.red,
      success: C.green,
      warning: C.yellow,
      background: {
        default: _.isEqual(mode, "dark") ? "rgb(18, 18, 18)" : "#fcfcfc",
      },
    },
    typography: {
      h1: { fontSize: "4rem", fontWeight: 600 },
      h2: { fontSize: "3rem", fontWeight: 500 },
      h3: { fontSize: "2rem" },
      h4: { fontSize: "1.8rem" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    components: {
      MuiIconButton: {
        styleOverrides: {
          sizeSmall: {
            padding: "3px !important",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {

          root: {
            backgroundImage: "none",
            backgroundColor: _.isEqual(mode, "dark") ? "#1e1e1e" : "#ffffff",
            boxShadow: _.isEqual(mode, "dark") ? "0px 0px 2px 0px rgb(100 100 100)" : "0px 0px 2px 0px rgb(117 117 117)",
          },
        },
      },
      MuiAlert: {
        styleOverrides: {

          root: {
            // backgroundImage: "none",
            // backgroundColor: _.isEqual(mode, "dark") ? "#1e1e1e" : "#ffffff",
            boxShadow: "none",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
            backgroundColor: _.isEqual(mode, "dark") ? "#212121" : "#ffffff !important",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingTop: "10px !important",
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
            backgroundColor: _.isEqual(mode, "dark") ? "#1e1e1e" : "#ffffff !important",
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            backgroundImage: "none",
            backgroundColor: _.isEqual(mode, "dark") ? "#1e1e1e" : "#ffffff !important",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            backgroundColor: _.isEqual(mode, "dark") ? "#383838 !important" : "#757575 !important",
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          positionStart: {
            backgroundColor: "black",
          },
        },
      },

    },
  });
};
