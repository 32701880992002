import { Button, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  return (
    <Link href="/"><Button>{t("login_to2")}</Button></Link>
  );
};
