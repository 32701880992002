import _ from "lodash";

import onload from "./_handle-capture-onload";

export default (t, enqueueSnackbar, setFieldValue, setFieldValues, setName, type = "image") => event => {
  setFieldValue("data", "");

  if (_.isNil(event.target.files[0])) {
    return;
  }

  if (event.target.files[0].size > 38500000) {
    enqueueSnackbar(t("file_too_large_max_x_mb"), { variant: "warning" });

    return;
  }
  const fileReader = new FileReader();
  fileReader.readAsDataURL(event.target.files[0]);
  fileReader.onload = onload(t, enqueueSnackbar, setFieldValues, type, setName, event.target.files[0]);
};
