import { Box, Paper } from "@mui/material";
import _ from "lodash";
import React from "react";

import { useSizeStyle } from "../../../../services/hooks";

export default ({
  header = null, body = null, footer = null, inModal = false, fullWidth = false, overrideSm = false, onClick = null,
}) => {
  const MainStyle = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 25%", lg: "0 0 20%", xl: "0 0 20%",
  }, overrideSm ? "sm" : null);
  const ContainerWidth = useSizeStyle({
    xs: "100%", sm: "100%", md: "25%", lg: "20%", xl: "20%",
  }, overrideSm ? "sm" : null);
  const InModalStyle = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 50%", lg: "0 0 25%", xl: "0 0 25%",
  }, overrideSm ? "sm" : null);
  const BoxWidth = useSizeStyle({
    xs: "100%", sm: "100%", md: "calc(100% - 5px)", lg: "calc(100% - 5px)", xl: "calc(100% - 5px)",
  }, overrideSm ? "sm" : null);

  const getFlex = () => {
    if (fullWidth) {
      return "0 0 100%";
    }

    return inModal ? InModalStyle.select() : MainStyle.select();
  };

  return (
    <Box display="flex" flex={getFlex()} flexWrap="wrap" flexDirection="column" width={ContainerWidth.select()}>
      <Paper
        variant="elevation"
        onClick={onClick}
        style={{
          padding: 10,
          width: BoxWidth.select(),
          marginTop: 5,
          flexGrow: "1",
          flexDirection: "column",
          display: "flex",
          wordBreak: "break-word",
          ...(_.isNil(onClick) ? {} : { cursor: "pointer" }),
        }}
      >
        {header}
        {body}
        <Box flexBasis="100%" flexGrow="1" />
        {!_.isNil(footer) && (
        <Box style={{ marginTop: 10 }} display="flex" flexWrap="nowrap">
          {footer}
        </Box>
        )}
      </Paper>
    </Box>
  );
};
