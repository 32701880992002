import { Button, DialogActions } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  close, cancel, submit, send, confirm,
}) => {
  const { t } = useTranslation();

  return (
    <DialogActions>
      {_.isFunction(close) && <Button onClick={close} color="secondary" aria-label="close">{t("close")}</Button>}
      {_.isFunction(cancel) && <Button onClick={cancel} color="secondary" aria-label="cancel">{t("cancel")}</Button>}
      {_.isFunction(submit) && <Button onClick={submit} color="primary" variant="contained" aria-label="save">{t("save")}</Button>}
      {_.isFunction(send) && <Button onClick={send} color="primary" variant="contained" aria-label="send">{t("send")}</Button>}
      {_.isFunction(confirm) && <Button onClick={confirm} color="primary" variant="contained" aria-label="confirm">{t("confirm")}</Button>}
    </DialogActions>
  );
};
