import React from "react";

export default (key, initialValue) => {
  const tryParse = value => {
    try {
      return JSON.parse(value);
    } catch {
      return value;
    }
  };

  const [storedValue, setStoredValue] = React.useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? tryParse(item) : initialValue;
  });

  const listener = e => {
    if (e.type === "onLocalStorageChange") {
      if (e.detail.key === key) {
        setStoredValue(tryParse(e.detail.value));
      }
    } else if (e.key === key) {
      setStoredValue(tryParse(e.newValue));
    }
  };

  React.useEffect(() => {
    window.addEventListener("onLocalStorageChange", listener);
    window.addEventListener("storage", listener);

    return () => {
      window.removeEventListener("onLocalStorageChange", listener);
      window.removeEventListener("storage", listener);
    };
  }, [key]);

  return [storedValue];
};
