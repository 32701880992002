import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import { IconButton, Tooltip } from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../../services/api";
import { userUpdateThemePut } from "../../../../services/api/user";
import { localStorageDict } from "../../../../services/enums";
import { useAuthenticated, useLocalStorage } from "../../../../services/hooks";
import { writeStorage } from "../../../../services/utils";

export default () => {
  const { t } = useTranslation();
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
  const { mutate } = useMutation(apiAction);
  const { isAuthenticated } = useAuthenticated();

  const isDark = () => _.isEqual(theme, localStorageDict.THEME.VALUES.DARK);

  useEffect(() => {
    // this use effect can be deleted after a week, when data from present users will be collected
    if (isAuthenticated()) {
      mutate(...userUpdateThemePut({ theme }));
    }
  }, []);

  return (
    <Tooltip title={isDark() ? t("switch_to_light_mode") : t("switch_to_dark_mode")} placement="bottom" disableTouchListener>
      <IconButton
        size="large"
        onClick={() => {
          const newTheme = isDark() ? localStorageDict.THEME.VALUES.LIGHT : localStorageDict.THEME.VALUES.DARK;

          if (isAuthenticated()) {
            mutate(...userUpdateThemePut({ theme: newTheme }));
          }

          writeStorage(
            localStorageDict.THEME.NAME, newTheme,
          );

          if (!_.isNil(window.ReactNativeWebView)) {
            window.ReactNativeWebView.postMessage(newTheme);
          }
        }}
      >
        {isDark() ? <Brightness5Icon /> : <Brightness4Icon />}
      </IconButton>
    </Tooltip>
  );
};
