import {
  Box, Divider, Typography, useTheme,
} from "@mui/material";
// import _ from "lodash";
import React from "react";
import Highlighter from "react-highlight-words";

export default ({ item, highlight = [] }) => {
  const theme = useTheme();

  return (
    <>
      <Box>
        <Typography variant="body1" style={{ width: "100%", fontWeight: 600, wordBreak: "break-word" }}>
          <Highlighter
            highlightStyle={{ backgroundColor: theme.palette.action.selected }}
            searchWords={highlight}
            autoEscape
            textToHighlight={item?.entry?.title ?? ""}
          />
        </Typography>
      </Box>
      <Box>
        <Typography variant="caption" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
          <Highlighter
            highlightStyle={{ backgroundColor: theme.palette.action.selected }}
            searchWords={highlight}
            autoEscape
            textToHighlight={item?.entry?.content ?? ""}
          />
        </Typography>
      </Box>
      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
      <Box>
        <Typography variant="body2" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
          <Highlighter
            highlightStyle={{ backgroundColor: theme.palette.action.selected }}
            searchWords={highlight}
            autoEscape
            textToHighlight={item?.content ?? ""}
          />
        </Typography>
      </Box>
    </>
  );
};
