import EqualizerIcon from "@mui/icons-material/Equalizer";
// import NoteIcon from "@mui/icons-material/Note";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Chip, Tooltip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { entryDelete } from "../../../services/api/entry";
import { models } from "../../../services/enums";
import { useCommonDateFormats } from "../../../services/hooks";
import { safeUpperCase } from "../../../services/utils";
// import { NoteEntryPreviewDialog } from "../../note";
import { ScoreEntryPreviewDialog } from "../../score";
import { ModelActionButton, PreviewButton } from "../../shared/buttons";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import Body from "./_body";

export default React.memo(({
  item, highlight = [], callback, use,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useCommonDateFormats();

  // const planIsNotCompetition = () => item.plan.interval !== intervals.COMPETITION;

  return (
    <ModelItemTemplate
      inModal={_.isFunction(use)}
      header={<ItemHeaderWithHighlight name={formatDate(item.date)} fontWeight={100} fontVariant="caption" />}
      body={<Body item={item} highlight={highlight} />}
      footer={(
        <ItemFooter
          item={item}
          callback={callback}
          use={use}
          buttons={(
            <>
              {!_.isNil(item.isHidden) && item.isHidden && (
              <Tooltip title={t("entry_visible_only_for_you")} placement="bottom" disableTouchListener>
                <VisibilityOffIcon size="small" color="disabled" />
              </Tooltip>
              )}
              <PreviewButton
                args={[item, item.plan]}
                previewDialog={ScoreEntryPreviewDialog}
                icon={EqualizerIcon}
                tooltipTitle="scores"
              />
              {/* {planIsNotCompetition() && (
              <PreviewButton
                args={[item._id]}
                previewDialog={NoteEntryPreviewDialog}
                icon={NoteIcon}
                tooltipTitle="notes"
              />
              )} */}
              <ModelActionButton
                type="duplicate"
                variant="iconbutton"
                model={models.ENTRY}
                tooltip="create_duplicate"
                modelData={item}
                fontSize="inherit"
                buttonSize="small"
                callback={callback}
              />
            </>
          )}
          modelDelete={entryDelete}
          model={models.ENTRY}
          chip={(
            <>
              <Chip padding="0" color="primary" size="small" label={safeUpperCase(item.plan.name)} />
              {!_.isNil(item.competitionCategory) && <Chip padding="0" color="secondary" size="small" label={safeUpperCase(item.competitionCategory)} />}
            </>
          )}
        />
      )}
    />
  );
});
