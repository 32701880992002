export default price => {
  const p = price.toString();
  switch (p.length) {
    case 1:
      return `0.0${p}`;
    case 2:
      return `0.${p}`;
    default:
      return `${p.slice(0, p.length - 2)}.${p.slice(p.length - 2, p.length)}`;
  }
};
