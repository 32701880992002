import EditIcon from "@mui/icons-material/Edit";
import {
  Box, IconButton, Tooltip, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { userUpdateBgDataPut } from "../../../../services/api/user";
import { useSizeStyle } from "../../../../services/hooks";
import { BgBox } from "../../../shared/boxes";
import { BackgroundImageFormDialog } from "../../../shared/dialogs";

const UserProfileBgBox = React.memo(({ user, callback, edit = true }) => {
  const actions = {};
  const { t } = useTranslation();
  const theme = useTheme();
  const EditPos = useSizeStyle({
    xs: {
      position: "absolute", top: -23, right: 23, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    sm: {
      position: "absolute", top: -23, right: 23, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    md: {
      position: "absolute", bottom: -23, right: 23, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    lg: {
      position: "absolute", bottom: -23, right: 23, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
    xl: {
      position: "absolute", bottom: -23, right: 23, backgroundColor: theme.palette.background.default, borderRadius: 25,
    },
  });

  return (
    <BgBox bg={_.isNil(user) ? null : user.bg}>
      {edit && (
      <Box style={EditPos.select()}>
        <Tooltip title={t("edit")} placement="bottom" disableTouchListener>
          <IconButton color="primary" component="span" onClick={() => actions.open()}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>
      )}
      <BackgroundImageFormDialog route={userUpdateBgDataPut} actions={actions} callback={callback} />
    </BgBox>
  );
});

export default UserProfileBgBox;
