import { Alert, Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { entryDataPost, entryDataPut, entryOneGet } from "../../../services/api/entry";
import { intervals, units } from "../../../services/enums";
import { useFormEngine, useSizeStyle } from "../../../services/hooks";
import { onSuccessCallbacks } from "../../../services/utils";
import { MediaPreviewPanel } from "../../media";
import {
  CheckboxField, CompetitionCategorySelectField, DateTimeField, MultilineTextField, NumberField, PlanField, StatisticField, TextField, UnitSelectField,
} from "../../shared/fields";
import { FormDialogTemplate } from "../../shared/templates";
import { EntryDateInput } from "..";
import AddMediaButton from "./_add-media-button";
import beforeSubmitFunc from "./_before-submit-func";
import CreateCopyButton from "./_create-copy-button";
import formInit from "./_form-init";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";
import PreviousEntriesButton from "./_previous-entries-button";
import removeMedia from "./_remove-media";

export default React.memo(({ actions, callback }) => {
  const leftFlex = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 33%", lg: "0 0 33%", xl: "0 0 33%",
  });
  const rightFlex = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 67%", lg: "0 0 67%", xl: "0 0 67%",
  });
  const scoreFieldFlex = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "1", lg: "1", xl: "1",
  });
  const { t } = useTranslation();

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage, isEditing,
  } = useFormEngine(actions, entryDataPost, entryDataPut, entryOneGet, formInit, formSchema(t),
    onSuccessCallbacks(callback), onLoadCallbacks, [], beforeSubmitFunc);

  return (
    <FormDialogTemplate
      maxWidth="md"
      isOpen={isOpen}
      isEditing={isEditing}
      onClose={handleClose}
      title="entry"
      titleButtons={(
        <>
          <AddMediaButton form={form} setFieldValue={setFieldValue} />
          <PreviousEntriesButton setFieldValues={setFieldValues} />
          {isEditing && <CreateCopyButton form={form} handleClose={handleClose} actions={actions} callback={callback} />}
        </>
)}
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <Box display="flex" flexWrap="wrap">
          <Box display="flex" flex={leftFlex.select()} flexDirection="column">
            <TextField
              label="title"
              fieldName="title"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
            <PlanField
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValues={setFieldValues}
            />
            <EntryDateInput
              onChange={setFieldValue}
              value={form.date}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              interval={form.planInterval}
            />
            {_.isEqual(intervals.COMPETITION, form.planInterval) && (
            <CompetitionCategorySelectField
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              setFieldValues={setFieldValues}
              isEditing={isEditing}
            />
            )}
            {_.includes([intervals.COMPETITION, intervals.STATIC], form.planInterval) && (
            <NumberField
              fieldName="day"
              label="period"
              min={1}
              max={9999}
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
            )}
            {isEditing && (
            <NumberField
              fieldName="order"
              label="order"
              min={1}
              max={100}
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
            )}
            {_.isEqual(form.planInterval, intervals.DAILY) && (
            <Box display="flex" flexWrap="wrap" width="100%">
              <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                <CheckboxField
                  fieldName="isInfo"
                  label="entry_as_info"
                  form={form}
                  setFieldValue={setFieldValue}
                  helper={t("entry_as_info_desc")}
                />
              </Box>
            </Box>
            )}
          </Box>
          <Box display="flex" flex={rightFlex.select()} flexDirection="column">
            <MultilineTextField
              fieldName="content"
              label="content"
              minRows={7}
              maxRows={15}
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
            {!form.isInfo && (
              <MultilineTextField
                fieldName="comment"
                label="comment"
                minRows={2}
                maxRows={5}
                form={form}
                isFieldError={isFieldError}
                fieldErrorMessage={fieldErrorMessage}
                setFieldValue={setFieldValue}
              />
            )}
          </Box>

          {_.isEqual(form.planInterval, intervals.COMPETITION) && (
          <Box display="flex" flexWrap="wrap" width="100%">
            <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
              <CheckboxField
                fieldName="isHidden"
                label="this_entry_is_hidden"
                form={form}
                setFieldValue={setFieldValue}
                helper={t("this_entry_will_not_be_shown_on_leaderboard")}
              />
            </Box>
          </Box>
          )}

          {_.isEqual(form.planInterval, intervals.COMPETITION) && (
          <Box display="flex" flexWrap="wrap" width="100%">
            <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
              <CheckboxField
                fieldName="isScoreHidden"
                label="scores_are_hidden"
                form={form}
                setFieldValue={setFieldValue}
                helper={t("scores_will_not_be_shown_on_leaderboard")}
              />
            </Box>
          </Box>
          )}

          {!form.isInfo && (
            <Box display="flex" flexWrap="wrap" width="100%">
              <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                <CheckboxField
                  fieldName="isScore"
                  label="add_score_to_this_workout_part"
                  form={form}
                  setFieldValue={setFieldValue}
                  disabled={_.isEqual(form.planInterval, intervals.COMPETITION)}
                />
              </Box>
              {form.isScore && (
                <Box display="flex" flexWrap="wrap" width="100%">
                  <Box flex={scoreFieldFlex.select()}>
                    <UnitSelectField
                      form={form}
                      isFieldError={isFieldError}
                      fieldErrorMessage={fieldErrorMessage}
                      setFieldValue={setFieldValue}
                      disabled={!_.isEmpty(form.statisticId)}
                    />
                  </Box>
                  <Box flex={scoreFieldFlex.select()}>
                    <StatisticField
                      form={form}
                      isFieldError={isFieldError}
                      fieldErrorMessage={fieldErrorMessage}
                      setFieldValues={setFieldValues}
                    />
                  </Box>
                  {_.isEqual(form.scoreUnit, units.TIME) && (
                  <Box width="100%">
                    <CheckboxField
                      fieldName="isTimeCap"
                      label="time_cap"
                      form={form}
                      setFieldValue={setFieldValue}
                      helper="time_cap_desc"
                    />
                  </Box>
                  )}
                  <Alert severity="info" style={{ marginBottom: 10 }}>
                    {t(_.isEqual(form.planInterval, intervals.COMPETITION) ? "entry_score_comment_competition" : "entry_score_comment")}
                  </Alert>

                  {_.isEqual(form.planInterval, intervals.COMPETITION) && (
                  <Box display="flex" flexWrap="wrap" width="100%">
                    <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                      <CheckboxField
                        fieldName="isTieBreak"
                        label="add_tie_break"
                        form={form}
                        setFieldValue={setFieldValue}
                      />
                    </Box>
                    {form.isTieBreak && (
                    <Box display="flex" flexWrap="wrap" width="100%">
                      <Box flex={scoreFieldFlex.select()}>
                        <UnitSelectField
                          fieldName="tieBreakUnit"
                          form={form}
                          isFieldError={isFieldError}
                          fieldErrorMessage={fieldErrorMessage}
                          setFieldValue={setFieldValue}
                          disabled={!_.isEmpty(form.statisticId)}
                        />
                      </Box>
                      <Box flex={scoreFieldFlex.select()}>
                        <TextField
                          fieldName="tieBreakDesc"
                          label="tie_break_desc"
                          form={form}
                          isFieldError={isFieldError}
                          fieldErrorMessage={fieldErrorMessage}
                          setFieldValue={setFieldValue}
                        />
                      </Box>
                      <Alert severity="info" style={{ marginBottom: 10 }}>
                        {t("tie_break_comment")}
                      </Alert>
                    </Box>
                    )}
                  </Box>
                  )}

                  {_.isEqual(form.planInterval, intervals.COMPETITION) && (
                  <Box display="flex" flexWrap="wrap" width="100%">
                    <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                      <CheckboxField
                        fieldName="isDeadline"
                        label="competitors_can_add_scores"
                        form={form}
                        setFieldValue={setFieldValue}
                        helper={t("competitors_can_add_scores_desc")}
                      />
                    </Box>
                    {form.isDeadline && (
                    <Box flex="1 0 100%">
                      <DateTimeField
                        fieldName="deadlineDate"
                        label="score_deadline"
                        form={form}
                        setFieldValue={setFieldValue}
                        isFieldError={isFieldError}
                        fieldErrorMessage={fieldErrorMessage}
                      />
                    </Box>
                    )}
                    {form.isDeadline && (
                    <Box flex="1 0 100%" style={{ marginBottom: 10 }}>
                      <CheckboxField
                        fieldName="isConfirmationRequired"
                        label="require_confirmation"
                        form={form}
                        setFieldValue={setFieldValue}
                        helper={t("require_confirmation_field_desc")}
                      />
                    </Box>
                    )}
                  </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
          <Box display="flex" flexWrap="wrap" width="100%">
            <MediaPreviewPanel data={form.media} remove={removeMedia(form, setFieldValue)} coach />
          </Box>
        </Box>
      )}
    />
  );
});
