import {
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { useCommonDateFormats } from "../../../services/hooks";
import GenericNotificationItem from "./_generic-notification-item";

export default memo(({ notif }) => {
  const { t } = useTranslation();
  const { formatDayAndMonth } = useCommonDateFormats();

  return (
    <GenericNotificationItem notif={notif} title={t("change_of_note_in", { plan: notif.metadata.planName })}>
      <Typography
        sx={{
          marginBottom: "5px", whiteSpace: "pre-line", width: "100%", wordBreak: "break-word",
        }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {notif.metadata.entryTitle}
        {" "}
        (
        {formatDayAndMonth(notif.metadata.entryDate)}
        )
        {" — "}
        <i>{notif.metadata.content}</i>
      </Typography>
    </GenericNotificationItem>
  );
});
