import _ from "lodash";
import React from "react";

import { statisticDelete } from "../../../services/api/statistic";
import { models } from "../../../services/enums";
import { PreviewButton } from "../../shared/buttons";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import { StatisticPreviewDialog } from "..";

export default ({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={item.name} highlight={highlight} />}
    footer={(
      <ItemFooter
        item={item}
        callback={callback}
        use={use}
        model={models.STATISTIC}
        modelDelete={statisticDelete}
        buttons={<PreviewButton args={[item._id]} previewDialog={StatisticPreviewDialog} />}
      />
)}
  />
);
