/* eslint-disable no-restricted-syntax */
import _ from "lodash";

/**
 * @param {object} obj any object
 *
 * @returns {object} processed obj
 */
export default obj => {
  let result = {};

  for (const key in obj) {
    const parts = key.split("__").reverse();
    let target = obj[key];

    while (parts.length > 0) {
      const part = parts.shift();
      target = { [part]: target };
    }
    result = _.merge(result, target);
  }

  return result;
};
