import {
  Box, Divider, List, Paper, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { noteFilterByEntryPublicGet } from "../../../../../services/api/note";
import { Loading } from "../../../../shared/other";
import UserNoteItem from "./_user-note-item";

export default React.memo(({ actions, entryId }) => {
  const { t } = useTranslation();
  const [notes, setNotes] = React.useState([]);
  const {
    loading, payload, query,
  } = useQuery(noteFilterByEntryPublicGet(entryId));

  actions.reloadNotes = () => query();

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.notes)) {
      setNotes(payload?.notes?.data || []);
    }
  }, [payload, loading]);

  if (notes.length === 0) {
    return null;
  }

  return (
    <Paper style={{
      marginBottom: "20px", paddingTop: 8, position: "relative", flexGrow: 1,
    }}
    >
      {loading && <Loading />}
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Typography variant="h3" style={{ fontWeight: 900, paddingTop: 8 }}>{t("public_notes")}</Typography>
        <Divider variant="middle" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
        <List style={{ width: "90%" }}>
          {_.map(notes, (item, idx) => (
            <UserNoteItem
              key={`${idx}_${item._id}`}
              item={item}
            />
          ))}
        </List>
      </Box>
    </Paper>
  );
});
