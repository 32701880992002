import React from "react";

import { useCommonDateFormats } from "../../../services/hooks";
import { ItemHeaderWithHighlight, ValueView } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import Body from "./_body";

export default React.memo(({
  item, highlight = [],
}) => {
  const { formatDate } = useCommonDateFormats();

  return (
    <ModelItemTemplate
      overrideSm
      header={<ItemHeaderWithHighlight name={formatDate(item.createdAt)} fontWeight={100} fontVariant="caption" />}
      body={<Body item={item} highlight={highlight} />}
      footer={<span style={{ width: "100%", textAlign: "right" }}><ValueView unit={item.scoreUnit} data={item} /></span>}
    />
  );
});
