import DateFnsUtils from "@date-io/date-fns";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import _ from "lodash";
import { SnackbarProvider } from "notistack";
import React from "react";
import { useQuery } from "react-fetching-library";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { LoadingGlobal } from "../../components/shared/other";
import { openAssetGet } from "../../services/api/other";
import { localStorageDict, roles } from "../../services/enums";
import {
  useAuthenticated, useLocalStorage, useLoggedUserLocale, useRoleValidator,
} from "../../services/hooks";
import {
  assetUrl, coachShopUrl, competitionUrl, emailChangeUrl, emailConfirmationUrl, newPasswordUrl, planTvUrl, registerUrl, remindPasswordUrl, stripeLoginUrl,
} from "../../services/routes";
// import { useInterval } from "react-interval-hook";
import { writeStorage } from "../../services/utils";
import {
  AppCoach, AppCustomer, CoachShop, Competition, ConfirmEmail, ConfirmEmailChange, Login, NewPassword, Register, RemindPassword, StripeLogin, Tv,
} from "..";
import createTheme from "./_create-theme";

export default React.memo(() => {
  const { isAuthenticated } = useAuthenticated();
  const { isRole } = useRoleValidator();
  const { getLoggedUserLocale } = useLoggedUserLocale();
  const [currentTheme] = useLocalStorage(localStorageDict.THEME.NAME, "");
  const [hostConfig] = useLocalStorage(localStorageDict.HOST_CONFIG, { name: "", website: "", features: [] });
  const { payload, loading } = useQuery(openAssetGet("config.json"));

  React.useLayoutEffect(() => {
    if (!loading && !_.isNil(payload?.name)) {
      writeStorage(localStorageDict.HOST_CONFIG, payload);

      if (_.isEmpty(currentTheme)) {
        writeStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
      }
    }
  }, [payload, loading]);

  return (
    <ThemeProvider theme={createTheme(_.isEmpty(currentTheme) ? localStorageDict.THEME.VALUES.LIGHT : currentTheme)}>
      <CssBaseline />
      <Helmet>
        <title>{hostConfig.name}</title>
        <meta charset="utf-8" />
        <link rel="icon" type="image/x-icon" href={assetUrl("favicon.ico")} />
        <link rel="icon" type="image/png" sizes="32x32" href={assetUrl("favicon-32x32.png")} />
        <link rel="icon" type="image/png" sizes="16x16" href={assetUrl("favicon-16x16.png")} />
        <link rel="apple-touch-icon" sizes="180x180" href={assetUrl("mobile.png")} />

        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" media="(prefers-color-scheme: light)" content="#ffffff" />
        <meta name="theme-color" media="(prefers-color-scheme: dark)" content="#303030" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="apple-mobile-web-app-title" content={hostConfig.name} />
        <meta name="application-name" content={hostConfig.name} />

        <link rel="mask-icon" href={assetUrl("safari-pinned-tab.svg")} color="#e50000" />
        <link rel="manifest" href={assetUrl("manifest.json")} />
        <meta name="msapplication-config" content={assetUrl("browserconfig.xml")} />
      </Helmet>

      {loading ? <LoadingGlobal /> : (
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={2000}
          onClick={e => {
            e.currentTarget.remove();
          }}
        >
          <BrowserRouter>
            <Switch>
              <Route path={planTvUrl()} exact component={Tv} />
              <Route path={competitionUrl()} exact component={Competition} />
              <Route path={coachShopUrl()} exact component={CoachShop} />
              <Route path={stripeLoginUrl()} exact component={StripeLogin} />
              <Route path={emailConfirmationUrl()} exact component={ConfirmEmail} />
              <Route path={emailChangeUrl()} exact component={ConfirmEmailChange} />
              <Route path={remindPasswordUrl()} exact component={RemindPassword} />
              <Route path={newPasswordUrl()} exact component={NewPassword} />
              <Route path={registerUrl()} exact component={Register} />
              <Route path="*">
                {isAuthenticated()
                  ? (
                    <LocalizationProvider dateAdapter={DateFnsUtils} adapterLocale={getLoggedUserLocale()}>
                      {isRole(roles.COACH) && <BrowserRouter><AppCoach /></BrowserRouter>}
                      {isRole(roles.CUSTOMER) && <BrowserRouter><AppCustomer /></BrowserRouter>}
                    </LocalizationProvider>
                  ) : <Login />}
              </Route>
            </Switch>
          </BrowserRouter>
        </SnackbarProvider>
      )}
    </ThemeProvider>
  );
});
