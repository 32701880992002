import { methods } from "../enums";
import { apiAction } from ".";

export const competitorDataPost = data => ["/competitor", methods.POST, data];
export const competitorSelfSignUpPost = data => ["/competitor/self-signup", methods.POST, data];
export const competitorDataPut = data => [`/competitor/${data._id}`, methods.PUT, data];
export const competitorDelete = id => [`/competitor/${id}`, methods.DELETE];
export const competitorOneGet = id => apiAction(`/competitor/one/${id}`);
export const competitorMeGet = id => apiAction(`/competitor/me/${id}`);
export const competitorFilterGet = (search = "", page = 1, limit = 100, sort = "", filter = "") => apiAction(`/competitor/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`);
