import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({ color, notrans = false, msg }) => {
  const { t } = useTranslation();

  return (
    <Paper style={{
      padding: 10,
      width: "100%",
      marginTop: 5,
      textAlign: "center",
      ...(color ? { color } : {}),
    }}
    >
      {notrans ? msg : t(msg)}
    </Paper>
  );
};
