import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";

export default (filterGet, limit = 10) => {
  const [params, setParams] = React.useState([]);
  const [metadata, setMetadataInner] = React.useState({ total: 0, limit, skip: 0 });
  const [searchWords, setSearchWords] = React.useState([]);
  const {
    loading, payload, query, error, status,
  } = useParameterizedQuery(filterGet, false);

  const update = (search, page, limit, sort, filter) => {
    query(search, page, limit, sort, filter);
    setSearchWords(_.without(search.split(" "), ""));
    setParams([search, page, limit, sort, filter]);
  };

  const setMetadata = React.useCallback(setMetadataInner, []);

  const reload = () => update(...params);

  return {
    loading, payload, error, status, update, reload, metadata, setMetadata, searchWords,
  };
};
