import {
  Button, useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { apiAction } from "../../../../services/api";
import { userUpdateRoleCoachPut } from "../../../../services/api/user";
import { localStorageDict } from "../../../../services/enums";
import { rootUrl } from "../../../../services/routes";
import { confirm, writeStorage } from "../../../../services/utils";

export default () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { loading, mutate } = useMutation(apiAction);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const switchtoCoachRole = async () => {
    const c = await confirm("switch_account_role_alert", { t, theme });

    if (c === true) {
      mutate(...userUpdateRoleCoachPut()).then(() => {
        history.push(rootUrl());
        writeStorage(localStorageDict.JWT, null);
        writeStorage(localStorageDict.ROLE, null);
        writeStorage(localStorageDict.ROLE_BACK, null);
        enqueueSnackbar(t("switch_account_role_ok"), { variant: "success" });
      });
    }
  };

  return (
    <Button disabled={loading} variant="contained" color="success" onClick={() => switchtoCoachRole()}>
      {t("switch_to_coach_account")}
    </Button>
  );
};
