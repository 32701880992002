import _ from "lodash";
import { useSnackbar } from "notistack";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../../services/api";

export default () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    loading, mutate, error, status,
  } = useMutation(apiAction);
  const [errors, setErrors] = React.useState([]);

  const duplicate = async (handleClose, callback, duplicateData) => {
    const { error, payload } = await mutate(...duplicateData);

    if (error) {
      enqueueSnackbar(t("duplicate_failed"), { variant: "error" });
      setErrors(payload.errors || []);
    } else {
      handleClose();
      callback();

      if (!_.isNil(payload) && !_.isNil(payload.message)) {
        enqueueSnackbar(t(payload.message), { variant: "success" });
      } else {
        enqueueSnackbar(t("duplicate_success"), { variant: "success" });
      }
    }
  };

  return {
    loading, error, status, duplicate, errors,
  };
};
