import { roles } from "../../services/enums";
import { getLocale } from "../../services/utils";

export default () => ({
  email: "",
  password: "",
  repeatPassword: "",
  timeZone: getLocale().timeZone,
  locale: getLocale().locale,
  language: getLocale().language,
  country: getLocale().country,
  terms: false,
  role: roles.COACH_NAME,
});
