import {
  addDays, addHours, addMonths, addWeeks, endOfMonth, endOfWeek, startOfMonth, startOfWeek,
} from "date-fns";

import { fixed, intervals } from "../enums";

/**
 * @param {String} interval value from intervals
 * @param {Number} type 0 - start of the period ; 1 - end of the period
 * @param {Date} date date to transform
 * @param {Number} val number of days,weeks,months to add (negative value is a substraction)
 *
 * @return {Date} transformed date
 */
export default (interval, type, date, val) => {
  switch (interval) {
    case intervals.DAILY: {
      const utcHours = date.getUTCHours();
      const d = type === 0 ? addHours(addDays(date, val), utcHours * -1) : addHours(addDays(date, val), 24 - utcHours);
      d.setMilliseconds(0);
      d.setSeconds(0);
      d.setMinutes(0);

      return d;
    }
    case intervals.WEEKLY:
      return type === 0 ? startOfWeek(addWeeks(date, val), { weekStartsOn: 1 }) : endOfWeek(addWeeks(date, val), { weekStartsOn: 1 });
    case intervals.MONTHLY:
      return type === 0 ? startOfMonth(addMonths(date, val)) : endOfMonth(addMonths(date, val));
    case intervals.COMPETITION:
    case intervals.STATIC:
      // starting date is fixed for good, please never change it as it is a starting point for calculations
      // it's done this way to avoid a lot of work just to satisfy a simple block view (without specific dates)
      return type === 0 ? addHours(addDays(new Date(fixed.STATIC_BLOCK_START_TIME), val), -12) : addHours(addDays(new Date(fixed.STATIC_BLOCK_START_TIME), val), 12);

    default:
      return new Date();
  }
};
