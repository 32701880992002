import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { units } from "../../../../services/enums";
import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, disabled, fieldName = "scoreUnit",
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()} disabled={disabled}>
      <InputLabel htmlFor="metric_unit">{t("metric_unit")}</InputLabel>
      <Select
        id="metric_unit"
        label={t("metric_unit")}
        onChange={e => setFieldValue(fieldName, e.target.value)}
        name={fieldName}
        value={form[fieldName] || ""}
        error={isFieldError(fieldName)}
      >
        <MenuItem value={units.WEIGHT}>{t("weight")}</MenuItem>
        <MenuItem value={units.TIME}>{t("time")}</MenuItem>
        <MenuItem value={units.DISTANCE}>{t("distance")}</MenuItem>
        <MenuItem value={units.ROUNDS}>{t("rounds")}</MenuItem>
        <MenuItem value={units.REPS}>{t("repetitions")}</MenuItem>
        <MenuItem value={units.DESCRIPTION}>{t("text_description")}</MenuItem>
      </Select>
      <FormHelperText error={isFieldError(fieldName)}>{fieldErrorMessage(fieldName)}</FormHelperText>
    </FormControl>
  );
};
