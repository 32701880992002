import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert, Box, List,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { notificationFilterGet } from "../../../services/api/notification";
import { notificationType } from "../../../services/enums";
import { GenericError, Loading } from "../../shared/other";
import EditSubordinateNoteForCoach from "./_edit-subordinate-note-for-coach";
import EditSubordinateScoreForCoach from "./_edit-subordinate-score-for-coach";
import NewCompetitorPurchaseCustomer from "./_new-competitor-purchase-customer";
import NewCompetitorPurchaseOwner from "./_new-competitor-purchase-owner";
import NewEntryMessage from "./_new-entry-message";
import NewPlanPurchaseCustomer from "./_new-plan-purchase-customer";
import NewPlanPurchaseOwner from "./_new-plan-purchase-owner";
import NewSubordinateNoteForCoach from "./_new-subordinate-note-for-coach";
import NewSubordinateScoreForCoach from "./_new-subordinate-score-for-coach";

export default React.memo(({ older = false }) => {
  const { t } = useTranslation();
  const [loadedNewest, setLoadedNewest] = useState(false);
  const [loadedOlder, setLoadedOlder] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [olderNotifications, setOlderNotifications] = useState([]);
  const {
    loading, error, status, payload, query,
  } = useParameterizedQuery(notificationFilterGet);

  useEffect(() => {
    query(1, 1000, JSON.stringify({ newest: true }));
  }, []);

  useEffect(() => {
    if (!loading && !error && !_.isNil(payload?.notifications?.data)) {
      if (loadedNewest) {
        setOlderNotifications(_(payload.notifications?.data || []).filter(notif => !_.includes(_.map(notifications, "_id"), notif._id)).value() || []);
        setLoadedOlder(true);
      } else {
        setNotifications(payload.notifications?.data || []);
        setLoadedNewest(true);
      }
    }
  }, [payload]);

  if (error) {
    return <GenericError status={status} />;
  }

  const renderNotif = notif => {
    switch (notif.type) {
      case notificationType.EDIT_SUBORDINATE_SCORE_FOR_COACH: {
        return <EditSubordinateScoreForCoach key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_SUBORDINATE_SCORE_FOR_COACH: {
        return <NewSubordinateScoreForCoach key={notif._id} notif={notif} />;
      }
      case notificationType.EDIT_SUBORDINATE_NOTE_FOR_COACH: {
        return <EditSubordinateNoteForCoach key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_SUBORDINATE_NOTE_FOR_COACH: {
        return <NewSubordinateNoteForCoach key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_PLAN_PURCHASE_CUSTOMER: {
        return <NewPlanPurchaseCustomer key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_PLAN_PURCHASE_OWNER: {
        return <NewPlanPurchaseOwner key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_COMPETITOR_PURCHASE_OWNER: {
        return <NewCompetitorPurchaseOwner key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_COMPETITOR_PURCHASE_CUSTOMER: {
        return <NewCompetitorPurchaseCustomer key={notif._id} notif={notif} />;
      }
      case notificationType.NEW_ENTRY_MESSAGE: {
        return <NewEntryMessage key={notif._id} notif={notif} />;
      }
      default: return null;
    }
  };

  return (
    <Box>
      {loading && <Loading />}
      <List sx={{ width: "100%" }}>
        {notifications.length === 0 && <Alert severity="info" icon={null}>{t("no_new_notifications")}</Alert>}
        {notifications.map(notif => renderNotif(notif))}
        {older && (
          <LoadingButton
            disabled={loadedOlder}
            loading={loading}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ marginTop: "20px", marginBottom: "20px", width: "100%" }}
            onClick={() => query(1, 1000)}
          >
            {t("load_older")}
          </LoadingButton>
        )}
        {olderNotifications.map(notif => renderNotif(notif))}
      </List>
    </Box>
  );
});
