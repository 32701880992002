import { format } from "date-fns";
import _ from "lodash";

export default (entries, setData) => {
  const data = {};

  _(entries).each(entry => {
    const day = format(new Date(entry.date), "yyyy-MM-dd");

    if (!_.has(data, day)) {
      data[day] = [];
    }
    data[day].push(entry);
  });

  setData(Object.values(data));
};
