import { useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { confirm, funcCaller } from "../utils";

/**
 * This hook adds an external controll eg. for modals
 * @param {Array} onOpenCallbacks Array of objects: { func: FUNCTION, args: [ARRAY OF ARGUMENTS]}
 * @param {Array} onCloseCallbacks Array of objects: { func: FUNCTION, args: [ARRAY OF ARGUMENTS]}
 * @param {Boolean} confirmClose If true, then confirmation dialog will be shown before close
 * which can be created by using createFuncDef from utils
 * @returns {Object} isOpen, handleOpen, handleClose
 */
export default (onOpenCallbacks = [], onCloseCallbacks = [], changed = false) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleOpen = () => {
    funcCaller(onOpenCallbacks);
    setOpen(true);
  };

  const handleClose = async () => {
    if (changed) {
      const c = await confirm("warning_you_will_lose_typed_data", { t, theme });

      if (c === true) {
        funcCaller(onCloseCallbacks);
        setOpen(false);
      }
    } else {
      funcCaller(onCloseCallbacks);
      setOpen(false);
    }
  };

  const handleCloseNoConfirm = () => {
    funcCaller(onCloseCallbacks);
    setOpen(false);
  };

  const isOpen = () => Boolean(open);

  return {
    isOpen, handleOpen, handleClose, handleCloseNoConfirm,
  };
};
