import {
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { useCommonDateFormats } from "../../../services/hooks";
import valueViewPlain from "../../user/UserPanelCustomer/PlanBox/PlanBoxScoreCard/_value-view-plain";
import GenericNotificationItem from "./_generic-notification-item";

export default memo(({ notif }) => {
  const { t } = useTranslation();
  const { formatDayAndMonth } = useCommonDateFormats();

  return (
    <GenericNotificationItem notif={notif} title={t("new_score_in", { plan: notif.metadata.planName })}>
      <Typography
        sx={{ marginBottom: "5px" }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {notif.metadata.entryTitle}
        {" "}
        (
        {formatDayAndMonth(notif.metadata.entryDate)}
        )
        {" — "}
        <b>{valueViewPlain(notif.metadata.scoreUnit, notif.metadata)}</b>
      </Typography>
    </GenericNotificationItem>
  );
});
