import React from "react";
import { useTranslation } from "react-i18next";

import { subscriptionFilterGet } from "../../../services/api/subscription";
import { models } from "../../../services/enums";
import { DataPanelTemplate } from "../../shared/templates";
import { SubscriptionsPanelItem } from "..";

export default React.memo(({ bgFrom, initData = {} }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      filterGet={subscriptionFilterGet}
      dataPrefix="subscriptions"
      addTooltip="add_subscription"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      filterDescs={{
        active: { label: t("only_active"), type: "boolean", value: false },
        plan: {
          label: t("plan"), type: "plan", value: initData.planId, filter: { showCompetitions: false },
        },
      }}
      model={models.SUBSCRIPTION}
      panelItem={SubscriptionsPanelItem}
      bgFrom={bgFrom}
      initData={initData}
    />
  );
});
