import { serializeError } from "serialize-error";

import { localStorageDict } from "../enums";

export default (error, info) => {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${window.localStorage.getItem(localStorageDict.JWT)}`,
    },
    body: JSON.stringify({ error: serializeError(error), info }),
  };

  fetch(`${process.env.REACT_APP_API_URL}/log-error`, options);
};
