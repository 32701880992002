export default {
  THEME: {
    NAME: "theme",
    VALUES: {
      DARK: "dark",
      LIGHT: "light",
    },
  },
  JWT: "jwt",
  ROLE: "role",
  ROLE_BACK: "roleBack",
  FEATURES: "features",
  HOST_CONFIG: "hostConfig",
  LOCALE: "locale",
  FEATURE_FLAGS: "featureFlags",
  LANGUAGE: "language",
  TIME_ZONE: "timeZone",
};
