import _ from "lodash";

import { createFuncDef } from "../../../services/utils";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      entryId: data.entryId,
      scoreUnit: data.scoreUnit,
      videoLink: _.isNil(data.videoLink) ? "" : data.videoLink,
      valueA: data.valueA,
      valueB: data.valueB,
      valueC: data.valueC,
      public: data.public,
      isConfirmed: _.isNil(data.isConfirmed) ? false : data.isConfirmed,
      isTieBreak: _.isNil(data.isTieBreak) ? false : data.isTieBreak,
      isTimeCap: _.isNil(data.isTimeCap) ? false : data.isTimeCap,
      timeCapReps: _.isNil(data.timeCapReps) ? 0 : data.timeCapReps,
    });
  }),
];
