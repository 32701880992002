import { Box } from "@mui/material";
import React, { memo } from "react";

export default memo(({ children }) => (
  <Box
    sx={{
      backgroundColor: "black",
      color: "white",
      position: "fixed",
      width: "100%",
      height: "100%",
      padding: "20px",
    }}
  >
    {children}
  </Box>
));
