import { FormControl, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { endOfWeek, startOfWeek } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals } from "../../../services/enums";
import { useCommonDateFormats, useGenericInputStyle } from "../../../services/hooks";

export default ({
  interval, value, onChange, isFieldError, fieldErrorMessage,
}) => {
  const { t } = useTranslation();
  const { formatDate } = useCommonDateFormats();
  const { inputStyle } = useGenericInputStyle();

  switch (interval) {
    case intervals.DAILY:
      return (
        <FormControl style={inputStyle.select()}>
          <DatePicker
            autoOk
            name="date"
            label={t("date")}
            mask=""
            value={value}
            onChange={val => {
              val.setHours(12);
              val.setUTCHours(12);
              onChange("date", val);
            }}
            renderInput={args => (
              <TextField
                {...args}
                error={isFieldError("date")}
                helperText={fieldErrorMessage("date")}
              />
            )}
          />
        </FormControl>
      );
    case intervals.WEEKLY:
      return (
        <FormControl style={inputStyle.select()}>
          <DatePicker
            autoOk
            name="date"
            label={t("date")}
            mask=""
            value={value}
            onChange={val => onChange("date", startOfWeek(new Date(val), { weekStartsOn: 1 }))}
            inputFormat={`dd.MM.yyyy - ${formatDate(endOfWeek(new Date(value), { weekStartsOn: 1 }))}`}
            renderInput={args => (
              <TextField
                {...args}
                error={isFieldError("date")}
                helperText={fieldErrorMessage("date")}
              />
            )}
          />
        </FormControl>
      );

    case intervals.MONTHLY:
      return (
        <FormControl style={inputStyle.select()}>
          <DatePicker
            autoOk
            views={["year", "month"]}
            name="date"
            label={t("date")}
            mask=""
            value={value}
            onChange={val => onChange("date", val)}
            renderInput={args => (
              <TextField
                {...args}
                error={isFieldError("date")}
                helperText={fieldErrorMessage("date")}
              />
            )}
          />
        </FormControl>
      );

    default:
      return null;
  }
};
