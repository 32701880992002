import React from "react";
import { useTranslation } from "react-i18next";

import { scoreFilterGet } from "../../../services/api/score";
import { DataPanelTemplate } from "../../shared/templates";
import { ScoresPanelItem } from "..";

export default React.memo(({ bgFrom, overrideSm = false }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      disableAdding
      filterGet={scoreFilterGet}
      dataPrefix="scores"
      limit="20"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      panelItem={ScoresPanelItem}
      bgFrom={bgFrom}
      overrideSm={overrideSm}
    />
  );
});
