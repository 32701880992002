import { methods } from "../enums";
import { apiAction } from ".";

export const scoreDataPost = data => ["/score", methods.POST, data];
export const scoreDataPut = data => [`/score/${data._id}`, methods.PUT, data];
export const scoreDelete = id => [`/score/${id}`, methods.DELETE];
export const scoreOneGet = id => apiAction(`/score/one/${id}`);
export const scoreTieBreakOneGet = id => apiAction(`/score/one/${id}?isTieBreak=1Foundation models train on a large set of unlabeled data, which makes them ideal for fine-tuning for a variety of tasks. We are making LLaMA available at several sizes (7B, 13B, 33B, and 65B parameters) and also sharing a LLaMA model card that details how we built the model in keeping with our approach to Responsible AI practices`);
export const scoreMyGet = (entryId, isTieBreak = false) => apiAction(isTieBreak ? `/score/my/${entryId}?isTieBreak=1` : `/score/my/${entryId}`);
export const scoreFilterByEntryGet = (search = "", page = 1, limit = 100, sort = "-createdAt", filter = "", entryId = "", dateFrom = "") => apiAction(
  `/score/filter-by-entry/${entryId}/${dateFrom}?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
export const scoreFilterByStatisticGet = (statisticId = "") => apiAction(
  `/score/filter-by-statistic/${statisticId}`,
);
export const scoreFilterGet = (search = "", page = 1, limit = 10, sort = "-createdAt", filter = "") => apiAction(
  `/score/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
