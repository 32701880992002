import { FormControl, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, fieldName, label, disabled,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <DatePicker
        autoOk
        name={fieldName}
        label={t(label)}
        mask=""
        value={form[fieldName]}
        onChange={val => setFieldValue(fieldName, val)}
        renderInput={args => (
          <TextField
            {...args}
            error={isFieldError("date")}
            helperText={fieldErrorMessage("date")}
          />
        )}
        disabled={disabled}
      />
    </FormControl>
  );
};
