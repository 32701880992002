import {
  Box, FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitionPlanOneGet } from "../../../../services/api/competition";
import { useGenericInputStyle } from "../../../../services/hooks";
import { GenericError } from "../../other";

export default React.memo(({
  form, isFieldError, fieldErrorMessage, setFieldValue, size = "medium", isEditing = false, useOutsideForm = false, style = {},
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();
  const [data, setData] = React.useState([]);
  const { error, loading, query } = useParameterizedQuery(competitionPlanOneGet);

  React.useLayoutEffect(() => {
    if (!_.isNil(form.planId) && !loading && !error) {
      query(form.planId).then(result => {
        setData(result?.payload?.data?.competitionCategories || []);

        if (!isEditing) {
          setTimeout(() => {
            setFieldValue("competitionCategory", result?.payload?.data?.competitionCategories[0] || null);
          }, 120);
        }
      });
    }
  }, [form.planId]);

  if (error) {
    return <GenericError />;
  }

  return (
    <Box display={data.length === 0 ? "none" : "inherit"} style={style}>
      {data.length > 0 && (
      <FormControl style={{ ...inputStyle.select(), ...(useOutsideForm && { marginBottom: 5 }) }}>
        <InputLabel htmlFor="category">{t("category")}</InputLabel>
        <Select
          id="category"
          label={t("category")}
          size={size}
          name="competitionCategory"
          value={form.competitionCategory || ""}
          onChange={e => setFieldValue("competitionCategory", e.target.value)}
          error={isFieldError("competitionCategory")}
        >
          {data.map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
        </Select>
        <FormHelperText error={isFieldError("competitionCategory")}>{fieldErrorMessage("competitionCategory")}</FormHelperText>
      </FormControl>
      )}
    </Box>
  );
});
