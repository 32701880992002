import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { fileBlobGet } from "../../../../services/api/file";

export default ({ plan }) => {
  const { t } = useTranslation();

  return (
    <Link to={{ pathname: fileBlobGet(plan.terms) }} target="_blank">
      <Button>
        {t("terms_and_conditions")}
&nbsp;
        <OpenInNewIcon fontSize="inherit" />
      </Button>
    </Link>
  );
};
