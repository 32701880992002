import { FormControl } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../../services/enums";
import { useGenericInputStyle } from "../../../../services/hooks";
import { SelectTextField } from "..";
import setCustomerSelectedData from "./_set-customer-selected-data";

// getUserId is used to grab id of customer or related user
export default ({
  form, isFieldError, fieldErrorMessage, setFieldValues, label = "customer", fieldName = "customerName", fieldId = "customerId", getUserId = false,
}) => {
  // passing label, fieldName, fieldId as you may need to change some of it
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <SelectTextField
        model={models.CUSTOMER}
        label={t(label)}
        name={label}
        value={form[fieldName] || ""}
        use={setCustomerSelectedData(setFieldValues, fieldName, fieldId, getUserId)}
        error={isFieldError(fieldId)}
        helperText={fieldErrorMessage(fieldId)}
      />
    </FormControl>
  );
};
