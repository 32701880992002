import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { noteFilterByEntryGet } from "../../../services/api/note";
import { createFuncDef, createUserDisplayName } from "../../../services/utils";
import {
  AvatarCircle, BoxMessage, GenericError, Loading,
} from "../../shared/other";
import { PreviewDialogTemplate } from "../../shared/templates";

export default ({ actions }) => {
  const { t } = useTranslation();
  const {
    loading, query, error, status, reset,
  } = useParameterizedQuery(noteFilterByEntryGet, false);
  const [data, setData] = React.useState([]);
  const dialogActions = {};

  actions.open = entryId => {
    dialogActions.open();
    query("", 1, 100, "", "", entryId).then(res => {
      if (_.isNil(res.payload) || _.isNil(res.payload.notes)) {
        return;
      }
      setData(res.payload.notes.data);
    });
  };

  return (
    <PreviewDialogTemplate
      actions={dialogActions}
      title="notes"
      maxWidth="md"
      content={(
        <>
          {loading && <Loading />}
          {error && <GenericError status={status} />}
          {data.length === 0 && <BoxMessage msg={t("no_notes")} />}
          <List>
            {_.map(data, item => (
              <React.Fragment key={item._id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <AvatarCircle user={item.user} />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={(
                      <Typography style={{ whiteSpace: "pre-line", textAlign: "justify", wordBreak: "break-word" }}>
                        {item.content}
                      </Typography>
                    )}
                    secondary={(
                      <Typography component="span" variant="body2">
                        {createUserDisplayName(item.user)}
                      </Typography>
                    )}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
          </List>
        </>
)}
      onOpenCallbacks={[createFuncDef(reset), createFuncDef(setData, [[]])]}
    />
  );
};
