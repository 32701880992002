import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({ isError, errors }) => {
  const { t } = useTranslation();

  return isError ? (
    <Box display="flex" width="100%" flexWrap="wrap">
      {_.isNil(errors) && <Alert severity="error" style={{ marginTop: 10, marginBottom: 10, width: "100%" }}>{t("generic_error")}</Alert>}
      {!_.isNil(errors) && errors.map(
        (error, i) => <Alert key={`error_${i}`} severity="error" style={{ marginTop: 10, marginBottom: 10, width: "100%" }}>{t(error)}</Alert>,
      )}
    </Box>
  ) : <Box />;
};
