import { useTheme } from "@emotion/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePdf } from "react-pdf-js";

import Loading from "../Loading";

export default ({ file }) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
  const theme = useTheme();

  const canvasEl = useRef(null);

  const [loading, numPages] = usePdf({
    file,
    page,
    canvasEl,
  });

  useEffect(() => {
    setPages(numPages);
  }, [numPages]);

  return (
    <Box
      display="flex"
      width="100%"
      flexWrap="wrap"
      paddingTop="10px"
      position="relative"
      sx={{
        position: "relative",
        bottom: "90px",
      }}
    >
      {page > 1 ? (
        <Box
          flex="1 0"
          paddingRight="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ "&:hover": { color: theme.palette.primary.main, cursor: "pointer" } }}
          onClick={() => setPage(page - 1)}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </Box>
      ) : <Box flex="1 0" />}
      <Box flex="8 0">
        <Box display="flex" flexDirection="column" justifyContent="center" width="100%" minHeight="220px" position="relative">
          <Box position="relative">
            {loading && <Loading />}
            <canvas ref={canvasEl} />
          </Box>
        </Box>
      </Box>
      {page < pages ? (
        <Box
          flex="1 0"
          paddingLeft="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ "&:hover": { color: theme.palette.primary.main, cursor: "pointer" } }}
          onClick={() => setPage(page + 1)}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Box>
      ) : <Box flex="1 0" />}
    </Box>
  );
};
