import { addDays } from "date-fns";

export default {
  _id: null,
  order: 1,
  planId: "",
  planName: "",
  planInterval: "",
  date: new Date(),
  day: 1,
  isDeadline: false,
  deadlineDate: addDays(new Date(), 1),
  title: "",
  content: "",
  comment: "",
  isScore: false,
  isScoreHidden: false,
  isHidden: false,
  scoreUnit: "",
  statisticId: "",
  statisticName: "",
  media: [],
  competitionCategory: null,
  isTieBreak: false,
  tieBreakUnit: "",
  tieBreakDesc: "",
  isConfirmationRequired: false,
  isTimeCap: false,
  isInfo: false,
};
