import { pl } from "date-fns/locale";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Provides date-fns locale
 * @return {Object} { getLoggedUserLocale }
 */
export default () => {
  const { t } = useTranslation();

  const getLoggedUserLocale = React.useCallback(() => {
    // because texts are translated here, there's no need to take different locales for different languages
    pl.localize.month = n => t(`m_${n}`);
    pl.localize.day = n => t(`d_${n}`);

    return pl;
  }, []);

  return { getLoggedUserLocale };
};
