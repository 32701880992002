import { createClient } from "react-fetching-library";

import { localStorageDict, methods } from "../enums";
import cache from "./_create-cache";
import deepenPayloadInterceptor from "./_deepen-payload-interceptor";
import requestHostInterceptor from "./_request-host-interceptor";

export const client = createClient({
  // eslint-disable-next-line no-undef
  requestInterceptors: [requestHostInterceptor(process.env.REACT_APP_API_URL), deepenPayloadInterceptor()],
  cacheProvider: cache,
});

export const apiAction = (endpoint, method = methods.GET, body, apiPrefix = true) => ({
  method,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${window.localStorage.getItem(localStorageDict.JWT)}`,
  },
  endpoint: apiPrefix ? `/api${endpoint}` : endpoint,
  body,
});

export const openAction = endpoint => ({
  method: methods.GET,
  headers: {
    "Content-Type": "application/json",
  },
  endpoint,
});
