import * as yup from "yup";

export default t => yup.object().shape({
  planId: yup.string().trim()
    .required(t("field_is_required")),
  customerId: yup.string().trim()
    .required(t("field_is_required")),
  start: yup.date().typeError(t("invalid_date_format"))
    .required(t("field_is_required")),
  end: yup.date().typeError(t("invalid_date_format"))
    .required(t("field_is_required")),
});
