import { Box } from "@mui/material";
import { grey } from "@mui/material/colors";
import useLocalStorage from "@rehooks/local-storage";
import _ from "lodash";
import React from "react";

import { fileBlobGet } from "../../../../services/api/file";
import { localStorageDict } from "../../../../services/enums";

export default React.memo(({ bg, children, width = "100%" }) => {
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
  return (
    <Box
      width={width}
      height={300}
      style={_.isNil(bg)
        ? { backgroundColor: _.isEqual(theme, localStorageDict.THEME.VALUES.DARK) ? grey[900] : grey[500], borderRadius: 15, position: "relative" }
        : {
          background: `url("${fileBlobGet(bg)}") no-repeat center`, backgroundSize: "cover", borderRadius: 15, position: "relative",
        }}
    >
      {children}
    </Box>
  );
});
