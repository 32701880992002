import {
  Box, Dialog, DialogContent, DialogTitle,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { useFullScreen } from "../../../../services/hooks";
import { ErrorMessages, FormDialogActions, Loading } from "../../other";

export default ({
  maxWidth, isOpen, isEditing, onClose, title, titleButtons, isError, errors, handleClose, handleSubmit, isLoading, body, comment,
}) => {
  const { t } = useTranslation();
  const { fullScreen } = useFullScreen();

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth={maxWidth}
      open={isOpen()}
      onClose={onClose}
    >
      <DialogTitle>
        <Box display="flex">
          {_.isNil(isEditing) && <Box flex="1 1 auto">{t(title)}</Box>}
          {!_.isNil(isEditing) && (
            <Box flex="1 1 auto">
              {t(title)}
              {" "}
              {isEditing ? `(${t("editing")})` : `(${t("new")})`}
            </Box>
          )}
          <Box>
            {titleButtons}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading() && <Loading />}
        {body}
        <ErrorMessages isError={isError()} errors={errors()} />
        {comment}
      </DialogContent>
      <FormDialogActions cancel={handleClose} submit={handleSubmit} />
    </Dialog>
  );
};
