import _ from "lodash";
import React from "react";

import { customerDelete } from "../../../services/api/customer";
import { models } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import Body from "./_body";

export default React.memo(({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={createUserDisplayName(item.customer)} highlight={highlight} />}
    body={<Body item={item} highlight={highlight} />}
    footer={<ItemFooter item={item} callback={callback} use={use} model={models.CUSTOMER} modelDelete={customerDelete} />}
  />
));
