import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default React.memo(() => {
  const { t } = useTranslation();

  return (
    <Box position="relative" width="100%">
      <Typography variant="h1" style={{ fontWeight: 800, textAlign: "center", marginTop: 30 }}>500</Typography>
      <Typography variant="h5" style={{ fontWeight: 300, textAlign: "center", marginTop: 30 }}>
        {t("generic_error")}
      </Typography>
    </Box>
  );
});
