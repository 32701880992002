import * as yup from "yup";

export default t => yup.object().shape({
  name: yup.string().trim()
    .required(t("field_is_required"))
    .max(1000, "text_too_long"),
  order: yup.number().required(t("field_is_required")),
  interval: yup.string().trim()
    .max(500, "text_too_long")
    .required(t("field_is_required")),
  shift: yup.number().required(t("field_is_required")),
  hidden: yup.boolean().required(t("field_is_required")),
  competitionNameHidden: yup.boolean().required(t("field_is_required")),
  hiddenDays: yup.boolean().required(t("field_is_required")),
  competitionDesc: yup.string().trim()
    .max(5000, "text_too_long"),
  periodName: yup.string().trim()
    .max(500, "text_too_long")
    .required(t("field_is_required")),
  data: yup.string().nullable(),
  mime: yup.string().trim()
    .nullable(),
  price: yup.number().transform(value => {
    let price = value.toString();
    price = price.indexOf(".") > -1 ? price : `${price}00`;

    return parseInt(price.replace(".", ""), 10);
  }).integer(t("invalid_price_value")).required(t("field_is_required")),
  inShop: yup.boolean().required(t("field_is_required")),
  shopDesc: yup.string().trim()
    .max(5000, "text_too_long"),
});
