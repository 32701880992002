import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Group from "@mui/icons-material/Group";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PageviewIcon from "@mui/icons-material/Pageview";
import Share from "@mui/icons-material/Share";
import TvIcon from "@mui/icons-material/Tv";
import {
  Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip,
} from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { planDelete, planDuplicate } from "../../../services/api/plan";
import { intervals, models } from "../../../services/enums";
import { useAnchorControl } from "../../../services/hooks";
import { competitionAbsoluteUrl } from "../../../services/routes";
import { createFuncDef } from "../../../services/utils";
import { CompetitionPreviewDialog } from "../../competition";
import { CompetitorsPanel } from "../../competitor";
import { PlanSharePanel } from "../../plan-share";
import {
  DateSelectButton, DuplicateButton, ModelActionButton, PreviewButton, TextCopyButton,
} from "../../shared/buttons";
import { SelectDialogTemplate } from "../../shared/templates";
import { SubscriptionsPanel } from "../../subscription";
import PlanCalendarViewDialog from "../PlanCalendarViewDialog";
import PlanTvDisplayDialog from "../PlanTvDisplayDialog";
import SearchEntriesButton from "./_search-entries-button";

export default ({
  plan, setRows, rows, callback, actions, canEdit = true, isShared = false,
}) => {
  const actionsCompetitors = {};
  const actionsSubscriptions = {};
  const actionsPlanShares = {};
  const actionsTv = {};
  const { t } = useTranslation();
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();

  const reloadAllPlanEntries = () => {
    _.mapValues(actions, func => func());
  };

  const competitionLink = competitionAbsoluteUrl(plan);

  return (
    <Box display="flex">
      {canEdit && !isShared && (
      <Box>
        <IconButton aria-controls={`menu-${plan._id}`} aria-haspopup="true" onClick={setAnchor}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu
          id={`menu-${plan._id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={removeAnchor}
        >
          {!_.isEqual(plan.interval, intervals.COMPETITION) && (
          <MenuItem
            data-testid="subscriptions"
            onClick={() => {
              removeAnchor();
              actionsSubscriptions.open({ plan: { _id: plan._id, name: plan.name } });
            }}
          >
            <ListItemIcon>
              <Group fontSize="small" />
            </ListItemIcon>
            {t("subscriptions")}
          </MenuItem>
          )}
          {_.isEqual(plan.interval, intervals.COMPETITION) && (
          <MenuItem
            data-testid="competitors"
            onClick={() => {
              removeAnchor();
              actionsCompetitors.open({ plan: { _id: plan._id, name: plan.name } });
            }}
          >
            <ListItemIcon>
              <Group fontSize="small" />
            </ListItemIcon>
            {t("competitors")}
          </MenuItem>
          )}
          <MenuItem
            data-testid="plan-shares"
            onClick={() => {
              removeAnchor();
              actionsPlanShares.open({ plan: { _id: plan._id, name: plan.name } });
            }}
          >
            <ListItemIcon>
              <Share fontSize="small" />
            </ListItemIcon>
            {t("share")}
          </MenuItem>
          {_.isEqual(plan.interval, intervals.DAILY) && (
          <MenuItem
            data-testid="tv-view"
            onClick={() => {
              removeAnchor();
              actionsTv.open();
            }}
          >
            <ListItemIcon>
              <TvIcon fontSize="small" />
            </ListItemIcon>
            {t("display_on_tv")}
          </MenuItem>
          )}
          <DuplicateButton
            duplicate={planDuplicate}
            modelId={plan._id}
            fontSize="inherit"
            callback={callback}
            isList
            removeAnchor={removeAnchor}
          />
          <ModelActionButton
            type="edit"
            variant="iconbutton"
            model={models.PLAN}
            tooltip="edit"
            modelId={plan._id}
            fontSize="small"
            callback={callback}
            isList
            removeAnchor={removeAnchor}
          />
          <ModelActionButton
            remove={planDelete}
            modelId={plan._id}
            fontSize="inherit"
            callback={callback}
            isList
            removeAnchor={removeAnchor}
          />
        </Menu>
        <PlanTvDisplayDialog actions={actionsTv} plan={{ _id: plan._id, name: plan.name }} />
        <SelectDialogTemplate
          title="subscriptions_for_plan"
          titleExt={plan.name}
          modelPanel={SubscriptionsPanel}
          actions={actionsSubscriptions}
          maxWidth="lg"
          initData={{ planId: plan._id, planName: plan.name }}
        />
        <SelectDialogTemplate
          title="competitors_for_plan"
          titleExt={plan.name}
          modelPanel={CompetitorsPanel}
          actions={actionsCompetitors}
          maxWidth="lg"
          initData={{ planId: plan._id, planName: plan.name }}
        />
        <SelectDialogTemplate
          title="shares_for_plan"
          titleExt={plan.name}
          modelPanel={PlanSharePanel}
          actions={actionsPlanShares}
          maxWidth="lg"
          initData={{ planId: plan._id, planName: plan.name }}
        />
      </Box>
      )}
      {_.isEqual(plan.interval, intervals.COMPETITION) && (
        <>
          <Box>
            <TextCopyButton textToCopy={competitionLink} size="" label="copy_link" />
          </Box>
          <Box>
            <PreviewButton
              args={[plan]}
              placement="top"
              buttonSize="medium"
              iconSize="small"
              icon={PageviewIcon}
              previewDialog={CompetitionPreviewDialog}
            />
          </Box>
        </>
      )}
      {!_.isEqual(plan.interval, intervals.COMPETITION) && <SearchEntriesButton callback={[createFuncDef(reloadAllPlanEntries)]} />}
      {_.isEqual(plan.interval, intervals.DAILY) && <PlanCalendarViewDialog plan={plan} canEdit={canEdit} isShared={isShared} callback={[createFuncDef(reloadAllPlanEntries)]} />}
      {(_.includes([intervals.DAILY, intervals.WEEKLY, intervals.MONTHLY], plan.interval) || (_.includes([intervals.COMPETITION, intervals.STATIC], plan.interval) && rows[0] > 0)) && (
      <Box>
        <Tooltip title={t("previous_page")} placement="top" disableTouchListener>
          <IconButton data-testid="left" color="secondary" onClick={() => setRows(_.range(rows[0] - 5, rows[4] - 4))}>
            <ArrowBackIosSharpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      )}
      {_.isEqual(plan.interval, intervals.DAILY) && (
        <Box>
          <DateSelectButton
            color="secondary"
            right={7}
            top={-1}
            onChange={val => {
              const dif = differenceInCalendarDays(val, new Date());
              setRows(_.range(dif - 1, dif + 4));
            }}
          />
        </Box>
      )}
      <Box>
        <Tooltip title={t("next_page")} placement="top" disableTouchListener>
          <IconButton data-testid="right" color="secondary" onClick={() => setRows(_.range(rows[0] + 5, rows[4] + 6))}>
            <ArrowForwardIosSharpIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
