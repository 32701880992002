import RefreshIcon from "@mui/icons-material/Refresh";
import { ListItemIcon, MenuItem } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { localStorageDict, roles } from "../../../services/enums";
import { useLocalStorage, useRoleValidator } from "../../../services/hooks";
import switchRole from "./_switch-role";

export default React.memo(({ callback }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { isRole } = useRoleValidator();
  const [roleBack] = useLocalStorage(localStorageDict.ROLE_BACK, null);
  const srf = switchRole(callback, history, roleBack);

  return (isRole(roles.COACH) || roleBack === roles.COACH) && (
  <MenuItem onClick={srf}>
    <ListItemIcon>
      <RefreshIcon fontSize="small" />
    </ListItemIcon>
    {t(_.isNil(roleBack) ? "customer_view" : "get_back_to_my_view")}
  </MenuItem>
  );
});
