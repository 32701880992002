/* eslint-disable camelcase */
import * as yup from "yup";

export default () => yup.object().shape({
  card__age: yup.string().trim()
    .max(1000, "text_too_long"),
  card__weight: yup.string().trim()
    .max(1000, "text_too_long"),
  card__height: yup.string().trim()
    .max(1000, "text_too_long"),
  card__club: yup.string().trim()
    .max(1000, "text_too_long"),
  card__exp: yup.string().trim()
    .max(1000, "text_too_long"),
});
