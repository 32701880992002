import { useTheme } from "@mui/material";
import React from "react";

export default ({ bgFrom, children }) => {
  const theme = useTheme();

  const getBg = () => {
    switch (bgFrom) {
      case "MuiMenu":
        return theme.components.MuiMenu.styleOverrides.paper.backgroundColor;
      case "MuiDialog":
        return theme.components.MuiDialog.styleOverrides.paper.backgroundColor;
      case "MuiPaper":
        return theme.components.MuiPaper.styleOverrides.root.backgroundColor;
      default:
        return theme.palette.background.default;
    }
  };

  return (
    <div
      aria-label="box"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        color: theme.palette.text.primary,
        backgroundColor: getBg(),
        opacity: 0.6,
        zIndex: 9,
        minHeight: 50,
      }}
    >
      {children}
    </div>
  );
};
