import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PreviewDialogTemplate } from "../../../shared/templates";
import { UserShopPage } from "../../../user";

export default ({ actions }) => {
  const { t } = useTranslation();
  const [clientNumber, setClientNumber] = useState(null);
  const [maxWidth, setMaxWidth] = useState("lg");
  const dialogActions = {};

  actions.open = clientNumber => {
    setClientNumber(clientNumber);
    dialogActions.open();
  };

  return (
    <PreviewDialogTemplate
      maxWidth={maxWidth}
      actions={dialogActions}
      title={t("preview")}
      content={(clientNumber && <UserShopPage clientNumber={clientNumber} footerAndHeader={false} forcedWidth={maxWidth} />)}
      titleButtons={(
        <Box display="flex" flexWrap="nowrap">
          <Tooltip title={t("preview_on_pc")} placement="top" disableTouchListener>
            <IconButton data-testid="right" onClick={() => setMaxWidth("lg")}>
              <LaptopIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("preview_on_mobile")} placement="top" disableTouchListener>
            <IconButton data-testid="right" onClick={() => setMaxWidth("xs")}>
              <PhoneIphoneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    />
  );
};
