import _ from "lodash";

/**
 * @param {String} string any string value
 * @param {String} pad any character
 * @param {Number} length total length of the output string
 *
 * @returns {String} eg. "00001"
 */
export default (string, pad, length) => {
  const len = length > 0 ? length : 0;

  return (new Array(len + 1).join(_.toString(pad)) + string).slice(-len);
};
