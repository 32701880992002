import {
  Box, Typography,
} from "@mui/material";
import React from "react";

import { useSizeStyle } from "../../../services/hooks";
import { safeUpperCase } from "../../../services/utils";
import Buttons from "./_buttons";

export default ({
  plan, setRows, rows, callback, actions, canEdit = true, isShared = false,
}) => {
  const { select } = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 25%", lg: "0 0 20%", xl: "0 0 20%",
  });
  const FlexButtons = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 1", lg: "0 1", xl: "0 1",
  });

  const getTitleColor = () => {
    if (isShared) {
      return plan.isEditing ? "green" : "red";
    }

    return "primary";
  };

  return (
    <Box flex={select()} display="flex" flexWrap="wrap" padding="5px">
      <Box flexGrow="1" display="flex" alignItems="left" flexDirection="column">
        <Box display="flex" flexWrap="wrap">
          <Typography
            variant="h3"
            color={getTitleColor()}
            style={{
              fontWeight: 600, wordBreak: "break-word", position: "relative", top: "4px", left: "4px",
            }}
          >
            {safeUpperCase(plan.name)}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flex={FlexButtons.select()} justifyContent="flex-end">
        <Buttons
          setRows={setRows}
          rows={rows}
          plan={plan}
          callback={callback}
          actions={actions}
          canEdit={canEdit}
          isShared={isShared}
        />
      </Box>
    </Box>
  );
};
