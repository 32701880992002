import {
  Box, Link, Paper, Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { LoginFormBox, LogoCenterBox } from "../../components/shared/boxes";
import { featureFlags } from "../../services/enums";
import { useFeatureFlag } from "../../services/hooks";
import { registerUrl, remindPasswordUrl } from "../../services/routes";

export default () => {
  const { t } = useTranslation();
  const { isEnabled } = useFeatureFlag(featureFlags.REGISTRATION_DISABLED);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh" style={{ minHeight: 340 }}>
      <Box position="absolute">
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Paper style={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 10,
            width: 300,
          }}
          >
            <LogoCenterBox />
            <LoginFormBox />
            <Box flex="1" display="flex">
              <Box flex="1 0 50%">
                <Typography variant="body2" style={{ marginTop: 20 }}>
                  <Link href={remindPasswordUrl()}>{t("remind_credentials")}</Link>
                </Typography>
              </Box>
              <Box flex="1 0 50%">
                {!isEnabled() && (
                <Typography variant="body2" style={{ marginTop: 20, textAlign: "right" }}>
                  <Link href={registerUrl()}>{t("new_account")}</Link>
                </Typography>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
};
