import { Box } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { noteDataPost, noteDataPut, noteOneGet } from "../../../services/api/note";
import { useFormEngine } from "../../../services/hooks";
import { onSuccessCallbacks } from "../../../services/utils";
import { CheckboxField, MultilineTextField } from "../../shared/fields";
import { FormDialogTemplate } from "../../shared/templates";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";

export default React.memo(({ actions, callback, options = {} }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, noteDataPost, noteDataPut, noteOneGet, {
    _id: null,
    content: "",
    entryId: "",
    coachCanSee: false,
    isPublic: false,
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  const setIsPublic = (_, checked) => {
    setFieldValues({
      coachCanSee: true,
      isPublic: checked,
    });
  };

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="note"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <MultilineTextField
            fieldName="content"
            label="content"
            minRows={10}
            maxRows={10}
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <Box>
            <CheckboxField
              label={options.isCompetition ? "coach_can_see_this_note_competition" : "coach_can_see_this_note"}
              fieldName="coachCanSee"
              form={form}
              setFieldValue={setFieldValue}
              disabled={form.isPublic}
            />
          </Box>
          <Box>
            {!options.isCompetition && (
            <CheckboxField
              label="note_public"
              helper="note_public_comment"
              fieldName="isPublic"
              form={form}
              setFieldValue={setIsPublic}
            />
            )}
          </Box>
        </>
      )}
    />
  );
});
