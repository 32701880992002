import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { useQuery } from "react-fetching-library";

import { planFilterGet } from "../../../services/api/plan";
import { planShareFilterCoachGet } from "../../../services/api/plan-share";
import { models } from "../../../services/enums";
import { PlanCoachBox } from "../../plan";
import { ModelActionButton } from "../../shared/buttons";
import { GenericError, Loading } from "../../shared/other";
import useUpdateDraggable from "./_use-update-draggable";

export default React.memo(() => {
  const actions = {};
  const [data, setData] = React.useState([]);
  const [sharedData, setSharedData] = React.useState([]);
  const {
    loading, payload, error, status, query,
  } = useQuery(planFilterGet("", 1, 100, "order", "{%22board%22:true}"));
  const planShare = useQuery(planShareFilterCoachGet("", 1, 100, "order", "{%22usePlanId%22:true}"));

  const reload = React.useCallback((entry, formData) => {
    if (_.isEqual(entry.planId, formData.planId)) {
      actions[entry.planId]();
    } else {
      actions[entry.planId]();
      actions[formData.planId]();
    }
  }, [actions]);

  const { update, isUpdating } = useUpdateDraggable(reload);

  React.useLayoutEffect(() => {
    setData(payload?.plans?.data || []);
    setSharedData(planShare.payload?.planShares?.data || []);
  }, [payload, planShare.payload]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box position="relative" width="100%">
      {(loading || isUpdating()) && <Loading />}
      <DragDropContext onDragEnd={update}>
        {data.map((plan, idx) => (
          <PlanCoachBox
            key={plan._id}
            plan={plan}
            callback={query}
            planIdx={idx}
            actions={actions}
            reload={reload}
          />
        ))}
        {sharedData.map((plan, idx) => (
          <PlanCoachBox
            key={plan._id}
            plan={plan}
            callback={query}
            planIdx={idx}
            actions={actions}
            reload={reload}
            isShared
          />
        ))}
      </DragDropContext>
      <ModelActionButton type="add" model={models.PLAN} variant="fabh1" tooltip="add_plan" callback={query} />
    </Box>
  );
});
