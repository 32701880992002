import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { Box, IconButton, Tooltip } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PreviewDialogTemplate } from "../../shared/templates";
import CompetitionPage from "../CompetitionPage";

export default ({ actions }) => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState(null);
  const [maxWidth, setMaxWidth] = useState("lg");
  const dialogActions = {};

  actions.open = plan => {
    setPlan(plan);
    dialogActions.open();
  };
  return (
    <PreviewDialogTemplate
      maxWidth={maxWidth}
      actions={dialogActions}
      title={(plan && plan.name)}
      content={(plan && <CompetitionPage clientNumber={plan.user.clientNumber} slug={plan.slug} footerAndHeader={false} />)}
      titleButtons={(
        <Box display="flex" flexWrap="nowrap">
          <Tooltip title={t("preview_on_pc")} placement="top" disableTouchListener>
            <IconButton data-testid="right" onClick={() => setMaxWidth("lg")}>
              <LaptopIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("preview_on_mobile")} placement="top" disableTouchListener>
            <IconButton data-testid="right" onClick={() => setMaxWidth("xs")}>
              <PhoneIphoneIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      )}
    />
  );
};
