import React from "react";
import { useTranslation } from "react-i18next";

import { statisticFilterGet } from "../../../services/api/statistic";
import { models } from "../../../services/enums";
import { DataPanelTemplate } from "../../shared/templates";
import { StatisticPanelItem } from "..";

export default React.memo(({ use, bgFrom }) => {
  const { t } = useTranslation();

  return (
    <DataPanelTemplate
      use={use}
      filterGet={statisticFilterGet}
      dataPrefix="statistics"
      addTooltip="add_statistic"
      sortDescs={[
        { label: t("from_newest"), desc: "-createdAt" },
        { label: t("from_oldest"), desc: "createdAt" },
      ]}
      model={models.STATISTIC}
      panelItem={StatisticPanelItem}
      bgFrom={bgFrom}
    />
  );
});
