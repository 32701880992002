import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { marginBottomForFields } from "../../../../services/utils";
import { GenericError, Loading } from "../../other";

export default ({
  fieldName, form, setFieldValue, isFieldError, fieldErrorMessage, label,
  styleVariant = "modal", filterGet, payloadBranch = "", listMapFunc, bgFrom,
  search = "", page = 1, limit = 100, sort = "-createdAt", filter = "",
}) => {
  const { t } = useTranslation();
  const [list, setList] = React.useState([]);
  const {
    loading, payload, error, status,
  } = useQuery(filterGet(search, page, limit, sort, filter));

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload[payloadBranch])) {
      setList(_.map(payload[payloadBranch].data, listMapFunc));
    }
  }, [payload, loading, payloadBranch, listMapFunc]);

  const getStyle = option => {
    switch (option) {
      case "modal": return { width: "100%", marginBottom: marginBottomForFields() };
      case "filter": return { width: "100%", marginTop: 10 };
      default: return {};
    }
  };

  const getVariant = option => {
    switch (option) {
      case "modal": return "outlined";
      case "filter": return "standard";
      default: return "";
    }
  };

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <FormControl variant={getVariant(styleVariant)} style={getStyle(styleVariant)}>
      {loading && <Loading bgFrom={bgFrom} />}
      <InputLabel htmlFor="max-width">{t(label)}</InputLabel>
      <Select
        label={t(label)}
        name={fieldName}
        value={list.length === 0 ? "" : form[fieldName] || ""}
        onChange={e => setFieldValue(fieldName, e.target.value)}
        error={_.isFunction(isFieldError) ? isFieldError(fieldName) : false}
      >
        {_.map(list, (item, i) => <MenuItem key={`${fieldName}${i}`} value={item.value}>{item.label}</MenuItem>)}
        {list.length === 0 && <MenuItem disabled>{t("no_data_to_display")}</MenuItem>}
      </Select>
      {_.isFunction(fieldErrorMessage) && <FormHelperText error={isFieldError(fieldName)}>{fieldErrorMessage(fieldName)}</FormHelperText>}
    </FormControl>
  );
};
