import {
  Alert, Box, Button, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { competitorSelfSignUpPost } from "../../../services/api/competitor";
import { newCompetitorPost } from "../../../services/api/other";
import { stripePlanPaymentLink } from "../../../services/api/plan";
import { localStorageDict } from "../../../services/enums";
import {
  useAuthenticated, useFormEngine, useLocalStorage,
} from "../../../services/hooks";
import {
  createFuncDef, formatPrice, getLocale, onSuccessCallbacks, safeUpperCase,
} from "../../../services/utils";
import { CheckboxField, CompetitionCategorySelectField, TextField } from "../../shared/fields";
import { UrlifiedTypography } from "../../shared/other";
import { FormDialogTemplate } from "../../shared/templates";
import formSchema from "./_form-schema";

export default React.memo(({
  actions = {}, callback, termsUrl, plan,
}) => {
  const { t } = useTranslation();
  const { isAuthenticated, getUserData } = useAuthenticated();

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, submitErrors,
    isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage, getFormSchema,
  } = useFormEngine(actions, isAuthenticated() ? competitorSelfSignUpPost : newCompetitorPost, null, null, {
    email: "",
    competitionCategory: null,
    competitorName: "",
    sportClub: "",
    timeZone: getLocale().timeZone,
    locale: getLocale().locale,
    language: getLocale().language,
    country: getLocale().country,
    terms: false,
    isTermsOn: termsUrl !== null,
  }, formSchema(t, isAuthenticated, (_.isNil(plan.price) || plan.price === 0)), onSuccessCallbacks(callback), null, [], null, [createFuncDef(() => setFieldValue("email", ""))]);
    // setFieldValue("email", "") above is required, because categories field is using setFieldValue to select automatically the first element and
    // it causes a modification of email field in this case. Eresing explicitly an email field on modal open solves the proble.

  const {
    loading, error, payload, mutate,
  } = useMutation(stripePlanPaymentLink);
  const [lang] = useLocalStorage(localStorageDict.LANGUAGE, getLocale().language);

  React.useLayoutEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      const userData = getUserData();
      const prefilledEmail = _.isNil(userData) ? "" : userData.email;
      window.location.href = `${payload.paymentLink}?prefilled_email=${prefilledEmail}&locale=${lang}`;
    }
  }, [payload]);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="competitor"
      isPaper
      isError={isError}
      errors={errors}
      isLoading={isLoading || loading}
      handleClose={handleClose}
      handleSubmit={(_.isNil(plan.price) || plan.price === 0) ? handleSubmit : () => {
        // eslint-disable-next-line no-console
        console.log("sub");
        const authUser = getUserData();
        const fs = getFormSchema();
        fs.validate(form, { abortEarly: false })
          .then(async () => {
            await mutate(plan._id, _.isNil(authUser) ? "new" : authUser._id, lang);
          })
          .catch(submitErrors);
      }}
      body={(
        <>
          {!isAuthenticated() && (_.isNil(plan.price) || plan.price === 0) && (
            <TextField
              disabled={isEditing}
              label="email"
              fieldName="email"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
            />
          )}
          {(_.isNil(plan.price) || plan.price === 0) && (
          <CompetitionCategorySelectField
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
            setFieldValues={setFieldValues}
          />
          )}
          {!isAuthenticated() && (_.isNil(plan.price) || plan.price === 0) && (
            <TextField
              disabled={isEditing}
              label="name_and_surname_or_nickname"
              fieldName="competitorName"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              isOptional
            />
          )}
          {!isAuthenticated() && (_.isNil(plan.price) || plan.price === 0) && (
            <TextField
              disabled={isEditing}
              label="sport_club"
              fieldName="sportClub"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              isOptional
            />
          )}
          {termsUrl !== null && (
            <CheckboxField
              label={(
                <UrlifiedTypography labels={[t("terms_and_conditions")]}>
                  {t("i_accept_terms_and_conditions_of_this_competition").replace("[TERMS]", termsUrl)}
                </UrlifiedTypography>
              )}
              fieldName="terms"
              form={form}
              setFieldValue={setFieldValue}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
            />
          )}
        </>
      )}
      comment={(
        <>
          {!isAuthenticated() && (_.isNil(plan.price) || plan.price === 0) && (
          <Box>
            <Alert severity="info" style={{ marginBottom: 10, marginTop: 10 }}>
              {t("competitor_self_signup_comment")}
            </Alert>
            <Alert severity="info" style={{ marginBottom: 10 }}>
              {t("if_you_have_acc_please_login_first")}
              <br />
              <Link to="/"><Button>{t("login_to2")}</Button></Link>
            </Alert>
          </Box>
          )}
          {!_.isNil(plan.price) && plan.price > 0 && (
          <Box>
            <Alert severity="info" style={{ marginBottom: 10, marginTop: 10 }}>
              {t("competition_payment_alert_box")}
              <Typography variant="h4" sx={{ marginTop: "10px" }}>
                {t("price")}
                :&nbsp;
                {formatPrice(plan.price)}
                {safeUpperCase(plan.user.stripe.currency)}
              </Typography>
            </Alert>
          </Box>
          )}
        </>
      )}
    />
  );
});
