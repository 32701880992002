import ArrowBackIosSharpIcon from "@mui/icons-material/ArrowBackIosSharp";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RestoreIcon from "@mui/icons-material/Restore";
import { Box, IconButton, Tooltip } from "@mui/material";
import { addMonths, subMonths } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals } from "../../../services/enums";
import { useCommonDateFormats } from "../../../services/hooks";
import { PreviewDialogTemplate } from "../../shared/templates";
import Panel from "./_panel";

export default React.memo(({
  plan, canEdit = true, isShared = false, callback,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = React.useState(new Date());
  const { formatByInterval } = useCommonDateFormats();
  const actions = {};

  return (
    <Box>
      <Tooltip title={t("calendar_view")} placement="top" disableTouchListener>
        <IconButton onClick={() => actions.open()}>
          <CalendarMonthIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <PreviewDialogTemplate
        actions={actions}
        onCloseCallbacks={callback}
        title={`${plan.name} | ${formatByInterval(intervals.MONTHLY, date)}`}
        maxWidth="xl"
        forceFullScreen
        content={<Panel plan={plan} date={date} canEdit={canEdit} isShared={isShared} />}
        titleButtons={(
          <Box display="flex" flexWrap="nowrap">
            <Tooltip title={t("previous_month")} placement="top" disableTouchListener>
              <IconButton data-testid="left" color="secondary" onClick={() => setDate(subMonths(date, 1))}>
                <ArrowBackIosSharpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("back_to_current_month")} placement="top" disableTouchListener>
              <IconButton data-testid="right" color="secondary" onClick={() => setDate(new Date())}>
                <RestoreIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("next_month")} placement="top" disableTouchListener>
              <IconButton data-testid="right" color="secondary" onClick={() => setDate(addMonths(date, 1))}>
                <ArrowForwardIosSharpIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      />
    </Box>
  );
});
