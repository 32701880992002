import SearchIcon from "@mui/icons-material/Search";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { EntriesPanel } from "../../entry";
import { PreviewDialogTemplate } from "../../shared/templates";

export default ({ callback }) => {
  const actions = {};
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t("search_for_entries")} placement="top" disableTouchListener>
        <IconButton onClick={() => actions.open()} size="medium">
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <PreviewDialogTemplate
        title="search_for_entries"
        content={<EntriesPanel bgFrom="MuiDialog" />}
        actions={actions}
        onCloseCallbacks={callback}
      />
    </>
  );
};
