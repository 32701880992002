import _ from "lodash";
import { useQuery } from "react-fetching-library";

import { userRefreshTokenGet } from "../api/user";
import { localStorageDict } from "../enums";
import { writeStorage } from "../utils";

export default () => {
  const { query } = useQuery(userRefreshTokenGet(), false);

  const refresh = () => {
    query().then(data => {
      if (!_.isNil(data) && !_.isNil(data.payload) && !_.isNil(data.payload.data)) {
        writeStorage(localStorageDict.JWT, data.payload.data.token);
      }
    });
  };

  return { refresh };
};
