import * as yup from "yup";

export default (t, isAuthenticated, requireEmail = true) => yup.object().shape({
  planId: yup.string().trim()
    .required(t("field_is_required")),
  ...(isAuthenticated() || !requireEmail ? {} : {
    email: yup.string().trim()
      .required(t("field_is_required"))
      .email(t("invalid_email_format"))
      .max(1000, "text_too_long"),
  }),
  competitionCategory: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  competitorName: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  sportClub: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  timeZone: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  locale: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  language: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  country: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  terms: yup.bool()
    .when("isTermsOn", (isTermsOn, schema) => (isTermsOn ? schema.oneOf([true], t("approval_required")) : schema.nullable())),
});
