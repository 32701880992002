import {
  Checkbox, FormControlLabel, FormHelperText, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { marginBottomForFields } from "../../../../services/utils";

export default ({
  form, setFieldValue, isFieldError, fieldErrorMessage, fieldName, label, helper, disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            disabled={disabled}
            checked={form[fieldName]}
            onChange={e => setFieldValue(fieldName, e.target.checked)}
            name={fieldName}
            color="primary"
          />
              )}
        label={_.isObject(label) ? label : t(label)}
      />
      {!_.isNil(helper) && <FormHelperText style={{ width: "100%", marginBottom: marginBottomForFields(), marginTop: -5 }}>{t(helper)}</FormHelperText>}
      {!_.isNil(isFieldError) && !_.isNil(fieldErrorMessage) && isFieldError(fieldName) && (
      <FormHelperText style={{
        width: "100%", marginBottom: marginBottomForFields(), marginTop: -5, color: theme.palette.error.main,
      }}
      >
        {fieldErrorMessage(fieldName)}
      </FormHelperText>
      )}
    </>
  );
};
