import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, Menu, MenuItem, Select, TextField, Tooltip, useMediaQuery, useTheme,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { DatePicker } from "@mui/x-date-pickers";
import { addHours } from "date-fns";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { planFilterGet } from "../../../../services/api/plan";
import { useAnchorControl, useDebounceState } from "../../../../services/hooks";
import { SelectDropdownFieldTemplate } from "../../templates";

export default React.memo(({
  callback, sorts, defaultSort, disableSearch, disableFilter, limits, filtersInit, metadata, initLimit = 10, overrideSm = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(initLimit);
  const [sort, setSort] = React.useState(defaultSort);
  const [filters, setFilters] = React.useState(filtersInit);
  const [reload, setReload] = React.useState(false);
  const { debounceValue, setDebounce } = useDebounceState(() => {
    setPage(1);
    callback(debounceValue, 1, limit, getSort(sort), getFilters());
  }, 0, "");

  const getSort = React.useCallback((idx = null) => {
    if (_.isNil(sorts) || sorts.length === 0) {
      return "";
    }

    if (!_.isNil(idx)) {
      return sorts[idx].desc;
    }

    if (defaultSort === -1) {
      return sorts[0].desc;
    }

    return sorts[defaultSort].desc;
  }, [sorts, defaultSort]);

  const getFilters = React.useCallback(() => JSON.stringify(_.mapValues(filters, "value")), [filters]);
  const callbackMemo = React.useCallback(callback, [callback]);

  React.useEffect(() => {
    if (reload) {
      callbackMemo(debounceValue, page, limit, getSort(sort), getFilters());
      setReload(false);
    }
  }, [metadata, reload, debounceValue, page, limit, sort, filters, callbackMemo, getFilters, getSort]);

  const calcPages = () => Math.ceil(metadata.total / metadata.limit);

  const onPageChange = (event, value) => {
    setPage(value);
    setReload(true);
  };

  const onLimitChange = event => {
    setLimit(event.target.value);
    setPage(1);
    setReload(true);
  };

  const onSortChange = event => {
    setSort(event.target.value);
    setPage(1);
    setReload(true);
  };

  const onFiltersChange = (event, key, filter) => {
    switch (filter.type) {
      case "select": {
        filter.value = event.target.value;
        break;
      }
      case "boolean": {
        filter.value = event.target.checked;
        break;
      }
      case "date": {
        filter.value = addHours(event, new Date().getUTCHours() * -1);
        break;
      }
      default:
    }
    setFilters({
      ...filters, [key]: filter,
    });
    setPage(1);
    setReload(true);
  };

  const onFilterChangePlan = (key, value) => {
    setFilters({
      ...filters, [key]: { ...filters[key], value },
    });
    setPage(1);
    setReload(true);
  };

  return (
    <Box display="flex" width="100%" flexWrap="wrap">
      {!disableSearch && (
      <Box flex="0 0 160px" display="flex" flexDirection="column" justifyContent="middle" order={1}>
        <Box>
          <FormControl>
            <TextField
              variant="standard"
              label={t("search")}
              value={debounceValue}
              onChange={e => setDebounce(e.target.value, 1000)}
              style={{ marginBottom: 12, width: 150 }}
            />
          </FormControl>
        </Box>
      </Box>
      )}
      <Box flex="1 0 auto" display="flex" flexDirection="column" alignItems="center" justifyContent="center" order={isSm || overrideSm ? 4 : 2}>
        {metadata.total > 0 && <Pagination count={calcPages()} defaultPage={metadata.total > 0 ? 1 : 0} onChange={onPageChange} page={page} />}
      </Box>
      {!disableFilter && (
      <Box flex={isSm || overrideSm ? "1 0 150px" : "0 0 80px"} display="flex" flexDirection="column" justifyContent="flex-end" alignSelf="flex-end" order={3}>
        <Box flex="0 1" alignSelf="flex-end">
          <Tooltip placement="bottom" title={t("filters")} disableTouchListener>
            <IconButton aria-controls="filter-menu" aria-haspopup="true" onClick={setAnchor} style={{ marginBottom: 6 }}>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="filter-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={removeAnchor}
          >
            <Box display="flex" flexWrap="wrap" style={{ margin: 10, maxWidth: 250 }}>
              <Box flex="1 0 100%">
                <FormControl style={{ width: "100%" }} variant="standard">
                  <InputLabel id="total-items">{t("quantity")}</InputLabel>
                  <Select
                    labelId="total-items"
                    id="total-items-select"
                    value={limit}
                    onChange={onLimitChange}
                  >
                    {_.map(limits, (limitVal, i) => <MenuItem key={`limit_${i}`} value={limitVal}>{limitVal}</MenuItem>)}
                  </Select>
                </FormControl>
              </Box>
              <Box flex="1 0 100%">
                {!_.isNil(sorts) && (
                <FormControl style={{ width: "100%", marginTop: 10 }} variant="standard">
                  <InputLabel id="sorts">{t("sort")}</InputLabel>
                  <Select
                    labelId="sorts"
                    id="sorts-select"
                    value={sort}
                    onChange={onSortChange}
                  >
                    {_.map(sorts, (sort, i) => <MenuItem key={`sort_${i}`} value={i}>{sort.label}</MenuItem>)}
                  </Select>
                </FormControl>
                )}
              </Box>
              <Box flex="1 0 100%">
                {!_.isNil(filters) && (
                <Box>
                  {_.map(filters, (filter, key) => (
                    <Box key={`filter_${key}`}>
                      {filter.type === "boolean" && (
                      <FormControlLabel
                        style={{ marginTop: 5, width: "100%" }}
                        control={<Checkbox checked={filter.value} onChange={e => onFiltersChange(e, key, filter)} name={filter.name} />}
                        label={filter.label}
                      />
                      )}
                      {filter.type === "date" && (
                      <FormControl style={{ marginTop: 10, width: "100%" }}>
                        <DatePicker
                          autoOk
                          name="date"
                          label={filter.label}
                          mask=""
                          value={filter.value}
                          onChange={val => onFiltersChange(val, key, filter)}
                          renderInput={args => (
                            <TextField
                              variant="standard"
                              {...args}
                              helperText=""
                              size="small"
                            />
                          )}
                        />
                      </FormControl>
                      )}
                      {filter.type === "select" && (
                      <FormControl style={{ width: "100%", marginTop: 10 }} variant="standard">
                        <InputLabel id="sorts">{t(filter.label)}</InputLabel>
                        <Select
                          labelId={filter.label}
                          id={`${filter.label}-select`}
                          value={filter.value}
                          onChange={val => onFiltersChange(val, key, filter)}
                        >
                          {_.map(filter.items, (item, i) => <MenuItem key={`${filter.label}_${i}`} value={item.desc}>{item.label}</MenuItem>)}
                        </Select>
                      </FormControl>
                      )}
                      {filter.type === "plan" && (
                      <SelectDropdownFieldTemplate
                        fieldName="plan"
                        form={{ plan: filter.value }}
                        setFieldValue={onFilterChangePlan}
                        styleVariant="filter"
                        filterGet={planFilterGet}
                        label="plan"
                        payloadBranch="plans"
                        listMapFunc={item => ({ value: item._id, label: item.name })}
                        bgFrom="MuiMenu"
                        filter={_.isNil(filter.filter) ? "" : JSON.stringify(filter.filter)}
                      />
                      )}
                    </Box>
                  ))}
                  <Button
                    style={{ marginTop: 10 }}
                    size="small"
                    onClick={() => {
                      setFilters(filtersInit);
                      setReload(true);
                    }}
                  >
                    {t("reset_filters")}
                  </Button>
                </Box>
                )}
              </Box>
            </Box>
          </Menu>
        </Box>
      </Box>
      )}
    </Box>
  );
});
