import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default () => {
  const { t } = useTranslation();

  return (
    <Box position="relative" width="100%">
      <Typography variant="h1" style={{ fontWeight: 800, textAlign: "center", marginTop: 30 }}>404</Typography>
      <Typography variant="h5" style={{ fontWeight: 300, textAlign: "center", marginTop: 30 }}>
        {t("page_not_found")}
      </Typography>
      <Typography variant="h6" style={{ fontWeight: 800, textAlign: "center", marginTop: 30 }}>
        <Link to="/"><Button>{t("get_back_to_main_page")}</Button></Link>
      </Typography>
    </Box>
  );
};
