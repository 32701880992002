import { Chip } from "@mui/material";
import _ from "lodash";
import React from "react";

import { competitorDelete } from "../../../services/api/competitor";
import { models } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import Body from "./_body";

export default React.memo(({
  item, highlight = [], callback, use,
}) => (
  <ModelItemTemplate
    inModal={_.isFunction(use)}
    header={<ItemHeaderWithHighlight name={createUserDisplayName(item.competitor)} highlight={highlight} />}
    body={<Body item={item} highlight={highlight} />}
    footer={(
      <ItemFooter
        item={item}
        callback={callback}
        use={use}
        model={models.COMPETITOR}
        modelDelete={competitorDelete}
        chip={_.isNil(item.competitionCategory)
          ? null
          : <Chip padding="0" color="info" size="small" label={item.competitionCategory} />}
      />
    )}
  />
));
