import _ from "lodash";
import React from "react";

/**
 * Simple debounce hook, which calls a callback function after
 * provided time in millis on value change. It cancels and restart a debaunce
 * if value has changed before the callback was called.
 * @param {Function} callback callback function
 * @param {Int} initDelay delay in millis
 * @param {*} initVal any value
 * @returns {Object} { debounceValue, setDebounce }
 */
export default (callback, initDelay = 0, initVal = null) => {
  const [debounceValue, setDebounceValue] = React.useState(initVal);
  const [delay, setDelay] = React.useState(null);

  const setDebounce = (newDebounceValue, newDelay = null) => {
    setDebounceValue(newDebounceValue);

    if (!_.isNil(newDelay)) {
      setDelay(newDelay);
    }
  };

  React.useEffect(() => {
    if (_.isNil(delay)) {
      setDelay(initDelay);
    }
    const handler = _.debounce(callback, delay);
    handler();

    return () => {
      handler.cancel();
    };
  }, [debounceValue, delay, initDelay]);

  return { debounceValue, setDebounce };
};
