import {
  Typography,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { useCommonDateFormats } from "../../../services/hooks";
import GenericNotificationItem from "./_generic-notification-item";

export default memo(({ notif }) => {
  const { t } = useTranslation();
  const { formatDate } = useCommonDateFormats();

  return (
    <GenericNotificationItem notif={notif} title={t("thanks_for_joining_x_program", { plan: notif.metadata.planName })}>
      <Typography
        sx={{ marginBottom: "5px" }}
        component="span"
        variant="body2"
        color="text.primary"
      >
        {t("your_subscription_is_valid_until", { date: formatDate(notif.metadata.subsEnd) })}
      </Typography>
    </GenericNotificationItem>
  );
});
