import _ from "lodash";

import { units } from "../../../../../services/enums";
import { selectUnit, timeStringFormat } from "../../../../../services/utils";

export default (unit, data) => {
  switch (unit) {
    case units.WEIGHT:
    case units.DISTANCE:
    case units.REPS:
    case units.ROUNDS:
      return `${data.valueA} ${selectUnit(unit)}`;
    case units.TIME:
      return !_.isNil(data.isTimeCap) && data.isTimeCap ? `CAP+${data.timeCapReps}` : timeStringFormat(data.valueA, data.valueB, data.valueC);
    case units.DESCRIPTION:
      return data.valueA;
    default:
      return null;
  }
};
