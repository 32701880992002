import * as yup from "yup";

export default t => yup.object().shape({
  email: yup.string().trim()
    .required(t("field_is_required"))
    .email(t("invalid_email_format"))
    .max(1000, "text_too_long"),
  note: yup.string().trim()
    .max(2000, "text_too_long"),
});
