import { Lock, Mail } from "@mui/icons-material";
import { Box, InputAdornment } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { CheckboxField, RoleSelectField, TextField } from "../../components/shared/fields";
import {
  ErrorMessages, FormDialogActions, Loading, UrlifiedTypography,
} from "../../components/shared/other";
import { registerAction } from "../../services/api/other";
import { useFormEngine } from "../../services/hooks";
import formInit from "./_form-init";
import formSchema from "./_form-schema";
import rootRedirectFuncDef from "./_root-redirect-func-def";

export default () => {
  const actions = {};
  const history = useHistory();
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, registerAction, null, null, formInit(), formSchema(t), [rootRedirectFuncDef(history)]);

  return (
    <Box>
      {isLoading() && <Loading />}
      <TextField
        variant="standard"
        label="your_email"
        fieldName="email"
        form={form}
        isFieldError={isFieldError}
        fieldErrorMessage={fieldErrorMessage}
        setFieldValue={setFieldValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Mail />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputProps={{ "data-testid": "password" }}
        variant="standard"
        type="password"
        label="password"
        fieldName="password"
        form={form}
        isFieldError={isFieldError}
        fieldErrorMessage={fieldErrorMessage}
        setFieldValue={setFieldValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        inputProps={{ "data-testid": "repeat-password" }}
        variant="standard"
        type="password"
        label="repeat_password"
        fieldName="repeatPassword"
        form={form}
        isFieldError={isFieldError}
        fieldErrorMessage={fieldErrorMessage}
        setFieldValue={setFieldValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>
          ),
        }}
      />
      <RoleSelectField
        variant="standard"
        form={form}
        isFieldError={isFieldError}
        fieldErrorMessage={fieldErrorMessage}
        setFieldValue={setFieldValue}
      />
      <CheckboxField
        label={<UrlifiedTypography labels={[t("terms_and_conditions")]}>{t("terms_and_conditions_checkbox")}</UrlifiedTypography>}
        fieldName="terms"
        form={form}
        setFieldValue={setFieldValue}
        isFieldError={isFieldError}
        fieldErrorMessage={fieldErrorMessage}
      />

      <ErrorMessages isError={isError()} errors={errors()} />

      <FormDialogActions send={handleSubmit} />
    </Box>
  );
};
