import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  DialogComponent, callback, tooltip = "edit", color = "primary",
}) => {
  const { t } = useTranslation();
  const actions = {};

  return (
    <>
      <Tooltip title={t(tooltip)} placement="top" disableTouchListener>
        <IconButton color={color} component="span" onClick={() => actions.edit()}>
          <EditIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <DialogComponent actions={actions} callback={callback} />
    </>
  );
};
