import { useTheme } from "@emotion/react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
// import TouchAppIcon from "@mui/icons-material/TouchApp";
import {
  Alert,
  Box, Paper, Typography,
} from "@mui/material";
import { addDays, addHours, differenceInHours } from "date-fns";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { entryCustomerBetweenDatesByPlanIdGet } from "../../../../../services/api/entry";
import { intervals } from "../../../../../services/enums";
import { useCommonDateFormats } from "../../../../../services/hooks";
import { setDateByInterval } from "../../../../../services/utils";
import { PaymentButton } from "../../../../shared/buttons";
import { BoxMessage, Loading } from "../../../../shared/other";
import { ModelItemTemplate } from "../../../../shared/templates";
import PlanBoxEntryDialog from "../PlanBoxEntryDialog";
import Buttons from "./Buttons";

export default React.memo(({ plan, reloadPlans, initShift = 0 }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { formatByInterval, formatWholeDate } = useCommonDateFormats();
  const [entries, setEntries] = React.useState([]);
  const [shift, setShift] = React.useState(initShift);
  const [prevShift, setPrevShift] = React.useState(0);
  const [nosub, setNosub] = React.useState(false);
  const [nextDay, setNextDay] = React.useState(false);
  const [notAccessible, setNotAccessible] = React.useState(false);
  const { loading, query } = useParameterizedQuery(entryCustomerBetweenDatesByPlanIdGet, false);

  const actions = {};

  const subsEndingIn = () => (plan.subscription?.end ? differenceInHours(new Date(plan.subscription.end), new Date()) : 0);

  const getShiftByInterval = () => {
    if (_.includes([intervals.DAILY, intervals.WEEKLY, intervals.MONTHLY], plan.interval)) {
      return shift;
    }

    if (_.includes([intervals.COMPETITION, intervals.STATIC], plan.interval)) {
      return shift - 1;
    }
    return shift;
  };

  const loadEntries = React.useCallback(async () => {
    const { payload, error } = await query(
      plan._id,
      setDateByInterval(plan.interval, 0, new Date(), getShiftByInterval()).toISOString(),
      setDateByInterval(plan.interval, 1, new Date(), getShiftByInterval()).toISOString(),
    );

    if (error) {
      setNosub(_.isEqual(payload?.errors[0], "no_subscription"));
      setNotAccessible(_.isEqual(payload?.errors[0], "not_yet_accessible"));
      setEntries([]);
    } else {
      setNosub(false);
      setNotAccessible(false);
      setNextDay(payload?.nextDay);
      setEntries(payload?.entries?.data);
    }
  }, [plan, shift, query]);

  React.useEffect(() => {
    loadEntries();
  }, [loadEntries]);

  const getNoAccessMessage = () => {
    const txt = t("program_will_be_available_from");
    const date = formatWholeDate(addHours(setDateByInterval(plan.interval, 0, new Date(), shift), _.isNil(plan.shift) ? 0 : plan.shift));

    return `${txt} ${date}`;
  };

  const showBackButton = () => {
    if (_.includes([intervals.DAILY, intervals.WEEKLY, intervals.MONTHLY], plan.interval)) {
      if (!nosub || shift > prevShift) {
        return true;
      }
    }

    if (_.includes([intervals.COMPETITION, intervals.STATIC], plan.interval)) {
      if (shift > 0) {
        return true;
      }
    }
    return false;
  };

  const showForwardButton = () => {
    if (_.includes([intervals.DAILY, intervals.WEEKLY, intervals.MONTHLY], plan.interval)) {
      if (notAccessible) {
        return false;
      }
      if (!nosub || shift > prevShift) {
        return true;
      }
    }
    return nextDay;
  };

  return (
    <>
      {showBackButton() ? (
        <Box
          flex="1 0"
          paddingRight="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            "&:hover": { color: theme.palette.primary.main, cursor: "pointer" },
            "@media (hover: none)": {
              "&:hover": { color: "inherit" },
            },
          }}
          onClick={() => {
            setPrevShift(shift);
            setShift(shift - 1);
          }}
        >
          <ArrowBackIosNewIcon fontSize="large" />
        </Box>
      ) : <Box flex="1 0" />}
      <Box flex="8 0">
        <Box display="flex" flexDirection="column" justifyContent="center" width="100%" minHeight="220px" position="relative">
          <Box position="relative">
            {loading && <Loading />}
            <Paper style={{ display: "flex", marginBottom: "10px" }}>
              <Box flex="1" display="flex" margin="16px">
                <Box flex="1 0" display="flex" flexDirection="column" justifyContent="center">
                  {_.includes([intervals.DAILY, intervals.WEEKLY, intervals.MONTHLY], plan.interval) && (
                  <Typography variant="body2" style={{ fontWeight: 800 }}>
                    {formatByInterval(plan.interval, addDays(new Date().setHours(12), shift))}
                  </Typography>
                  )}
                  {_.includes([intervals.COMPETITION, intervals.STATIC], plan.interval) && !plan.hiddenDays && (
                  <Typography variant="body2" style={{ fontWeight: 800 }}>
                    {t("day")}
                    {" "}
                    {shift + 1}
                  </Typography>
                  )}
                </Box>
                <Buttons plan={plan} shift={shift} setShift={setShift} setPrevShift={setPrevShift} reloadPlans={reloadPlans} />
              </Box>
            </Paper>
            {!nosub && !notAccessible && entries.length === 0 && <BoxMessage msg="no_program_for_this_day" />}
            {notAccessible && !nosub && <BoxMessage msg={getNoAccessMessage()} notrans />}
            {subsEndingIn() < 72 && plan.price > 0 && !_.isNil(plan.user.stripe) && (
            <Alert
              severity={Math.floor(subsEndingIn() / 24) === 0 ? "warning" : "info"}
              sx={{ marginBottom: "8px" }}
            >
              {t("sub_ending_in_x_days", { x: Math.floor(subsEndingIn() / 24) })}
              <PaymentButton planId={plan._id} price={plan.price} currency={plan.user?.stripe?.currency} extend />
            </Alert>
            )}
            {nosub && <BoxMessage msg="your_subscription_ends_up_here_extend_to_gain_access" color="red" />}
            <Box display="flex" flexWrap="wrap">
              {entries.map((entry, idx) => (
                <ModelItemTemplate
                  key={idx}
                  inModal
                  header={<Typography variant="body1" style={{ width: "100%", fontWeight: 600, wordBreak: "break-word" }}>{entry.title}</Typography>}
                  body={(
                    <Typography variant="caption" style={{ whiteSpace: "pre-line", width: "100%", wordBreak: "break-word" }}>
                      {entry.content}
                    </Typography>
                  )}
                  onClick={entry.isInfo ? null : () => actions.open(plan, entry, shift, loadEntries)}
                  footer={!entry.isInfo && (
                    <>
                      <Box flex="1 0" justifyContent="flex-start">
                        {/* <TouchAppIcon fontSize="small" color="disabled" /> */}
                      </Box>
                      <Box display="flex" justifyContent="flex-end">
                        <TextSnippetIcon fontSize="small" color={_.isNil(entry.userNote) ? "disabled" : "success"} />
                        {entry.isScore && <SportsScoreIcon fontSize="small" color={_.isNil(entry.userScore) ? "disabled" : "success"} />}
                      </Box>
                    </>
                  )}
                />
              ))}
            </Box>
            <PlanBoxEntryDialog actions={actions} />
          </Box>
        </Box>
      </Box>
      {showForwardButton() ? (
        <Box
          flex="1 0"
          paddingLeft="8px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            cursor: "pointer",
            WebkitTapHighlightColor: "transparent",
            "&:hover": { color: theme.palette.primary.main, cursor: "pointer" },
            "@media (hover: none)": {
              "&:hover": { color: "inherit" },
            },
          }}
          onClick={() => {
            setPrevShift(shift);
            setShift(shift + 1);
          }}
        >
          <ArrowForwardIosIcon fontSize="large" />
        </Box>
      ) : <Box flex="1 0" />}
    </>
  );
});
