import {
  addMinutes, addMonths, endOfMonth, format, isWithinInterval, startOfMonth,
} from "date-fns";
import _ from "lodash";
import * as R from "ramda";

export default (data, rows, locale) => {

  const som = val => R.pipe(addMonths, date => startOfMonth(date))(new Date(), val);
  const eom = val => R.pipe(addMonths, date => endOfMonth(date))(new Date(), val);

  const schema = _(rows).map(val => ({
    "isoStart": som(val).toISOString(),
    "isoEnd": eom(val).toISOString(),
    "iso": addMinutes(som(val), 1).toISOString(),
    "month": format(som(val), "M", { locale }),
    "year": format(eom(val), "yyyy", { locale }),
    "som": som(val),
    "eom": eom(val),
    "present": val === 0,
    "items": [],
  }))
    .value();

  _(data).each(item => {
    _(schema).each(val => {
      if (isWithinInterval(new Date(item.date), { start: val.som, end: val.eom })) {
        val.items.push(item);
      }
    });
  });

  _(schema).each(item => {
    item.items = _.orderBy(item.items, ["order"], ["asc"]);
  });

  Reflect.deleteProperty(schema, "som");
  Reflect.deleteProperty(schema, "eom");

  return schema;
};
