import React from "react";
import { useTranslation } from "react-i18next";

import { userChangePasswordPut } from "../../../../services/api/user";
import { useFormEngine } from "../../../../services/hooks";
import { onSuccessCallbacks } from "../../../../services/utils";
import { TextField } from "../../../shared/fields";
import { FormDialogTemplate } from "../../../shared/templates";
import formSchema from "./_form-schema";

const UserProfilePasswordFormDialog = React.memo(({ actions = {}, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, null, userChangePasswordPut, null, {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
  }, formSchema(t), onSuccessCallbacks(callback));

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="password_change"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            type="password"
            label="old_password"
            fieldName="oldPassword"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            type="password"
            label="new_password"
            fieldName="newPassword"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <TextField
            type="password"
            label="repeat_new_password"
            fieldName="repeatNewPassword"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
      )}
    />
  );
});

export default UserProfilePasswordFormDialog;
