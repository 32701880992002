/* eslint-disable no-restricted-syntax */
export default (form, t, enqueueSnackbar, setFieldValue) => item => {
  for (const el of form.media) {
    if (el._id === item._id) {
      enqueueSnackbar(t("item_already_on_the_list"), { variant: "warning" });

      return;
    }
  }
  setFieldValue("media", [...form.media, item]);
};
