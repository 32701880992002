/* eslint-disable react/no-danger */
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LanguageIcon from "@mui/icons-material/Language";
import XIcon from "@mui/icons-material/X";
import {
  Alert, Box, Container, IconButton, Stack,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { Trans, useTranslation } from "react-i18next";

import secureStripePaymentsAsset from "../../../assets/images/secure-stripe-payment-logo.png";
import { userShopByClientNumberGet } from "../../../services/api/user";
import { useSizeStyle } from "../../../services/hooks";
import {
  DangerousHtmlDesc,
  Footer, GenericError, Header, Loading,
} from "../../shared/other";
import PlanItem from "./_plan-item";
import UserProfile from "./_user-profile";

export default ({ clientNumber, footerAndHeader = true, forcedWidth = null }) => {
  const [coach, setCoach] = React.useState(null);
  const { t } = useTranslation();
  const {
    loading, error, status, payload,
  } = useQuery(userShopByClientNumberGet(clientNumber));
  const MarginTop = useSizeStyle({
    xs: { marginTop: 35 },
    sm: { marginTop: 35 },
    md: { marginTop: 35 },
    lg: { marginTop: 100 },
    xl: { marginTop: 100 },
  });

  const FullWidth = useSizeStyle({
    xs: true,
    sm: true,
    md: true,
    lg: false,
    xl: false,
  });

  const Socials = useSizeStyle({
    xs: {
      right: "0px", top: "-40px", display: "flex", justifyContent: "center", width: "100%",
    },
    sm: {
      right: "0px", top: "-40px", display: "flex", justifyContent: "center", width: "100%",
    },
    md: {
      right: "0px", top: "-40px", display: "flex", justifyContent: "center", width: "100%",
    },
    lg: { right: "0px", top: "-90px" },
    xl: { right: "0px", top: "-90px" },
  });

  React.useLayoutEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      setCoach(payload?.user);
    }
  }, [payload]);

  if (error) {
    return <GenericError status={status} />;
  }

  if (loading || _.isNil(coach)) {
    return <Loading />;
  }

  if (_.isNil(coach)) {
    return <Alert severity="warning">{t("page_does_not_exist")}</Alert>;
  }

  if (_.isNil(coach.stripe)) {
    return <Alert severity="warning">{t("page_temporarily_unavailable")}</Alert>;
  }

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      {footerAndHeader && <Header />}
      <Box flex="1 0">
        <Container maxWidth="lg" style={{ paddingTop: 30 }}>
          <Box display="flex" flexWrap="wrap" width="100%" position="relative">
            <UserProfile user={coach} forcedWidth={forcedWidth} />
            {(_.isNil(coach.plans) || coach.plans.length === 0) && (
              <Box display="flex" width="100%" style={MarginTop.select()} flexWrap="wrap">
                <Trans i18nKey="no_plans_for_sale" />
                </Box>
            )}

            <Box
              display="flex"
              width="100%"
              style={MarginTop.select()}
              flexWrap="wrap"
              position="relative"
              justifyContent={coach.plans.length === 2 ? "flex-end" : "flex-start"}
            >
              <Box flex="1 0 100%" position="absolute" sx={Socials.select()}>
                <Stack direction="row" spacing="1px" alignContent="center">
                  {coach.link?.instagram && (
                  <IconButton onClick={() => (window.location.href = coach?.link?.instagram)}>
                    <InstagramIcon />
                  </IconButton>
                  )}
                  {coach.link?.facebook && (
                  <IconButton onClick={() => (window.location.href = coach?.link?.facebook)}>
                    <FacebookIcon />
                  </IconButton>
                  )}
                  {coach.link?.twitter && (
                  <IconButton onClick={() => (window.location.href = coach?.link?.twitter)}>
                    <XIcon />
                  </IconButton>
                  )}
                  {coach.link?.website && (
                  <IconButton onClick={() => (window.location.href = coach?.link?.website)}>
                    <LanguageIcon />
                  </IconButton>
                  )}
                </Stack>
              </Box>
              <Box
                flex="1 0 50%"
                sx={{
                  wordBreak: "break-word", whiteSpace: "break-spaces", textAlign: "justify", padding: "10px",
                }}
              >
                {coach.aboutMe && <DangerousHtmlDesc text={coach.aboutMe} />}
              </Box>
              {coach.plans && coach.plans.map((plan, idx) => <PlanItem key={`plan${idx}`} coach={coach} plan={plan} fullWidth={FullWidth.select()} />)}
            </Box>
            <Box width="100%" textAlign="center" marginTop="100px">
              <img src={secureStripePaymentsAsset} alt="Secure Stripe payments" width="350px" />
            </Box>
          </Box>
        </Container>
      </Box>
      {footerAndHeader && <Footer />}
    </Box>
  );
};
