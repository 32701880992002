import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from "@mui/material";
import {
  endOfMonth, endOfWeek, startOfMonth, startOfWeek,
} from "date-fns";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { entryBetweenDatesByPlanIdGet } from "../../../services/api/entry";
import { intervals, models } from "../../../services/enums";
import { useLoggedUserLocale } from "../../../services/hooks";
import { ModelActionButton } from "../../shared/buttons";
import { GenericError, Loading } from "../../shared/other";
import calcMonthData from "./_calc-month-data";
import EntryItem from "./_entry-item";

export default React.memo(({ plan, date }) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState([]);
  const {
    loading, error, status, payload, query,
  } = useQuery(entryBetweenDatesByPlanIdGet(
    plan._id,
    startOfWeek(startOfMonth(date), { weekStartsOn: 1 }).toISOString(),
    endOfWeek(endOfMonth(date), { weekStartsOn: 1 }).toISOString(),
    "order",
  ));
  const { getLoggedUserLocale } = useLoggedUserLocale();

  React.useEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload.entries)) {
      setData(calcMonthData(payload.entries.data, date, getLoggedUserLocale()));
    }
  }, [payload, loading]);

  if (error) {
    return <GenericError status={status} />;
  }

  return (
    <Box position="relative" minHeight="200px">
      {loading && <Loading bgFrom="MuiDialog" />}

      <TableContainer>
        <Table style={{ tableLayout: "auto" }}>
          <TableHead>
            <TableRow>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_1")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_2")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_3")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_4")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_5")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_6")}</Typography></TableCell>
              <TableCell width="14.28%" style={{ padding: "2px" }}><Typography variant="h4" textAlign="center">{t("d_0")}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((chunk, idx) => (
              <TableRow key={`row_${idx}`}>
                {chunk.map((day, idx) => (
                  <TableCell key={`cell_${idx}`} style={{ position: "relative", verticalAlign: "baseline", padding: "20px 2px 20px 2px" }}>
                    <Typography variant="h6" fontWeight={900} paddingLeft="10px" textAlign="center">
                      {day.dayNo}
                      {" "}
                      <ModelActionButton
                        type="add"
                        variant="iconbutton"
                        model={models.ENTRY}
                        tooltip="add_entry"
                        initData={{
                          isScore: _.isEqual(plan.interval, intervals.COMPETITION),
                          date: day.date,
                          planId: plan._id,
                          planName: plan.name,
                          planInterval: plan.interval,
                          order: day.entries.length + 1,
                        }}
                        callback={query}
                        placement="bottom"
                      />
                    </Typography>
                    <Box display="flex" flexDirection="column" minHeight="30px">
                      {day.entries.map((entry, idx) => (
                        <EntryItem key={`row_${idx}`} entry={entry} plan={plan} callback={query} />
                      ))}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
});
