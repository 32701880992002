import * as yup from "yup";

import { roles } from "../../services/enums";
import passwordsMatch from "./_passwords-match";

export default t => yup.object().shape({
  email: yup.string()
    .required(t("field_is_required"))
    .email(t("invalid_email_format"))
    .max(1000, "text_too_long"),
  password: yup.string().trim()
    .required(t("field_is_required"))
    .max(1000, "text_too_long"),
  repeatPassword: yup.string().trim()
    .required(t("field_is_required"))
    .when("password", passwordsMatch(t))
    .max(1000, "text_too_long"),
  timeZone: yup.string()
    .required(t("field_is_required")),
  locale: yup.string().trim()
    .required(t("field_is_required")),
  language: yup.string().trim()
    .required(t("field_is_required")),
  country: yup.string().trim()
    .required(t("field_is_required")),
  terms: yup.bool().oneOf([true], t("approval_required"))
    .required(t("field_is_required")),
  role: yup.string().oneOf([roles.COACH_NAME, roles.CUSTOMER_NAME], t("role_required"))
    .required(t("field_is_required")),
});
