import { Chip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { planShareDelete } from "../../../services/api/plan-share";
import { models } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";
import Body from "./_body";

export default React.memo(({
  item, highlight = [], callback, use,
}) => {
  const { t } = useTranslation();
  return (
    <ModelItemTemplate
      inModal={_.isFunction(use)}
      header={(
        <ItemHeaderWithHighlight
          name={_.isNil(item.coach)
            ? item.name
            : createUserDisplayName(item.coach)}
          highlight={highlight}
        />
)}
      body={<Body item={item} highlight={highlight} />}
      footer={(
        <ItemFooter
          item={item}
          callback={callback}
          use={use}
          model={models.PLAN_SHARE}
          modelDelete={planShareDelete}
          showEdit={!_.isNil(item.coach)}
          showUse={!_.isNil(item.isEditing) && item.isEditing}
          chip={(!_.isNil(item.isEditing) && item.isEditing)
            ? <Chip padding="0" color="success" size="small" label={t("sharing_edit")} />
            : <Chip padding="0" color="default" size="small" label={t("sharing_preview")} />}
        />
)}
    />
  );
});
