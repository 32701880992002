import {
  Alert, Box, List, Paper, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { competitionLeaderboardGet } from "../../../services/api/competition";
import { useSizeStyle } from "../../../services/hooks";
import { EntrySelectField } from "../../shared/fields";
import { GenericError, Loading } from "../../shared/other";
import LeaderboardItem from "./_leaderboard-item";
import LeaderboardItemNoScore from "./_leaderboard-item-no-score";

export default ({ plan, category }) => {
  const { t } = useTranslation();
  const {
    error, loading, payload, query,
  } = useParameterizedQuery(competitionLeaderboardGet);
  const [event, setEvent] = React.useState("all");
  const [competitors, setCompetitors] = React.useState([]);
  const FlexRightCol = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "1 0 33%", lg: "1 0 33%", xl: "1 0 33%",
  });
  const BoxStyle = useSizeStyle({
    xs: {}, sm: {}, md: {}, lg: { marginLeft: "5px", marginRight: "5px" }, xl: { marginLeft: "5px", marginRight: "5px" },
  });
  const SelectStyle = useSizeStyle({
    xs: {}, sm: {}, md: {}, lg: { marginLeft: "5px" }, xl: { marginLeft: "5px" },
  });

  React.useLayoutEffect(() => {
    if (payload?.competitors) {
      setCompetitors(payload.competitors);
    }
  }, [payload]);

  if (error) {
    return <Box flex={FlexRightCol.select()} position="relative"><GenericError /></Box>;
  }

  return (
    <Box flex={FlexRightCol.select()} position="relative">
      {loading && <Loading />}

      <Typography variant="h4" color="default" style={{ wordBreak: "break-word", textAlign: "center" }}>
        {t("leaderboard")}
      </Typography>

      <EntrySelectField
        form={{ planId: plan._id, event, category }}
        isFieldError={() => false}
        fieldErrorMessage={() => ""}
        useOutsideForm
        size="small"
        setFieldValue={(field, value) => {
          setEvent(value);
          query(plan._id, category, value);
        }}
        style={SelectStyle.select()}
      />
      <Box style={BoxStyle.select()}>
        <Paper style={{ padding: 8 }}>
          <List>
            {_.map(competitors, (item, idx) => (!_.isNil(item.noScoreYet) && item.noScoreYet
              ? <LeaderboardItemNoScore key={`u${idx}`} competitor={item} />
              : <LeaderboardItem key={`u${idx}`} competitor={item} idx={idx} isEvent={payload?.isEvent} />
            ))}
            {payload?.competitors.length === 0 && <Alert severity="info">{t("no_competitors")}</Alert>}
          </List>
        </Paper>
      </Box>
    </Box>
  );
};
