/* eslint-disable jsx-a11y/media-has-caption */
import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";

import { fileBlobGet } from "../../../services/api/file";
import { imageOrVideo } from "../../../services/utils";

export default ({ form, file }) => (
  <>
    {form.data && !_.isEqual(form.data, "none") && (
    <Box flex="1 0 100%">
      <Box width="100%" height={300}>
        {imageOrVideo(form.mime) === 1 && <img src={form.data} alt="sample" width="100%" />}
        {imageOrVideo(form.mime) === 0 && (
        <video width="100%" controls preload="metadata">
          <source src={form.data} type={form.mime} />
          VIDEO
        </video>
        )}
      </Box>
    </Box>
    )}
    {!_.isEmpty(file) && (
    <Box flex="1 0 100%">
      <Box width="100%" height={300}>
        {imageOrVideo(file.mime) === 1 && <img src={fileBlobGet(file)} alt="sample" width="100%" />}
        {imageOrVideo(file.mime) === 0 && (
        <video width="100%" controls preload="metadata">
          <source src={fileBlobGet(file)} type={file.mime} />
          VIDEO
        </video>
        )}
      </Box>
    </Box>
    )}
  </>
);
