/* eslint-disable new-cap */
import _ from "lodash";

export default () => {
  const data = Intl.DateTimeFormat().resolvedOptions();
  const navLang = navigator.language;

  let timeZone = "Europe/Warsaw";
  let locale = "pl-PL";
  let language = "pl";
  let country = "PL";

  if (!_.isNil(data.timeZone) && !_.isEmpty(data.timeZone)) {
    timeZone = data.timeZone;
  }

  if (!_.isNil(data.locale) && !_.isEmpty(data.locale) && data.locale.length === 5) {
    locale = data.locale;
    language = data.locale.slice(0, 2);
    country = data.locale.slice(3, 5);
  } else if (!_.isNil(navLang) && !_.isEmpty(navLang) && navLang.length === 5) {
    locale = navLang;
    language = navLang.slice(0, 2);
    country = navLang.slice(3, 5);
  } else if (!_.isNil(navLang) && !_.isEmpty(navLang) && navLang.length === 2) {
    locale = `${navLang}-${navLang.toUpperCase()}`;
    language = navLang;
    country = navLang.toUpperCase();
  }

  return {
    timeZone, locale, language, country,
  };
};
