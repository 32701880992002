import {
  AppBar, Box, Container, Toolbar, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";

import { localStorageDict, roles } from "../../../../services/enums";
import { useLocalStorage, useRoleValidator, useSizeStyle } from "../../../../services/hooks";
// import { MessageButton } from "../../../message";
import { assetUrl } from "../../../../services/routes";
import { CompetitionSignUpButton } from "../../../competition";
import { NotificationButton } from "../../../notification";
import {
  LanguageSwitchButton, LoginButton, PlanTermsButton, ThemeSwitchButton,
} from "../../buttons";
import { HeaderMenu } from "..";

export default React.memo(({ callback, competition = false, plan = null }) => {
  const { isRole } = useRoleValidator();
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
  const th = useTheme();
  const nopadding = { paddingLeft: 0, paddingRight: 0 };
  const NoPaddingHook = useSizeStyle({ xs: nopadding, sm: nopadding, md: nopadding });
  const ButtonsVisibilityHook = useSizeStyle({
    xs: false, sm: false, md: true, lg: true, xl: true, default: true,
  });

  return (
    <>
      <Box height="48px" width="100%" />
      <AppBar position="fixed">
        <Toolbar variant="dense" style={{ backgroundColor: th.components.MuiPaper.styleOverrides.root.backgroundColor }}>
          <Container maxWidth="lg" style={NoPaddingHook.select()}>
            <Box display="flex">
              <Box display="flex" flex="1 0" alignItems="center" justifyContent="left">
                <a href="/">
                  <img
                    src={_.isEqual(theme, localStorageDict.THEME.VALUES.DARK) ? assetUrl("logo_282x130_white.png") : assetUrl("logo_282x130_black.png")}
                    alt="LOGO"
                    style={{ maxHeight: 35, verticalAlign: "middle" }}
                  />
                </a>
              </Box>
              <Box display="flex" flex="1 0" justifyContent="flex-end">
                <Box>
                  {competition && ButtonsVisibilityHook.select() && (
                  <>
                    {!_.isNil(plan) && plan.terms && <PlanTermsButton plan={plan} />}
                    {!_.isNil(plan) && <CompetitionSignUpButton plan={plan} callback={callback} />}
                    {(!isRole(roles.COACH) && !isRole(roles.CUSTOMER)) && <LoginButton />}
                  </>
                  )}
                  {(isRole(roles.COACH) || isRole(roles.CUSTOMER)) && <NotificationButton />}
                  {ButtonsVisibilityHook.select() && <LanguageSwitchButton />}
                  {ButtonsVisibilityHook.select() && <ThemeSwitchButton />}
                  {/* {(isRole(roles.COACH) || isRole(roles.CUSTOMER)) && ButtonsVisibilityHook.select() && <MessageButton />} */}
                  {isRole(roles.COACH) && <HeaderMenu competition={competition} plan={plan} callback={callback} isCoach />}
                  {isRole(roles.CUSTOMER) && <HeaderMenu competition={competition} plan={plan} callback={callback} isCustomer />}
                  {competition && !isRole(roles.CUSTOMER) && !isRole(roles.COACH) && !ButtonsVisibilityHook.select() && <HeaderMenu competition={competition} plan={plan} callback={callback} />}
                </Box>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
});
