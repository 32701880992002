import { Backdrop, CircularProgress, useTheme } from "@mui/material";
import React from "react";

export default React.memo(({ open = true }) => {
  const theme = useTheme();

  return (
    <Backdrop
      aria-label="loading-global"
      open={open}
      style={{
        color: theme.palette.background.default,
        zIndex: theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
});
