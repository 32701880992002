import _ from "lodash";
import { useSnackbar } from "notistack";
import { useMutation, useParameterizedQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../api";
import { funcCaller } from "../utils";
// import { useHistory } from "react-router-dom";

/**
 * Manage models data manipulations
 *
 * @param {Function} createFunc Function to create "create" definition
 * @param {Function} updateFunc Function to create "update" definition
 * @param {Function} queryFunc Function to load data for editing
 * @param {Array} onSuccessCallbacks Functions to call after successful submit
 * @param {Array|Function} onLoadCallbacks Functions to call on successful load data for editing
 * @param {Function} beforeSubmitFunc Function to manipulate data right before submission
 * @param {Function} setFieldValues Function from useFormEngine to update form data
 * @returns {Object} { submit, isLoading, isError, errors, load }
 */
export default (createFunc, updateFunc, queryFunc, onSuccessCallbacks = [], onLoadCallbacks = [], beforeSubmitFunc = null, setFieldValues = null) => {
  const { t } = useTranslation();
  // const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const Mutation = useMutation(apiAction);
  const ModelQuery = useParameterizedQuery(queryFunc, false);

  const submit = async form => {
    let submitData = form;

    if (!_.isNil(beforeSubmitFunc)) {
      submitData = beforeSubmitFunc(submitData);
    }
    let mutateDesc = null;

    if (_.isNil(submitData._id) && !_.isNil(createFunc)) {
      mutateDesc = createFunc(submitData);
    } else {
      mutateDesc = updateFunc(submitData);
    }
    const { error, payload } = await Mutation.mutate(...mutateDesc);

    if (!error) {

      if (!_.isNil(payload) && !_.isNil(payload.redirect)) {
        window.location.href = payload.redirect;

        return false;
      }

      funcCaller(_(onSuccessCallbacks)
        .map(item => ({ func: item.func, args: item.args ? [...item.args, payload, form] : [payload, form] }))
        .value());

      if (!_.isNil(payload) && !_.isNil(payload.message)) {
        enqueueSnackbar(t(payload.message), { variant: "success" });
      } else {
        enqueueSnackbar(t("saved_successfully"), { variant: "success" });
      }

      return false;
    }

    return true;
  };

  const load = async id => {
    if (_.isNil(queryFunc)) {
      return;
    }
    const { error, payload } = await ModelQuery.query(id);

    if (!error) {
      runLoadCallbacks(id, payload.data);
    }
  };

  const runLoadCallbacks = (id, data) => {
    let olcs = onLoadCallbacks;

    if (_.isFunction(onLoadCallbacks)) {
      olcs = onLoadCallbacks(setFieldValues);
    }
    const funcs = olcs.map(item => ({ ...item, args: [id, data] }));
    funcCaller(funcs);
  };

  const isLoading = () => Mutation.loading || ModelQuery.loading;
  const isError = () => !Mutation.loading && Mutation.error;
  const errors = () => (Mutation.error && !_.isNil(Mutation.payload) ? Mutation.payload.errors : []);
  const reset = () => Mutation.reset();

  return {
    submit, isLoading, isError, errors, load, runLoadCallbacks, reset,
  };
};
