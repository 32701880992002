import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default ({
  textToCopy, size = "small", label = "copy", disabled = false,
}) => {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(label)} placement="top" disableTouchListener>
      <span>
        <IconButton
          disabled={disabled}
          size={size}
          onClick={() => {
            navigator.clipboard.writeText(textToCopy);
          }}
        >
          <ContentCopyIcon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
};
