import _ from "lodash";

export default (key, value = null) => {
  if (_.isNil(key)) {
    return;
  }
  const newValue = typeof value === "object" ? JSON.stringify(value) : value;
  window.localStorage.setItem(key, newValue);
  window.dispatchEvent(new CustomEvent("onLocalStorageChange", { detail: { key, value: newValue } }));
};
