import {
  addDays, addHours, endOfMonth, endOfWeek, format, isBefore, startOfMonth, startOfWeek,
} from "date-fns";
import _ from "lodash";

export default (entries, date, locale) => {
  const data = [];
  date.setUTCHours(12);
  const utcHours = date.getUTCHours();
  const som = addHours(startOfMonth(date), utcHours);
  const eom = addHours(endOfMonth(date), utcHours - 24);
  let sow = addHours(startOfWeek(som, { weekStartsOn: 1 }), utcHours);
  const eow = addHours(endOfWeek(eom, { weekStartsOn: 1 }), utcHours - 24);

  while (isBefore(sow, eow)) {
    const curDateString = format(sow, "dd.MM.yyyy", { locale });

    data.push({
      dayNo: format(sow, "d"),
      date: sow.toISOString(),
      entries: _(entries).filter(entry => {
        const entryDateString = format(new Date(entry.date), "dd.MM.yyyy", { locale });

        return curDateString === entryDateString;

      })
        .value(),
    });

    sow = addDays(sow, 1);
  }

  return _.chunk(data, 7);
};
