import * as yup from "yup";

export default t => yup.object().shape({
  name: yup.string().trim()
    .required(t("field_is_required"))
    .max(1000, "text_too_long"),
  description: yup.string().trim()
    .max(10000, "text_too_long"),
  data: yup.string().required(t("file_is_required")),
  mime: yup.string().trim(),
  fileId: yup.string().trim(),
});
