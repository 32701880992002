import EqualizerIcon from "@mui/icons-material/Equalizer";
import NoteIcon from "@mui/icons-material/Note";
import PercentIcon from "@mui/icons-material/Percent";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  Box, IconButton, Paper, Tooltip,
} from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import NotesPreviewDialog from "../NotesPreviewDialog";
import PlansVisibilityDialog from "../PlansVisibilityDialog";
import ScoresPreviewDialog from "../ScoresPreviewDialog";
import WeightCalculatorDialog from "./WeightCalculatorDialog";

export default memo(({ reloadPlans, plans = [] }) => {
  const { t } = useTranslation();

  const actions = {
    plans: {},
    scores: {},
    notes: {},
    weights: {},
  };

  return (
    <Paper style={{ display: "flex", marginBottom: "30px", width: "100%" }}>
      <Box flex="1" display="flex" margin="10px">

        <Tooltip placement="bottom" title={t("plans")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.plans.open()}>
            <ViewModuleIcon />
          </IconButton>
        </Tooltip>
        <PlansVisibilityDialog actions={actions.plans} plans={plans} reloadPlans={reloadPlans} />

        <Tooltip placement="bottom" title={t("scores")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.scores.open()}>
            <EqualizerIcon />
          </IconButton>
        </Tooltip>
        <ScoresPreviewDialog actions={actions.scores} />

        <Tooltip placement="bottom" title={t("notes")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.notes.open()}>
            <NoteIcon />
          </IconButton>
        </Tooltip>
        <NotesPreviewDialog actions={actions.notes} />

        <Tooltip placement="bottom" title={t("weight_calculator")} disableTouchListener>
          <IconButton size="large" onClick={() => actions.weights.open()}>
            <PercentIcon />
          </IconButton>
        </Tooltip>
        <WeightCalculatorDialog actions={actions.weights} />

      </Box>
    </Paper>
  );
});
