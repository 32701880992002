export default async (source, options = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  rotation: 0,
  flipHorizontally: false,
  flipVertically: false,
  scaleWidth: 0,
  scaleHeight: 0,
  type: "rect",
}) => {
  const image = await (new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = e => reject(e);
    img.src = source;
  }));
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate((options.rotation * Math.PI) / 180);

  if (options.flipHorizontally) {
    ctx.scale(-1, 1);
  }

  if (options.flipVertically) {
    ctx.scale(1, -1);
  }
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    (safeArea / 2) - (image.width * 0.5),
    (safeArea / 2) - (image.height * 0.5),
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = options.width;
  canvas.height = options.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - (safeArea / 2) + (image.width * 0.5) - options.x,
    0 - (safeArea / 2) + (image.height * 0.5) - options.y,
  );

  // create second canvas and scale to requested size
  const canvas2 = document.createElement("canvas");
  const ctx2 = canvas2.getContext("2d");
  canvas2.width = options.scaleWidth > 0 ? options.scaleWidth : options.width;
  canvas2.height = options.scaleHeight > 0 ? options.scaleHeight : options.height;

  // cut a round shape from image if needed
  if (options.type === "round") {
    ctx2.beginPath();
    ctx2.arc(canvas2.width / 2, canvas2.height / 2, canvas2.width / 2, 0, 2 * Math.PI, false);
    ctx2.clip();
    ctx2.stroke();
    ctx2.closePath();
    ctx2.restore();
  }
  ctx2.drawImage(canvas, 0, 0, canvas2.width, canvas2.height);

  return canvas2.toDataURL("image/png");
};
