import ModeCommentIcon from "@mui/icons-material/ModeComment";
import { IconButton, Tooltip } from "@mui/material";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import MessageDialog from "../MessageDialog";

export default memo(({
  entryId, subordinateId, userName = "", tooltip = "messages", placement = "bottom", buttonSize = "small", fontSize = "small",
}) => {
  const { t } = useTranslation();
  const actions = {};

  return (
    <>
      <Tooltip title={t(tooltip)} placement={placement} disableTouchListener>
        <IconButton
          size={buttonSize}
          onClick={() => {
            actions.open(entryId, subordinateId, userName);
          }}
        >
          <ModeCommentIcon fontSize={fontSize} />
        </IconButton>
      </Tooltip>
      <MessageDialog actions={actions} />
    </>
  );
});
