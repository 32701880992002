import { Box, Container } from "@mui/material";
import React from "react";
import { Route, Switch } from "react-router-dom";

import { Footer, Header } from "../../components/shared/other";
import {
  customersUrl, entriesUrl, financeUrl, mediaUrl, plansUrl, profileUrl, recordingUrl, rootUrl, statisticsUrl, subscriptionsUrl,
} from "../../services/routes";
import {
  Customers, Entries, Error404, Finance, Media, Panel, Plans, Profile, Recording, Statistics, Subscriptions,
} from "..";

export default React.memo(() => (
  <Box display="flex" flexDirection="column" flexGrow={1}>
    <Header />
    <Box flex="1 0">
      <Container maxWidth="lg" style={{ paddingTop: 30 }}>
        <Switch>
          <Route path={rootUrl()} exact>
            <Panel />
          </Route>
          <Route path={plansUrl()} exact>
            <Plans />
          </Route>
          <Route path={profileUrl()} exact>
            <Profile />
          </Route>
          <Route path={plansUrl()} exact>
            <Plans />
          </Route>
          <Route path={entriesUrl()} exact>
            <Entries />
          </Route>
          <Route path={statisticsUrl()} exact>
            <Statistics />
          </Route>
          <Route path={customersUrl()} exact>
            <Customers />
          </Route>
          <Route path={subscriptionsUrl()} exact>
            <Subscriptions />
          </Route>
          <Route path={mediaUrl()} exact>
            <Media />
          </Route>
          <Route path={financeUrl()} exact>
            <Finance />
          </Route>
          <Route path={recordingUrl()} exact>
            <Recording />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      </Container>
    </Box>
    <Footer />
  </Box>
));
