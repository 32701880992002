import * as yup from "yup";

import { intervals } from "../../../services/enums";
import scoreUnitWhen from "./_score-unit-when";

export default t => yup.object().shape({
  order: yup.number().required(t("field_is_required")),
  planId: yup.string().trim()
    .required(t("field_is_required")),
  date: yup.date().typeError(t("invalid_date_format"))
    .required(t("field_is_required")),
  isDeadline: yup.boolean().required(t("field_is_required")),
  deadlineDate: yup.date().typeError(t("invalid_date_format"))
    .when("planInterval", (planInterval, schema) => (planInterval === intervals.COMPETITION ? schema.required(t("field_is_required")) : schema.nullable())),
  title: yup.string().trim()
    .max(1000, "text_too_long"),
  content: yup.string().trim()
    .max(10000, "text_too_long"),
  comment: yup.string().trim()
    .max(10000, "text_too_long"),
  isScore: yup.boolean().required(t("field_is_required")),
  isScoreHidden: yup.boolean().required(t("field_is_required")),
  isHidden: yup.boolean().required(t("field_is_required")),
  scoreUnit: yup.string().trim()
    .when("isScore", scoreUnitWhen(t)),
  statisticId: yup.string().trim(),
  media: yup.array().of(yup.object()),
  competitionCategory: yup.string().trim()
    .nullable()
    .max(1000, "text_too_long"),
  isTieBreak: yup.boolean().required(t("field_is_required")),
  tieBreakUnit: yup.string().trim()
    .when("isTieBreak", (isTieBreak, schema) => (isTieBreak ? schema.required(t("field_is_required")) : schema.nullable())),
  tieBreakDesc: yup.string().trim()
    .when("isTieBreak", (isTieBreak, schema) => (isTieBreak ? schema.required(t("field_is_required")) : schema.nullable())),
  isConfirmationRequired: yup.boolean().required(t("field_is_required")),
  isTimeCap: yup.boolean().required(t("field_is_required")),
  isInfo: yup.boolean().required(t("field_is_required")),
});
