import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

import { MediaPanel } from "../../media";
import { SelectDialogTemplate } from "../../shared/templates";
import useMedia from "./_use-media";

export default ({ form, setFieldValue }) => {
  const actions = {};
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <>
      <Tooltip title={t("add_media")} placement="bottom" disableTouchListener>
        <IconButton onClick={() => actions.open()}>
          <AddPhotoAlternateIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <SelectDialogTemplate
        title="select_media"
        use={useMedia(form, t, enqueueSnackbar, setFieldValue)}
        modelPanel={MediaPanel}
        actions={actions}
      />
    </>
  );
};
