import { FormControl } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { models } from "../../../../services/enums";
import { useGenericInputStyle } from "../../../../services/hooks";
import { SelectTextField } from "..";
import setStatisticSelectedData from "./_set-statistic-selected-data";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValues,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <SelectTextField
        model={models.STATISTIC}
        label={t("statistic")}
        name="statistic"
        value={form.statisticName}
        use={setStatisticSelectedData(setFieldValues)}
        error={isFieldError("statisticId")}
        helperText={fieldErrorMessage("statisticId")}
        clear={() => setFieldValues({ statisticId: "", statisticName: "" })}
      />
    </FormControl>
  );
};
