import { Paper } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default React.memo(() => {
  const { t } = useTranslation();

  return (
    <Paper style={{
      padding: 10,
      width: "calc(100% - 5px)",
      marginTop: 5,
      textAlign: "center",
    }}
    >
      {t("no_results_found")}
    </Paper>
  );
});
