import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box, Chip, Stack, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { stripePlanPaymentLink } from "../../../../services/api/plan";
import { localStorageDict } from "../../../../services/enums";
import { useAuthenticated, useLocalStorage } from "../../../../services/hooks";
import { formatPrice, getLocale, safeUpperCase } from "../../../../services/utils";

export default ({
  planId, currency, price, extend = false,
}) => {
  const { t } = useTranslation();
  const {
    loading, error, payload, mutate,
  } = useMutation(stripePlanPaymentLink);
  const { getUserData } = useAuthenticated();
  const [lang] = useLocalStorage(localStorageDict.LANGUAGE, getLocale().language);

  React.useLayoutEffect(() => {
    if (!loading && !error && !_.isNil(payload)) {
      const userData = getUserData();
      const prefilledEmail = _.isNil(userData) ? "" : userData.email;
      window.location.href = `${payload.paymentLink}?prefilled_email=${prefilledEmail}&locale=${lang}`;
    }
  }, [payload]);

  return (
    <Box display="flex" flexDirection="column">
      <Stack direction="row" spacing="5px" alignItems="center">
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={() => {
            const authUser = getUserData();
            mutate(planId, _.isNil(authUser) ? "new" : authUser._id, lang);
          }}
        >
          {t(extend ? "extend" : "buy")}
        </LoadingButton>
        <Chip variant="outlined" label={`${safeUpperCase(currency)} ${formatPrice(price)}`} />
      </Stack>
      <Typography fontSize="small">{`* ${t("you_are_buying_one_month_access")}`}</Typography>
    </Box>
  );
};
