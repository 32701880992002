import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { statisticDataPost, statisticDataPut, statisticOneGet } from "../../../services/api/statistic";
import { useFormEngine } from "../../../services/hooks";
import { onSuccessCallbacks } from "../../../services/utils";
import { TextField } from "../../shared/fields";
import UnitSelectField from "../../shared/fields/UnitSelectField";
import { FormDialogTemplate } from "../../shared/templates";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";

export default React.memo(({ actions, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, statisticDataPost, statisticDataPut, statisticOneGet, {
    _id: null,
    name: "",
    scoreUnit: "",
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="statistic"
      titleButtons=""
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            label="name"
            fieldName="name"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />

          <UnitSelectField
            disabled={isEditing && !_.isEmpty(form.scoreUnit)}
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
)}
      comment={(
        <Typography
          variant="body2"
          style={{
            textAlign: "justify",
            marginTop: 20,
            fontSize: "0.75rem",
          }}
        >
          {t("add_statistic_comment")}
        </Typography>
)}
    />
  );
});
