import { AccountCircle } from "@mui/icons-material";
import {
  Box, Button, InputAdornment, Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { TextField } from "../../components/shared/fields";
import { ErrorMessages, Loading } from "../../components/shared/other";
import { remindPasswordAction } from "../../services/api/other";
import { useFormEngine } from "../../services/hooks";
import { createFuncDef } from "../../services/utils";

export default React.memo(() => {
  const { t } = useTranslation();
  const [sent, setSent] = React.useState(false);

  const formSchema = yup.object().shape({
    email: yup.string().trim()
      .required(t("field_is_required"))
      .email(t("invalid_email_format"))
      .max(1000, "text_too_long"),
  });

  const onSuccessCallbacks = [
    createFuncDef(() => {
      setSent(true);
    }),
  ];

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, errors, isFieldError, fieldErrorMessage,
  } = useFormEngine(
    {}, remindPasswordAction, null, null, { email: "" }, formSchema, onSuccessCallbacks,
  );

  return (
    <Box width="100%">
      {isLoading() && <Loading />}
      {!sent && (
      <form onSubmit={e => e.preventDefault()} noValidate>
        <Box display="flex" flexWrap="wrap" flexDirection="column">
          <Box flex="1">
            <TextField
              variant="standard"
              label="email"
              fieldName="email"
              form={form}
              isFieldError={isFieldError}
              fieldErrorMessage={fieldErrorMessage}
              setFieldValue={setFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <ErrorMessages isError={isError()} errors={errors()} />

          <Typography
            variant="body2"
            style={{
              textAlign: "justify",
              marginTop: 15,
              marginBottom: 20,
              fontSize: "0.75rem",
            }}
          >
            {t("remind_password_desc")}
          </Typography>

          <Box flex="1">
            <Button type="submit" size="small" variant="contained" color="primary" style={{ width: "100%" }} onClick={handleSubmit}>
              {t("send")}
            </Button>
          </Box>
        </Box>
      </form>
      )}
    </Box>
  );
});
