import { Box, Link } from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { Loading } from "../../components/shared/other";
import { emailChangeConfirmationGet } from "../../services/api/other";

export default ({ match }) => {
  const { t } = useTranslation();
  const { loading, error } = useQuery(emailChangeConfirmationGet(match.params.hash));

  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      {loading && <Loading />}
      <Box position="absolute">
        {!loading && !error && (
          <Alert severity="success">
            {t("email_changed_successfully")}
            {" "}
            <Link href="/">{t("click_to_get_back_to_login_page")}</Link>
          </Alert>
        )}
        {!loading && error && (
          <Alert severity="error">
            {t("invalid_link")}
            {" "}
            <Link href="/">{t("click_to_get_back_to_login_page")}</Link>
          </Alert>
        )}
      </Box>
    </Box>
  );
};
