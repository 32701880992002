import { Alert } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { customerDataPost, customerDataPut, customerOneGet } from "../../../services/api/customer";
import { useFormEngine } from "../../../services/hooks";
import { onSuccessCallbacks } from "../../../services/utils";
import { MultilineTextField, TextField } from "../../shared/fields";
import { FormDialogTemplate } from "../../shared/templates";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";

export default React.memo(({ actions = {}, callback }) => {
  const { t } = useTranslation();

  const {
    form, setFieldValue, handleSubmit, isLoading, isError, isEditing, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, customerDataPost, customerDataPut, customerOneGet, {
    email: "",
    note: "",
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks);

  return (
    <FormDialogTemplate
      maxWidth="sm"
      isOpen={isOpen}
      onClose={handleClose}
      title="customer"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            disabled={isEditing}
            label="email"
            fieldName="email"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <MultilineTextField
            minRows={3}
            maxRows={5}
            label="note"
            fieldName="note"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
        </>
)}
      comment={(
        <Alert severity="info">
          {t("add_customer_comment")}
        </Alert>
)}
    />
  );
});
