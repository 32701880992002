import _ from "lodash";

import { createFuncDef } from "../../../../services/utils";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      uniqueSlug: _.isNil(data.uniqueSlug) ? "" : data.uniqueSlug,
    });
  }),
];
