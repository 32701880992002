import { capitalize } from "@mui/material";
import { endOfWeek, format, startOfWeek } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals } from "../enums";
import { useLoggedUserLocale } from ".";

/**
 * Common date-fns formatters
 * @returns {Object} { formatWholeDate, formatDate, formatByInterval }
 */
export default () => {
  const { t } = useTranslation();
  const { getLoggedUserLocale } = useLoggedUserLocale();

  const formatWholeDate = React.useCallback(date => {
    try {
      return format(typeof date === "string" ? new Date(date) : date, "dd.MM.yyyy HH:mm", { locale: getLoggedUserLocale() });
    } catch (e) {
      return "dd.MM.yyyy HH:mm";
    }
  }, [getLoggedUserLocale]);

  const formatDate = React.useCallback(date => {
    try {
      return format(typeof date === "string" ? new Date(date) : date, "dd.MM.yyyy", { locale: getLoggedUserLocale() });
    } catch (e) {
      return "dd.MM.yyyy";
    }
  }, [getLoggedUserLocale]);

  const formatDayAndMonth = React.useCallback(date => {
    try {
      return format(typeof date === "string" ? new Date(date) : date, "do MMMM", { locale: getLoggedUserLocale() });
    } catch (e) {
      return "do MMMM";
    }
  }, [getLoggedUserLocale]);

  const formatByInterval = React.useCallback((interval, date) => {
    let temp = date;

    if (typeof date === "string") {
      temp = new Date(date);
    }
    switch (interval) {
      case intervals.DAILY: {
        const dayNo = format(temp, "i", { locale: getLoggedUserLocale() });
        const day = t(`d_${dayNo}`);
        const date = format(temp, "dd.MM.yyyy", { locale: getLoggedUserLocale() });

        return capitalize(`${day}, ${date}`);
      }
      case intervals.WEEKLY: {
        const sow = format(startOfWeek(temp, { weekStartsOn: 1 }), "dd.MM.yyyy", { locale: getLoggedUserLocale() });
        const eow = format(endOfWeek(temp, { weekStartsOn: 1 }), "dd.MM.yyyy", { locale: getLoggedUserLocale() });

        return `${sow} - ${eow}`;
      }
      case intervals.MONTHLY: {
        const monthNo = format(temp, "M", { locale: getLoggedUserLocale() });
        const month = t(`m_${monthNo - 1}`);
        const year = format(temp, "yyyy", { locale: getLoggedUserLocale() });

        return `${capitalize(month)} ${year}`;
      }
      case intervals.COMPETITION:
      case intervals.STATIC:
      default:
        return "";
    }
  }, [t, getLoggedUserLocale]);

  return {
    formatWholeDate, formatDate, formatByInterval, formatDayAndMonth,
  };
};
