import {
  Box, Typography,
} from "@mui/material";
import _ from "lodash";
import React from "react";

import { useSizeStyle } from "../../../services/hooks";
import UserProfileAvatarBox from "../UserProfile/UserProfileAvatarBox";
import UserProfileBgBox from "../UserProfile/UserProfileBgBox";

export default React.memo(({ user, forcedWidth = null }) => {
  const isName = () => !_.isNil(user) && !_.isNil(user.name) && !_.isEmpty(user.name);
  const AvatarPos = useSizeStyle({
    xs: { position: "absolute", top: 180, left: "calc(50% - 110px)" },
    sm: { position: "absolute", top: 180, left: "calc(50% - 110px)" },
    md: { position: "absolute", top: 180, left: "calc(50% - 110px)" },
    lg: { position: "absolute", top: 180, left: 35 },
    xl: { position: "absolute", top: 180, left: 35 },
  });
  const NamePos = useSizeStyle({
    xs: { width: "100%", textAlign: "center", marginTop: 90 },
    sm: { width: "100%", textAlign: "center", marginTop: 90 },
    md: { width: "100%", textAlign: "center", marginTop: 90 },
    lg: { position: "absolute", bottom: -70, left: 255 },
    xl: { position: "absolute", bottom: -70, left: 255 },
  });

  return (
    <Box style={{ position: "relative" }} width="100%">

      <UserProfileBgBox user={user} edit={false} />
      <Box style={AvatarPos.select(forcedWidth)}>
        <UserProfileAvatarBox user={user} edit={false} />
      </Box>
      <Box style={NamePos.select(forcedWidth)}>
        <Box position="relative">
          {isName() && <Typography variant="h2">{user.name}</Typography>}
        </Box>
      </Box>
    </Box>
  );
});
