import {
  Box, ListItem, ListItemAvatar, ListItemText, Typography,
} from "@mui/material";
import React from "react";

import { createUserDisplayName } from "../../../services/utils";
import { AvatarCircle } from "../../shared/other";

export default ({ competitor }) => (
  <ListItem alignItems="flex-start" disabled>
    <ListItemAvatar style={{ marginTop: 4 }}>
      <AvatarCircle
        user={competitor}
        style={{
          height: 30, width: 30, fontSize: "0.75rem",
        }}
      />
    </ListItemAvatar>
    <ListItemText
      style={{ alignSelf: "center" }}
      disableTypography
      primary={(
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="body2">
              {createUserDisplayName(competitor)}
            </Typography>
            {competitor?.card?.club && (
              <Typography variant="caption" style={{ fontWeight: 600 }} color="inherit">
                {competitor?.card?.club}
              </Typography>
            )}
          </Box>
        </Box>
          )}
    />
  </ListItem>
);
