export default {
  NEW_SUBORDINATE_SCORE_FOR_COACH: "new_subordinate_score_for_coach",
  EDIT_SUBORDINATE_SCORE_FOR_COACH: "edit_subordinate_score_for_coach",
  NEW_SUBORDINATE_NOTE_FOR_COACH: "new_subordinate_note_for_coach",
  EDIT_SUBORDINATE_NOTE_FOR_COACH: "edit_subordinate_note_for_coach",
  NEW_PLAN_PURCHASE_OWNER: "new_plan_purchase_owner",
  NEW_PLAN_PURCHASE_CUSTOMER: "new_plan_purchase_customer",
  NEW_COMPETITOR_PURCHASE_OWNER: "new_competitor_purchase_owner",
  NEW_COMPETITOR_PURCHASE_CUSTOMER: "new_competitor_purchase_customer",
  NEW_ENTRY_MESSAGE: "new_entry_message",
};
