import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import {
  Box, Divider, IconButton, Paper, Tooltip, Typography, useTheme,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useQuery } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { noteDelete, noteOneByEntryGet } from "../../../../../services/api/note";
import { models } from "../../../../../services/enums";
import { ModelActionButton } from "../../../../shared/buttons";
import { GenericError, Loading } from "../../../../shared/other";

export default React.memo(({ actions, entryId, isCompetition = false }) => {
  const { t } = useTranslation();
  const [data, setData] = React.useState(null);
  const {
    loading, payload, query, error, status,
  } = useQuery(noteOneByEntryGet(entryId));
  const theme = useTheme();

  React.useEffect(() => {
    if (!loading && !_.isNil(payload)) {
      setData(payload.note);
    }
  }, [payload, loading]);

  if (error && status !== 404) {
    return <GenericError status={status} />;
  }

  const reload = () => {
    query();
    actions.reloadNotes();
  };

  return (
    <Paper style={{
      marginBottom: "20px", paddingTop: 8, position: "relative", flexGrow: 1,
    }}
    >
      {loading && <Loading />}
      <Box display="flex" justifyContent="center" flexWrap="wrap">
        <Typography variant="h3" style={{ fontWeight: 900, paddingTop: 8 }}>{t("note")}</Typography>
        <Divider variant="middle" style={{ width: "100%", marginTop: 10, marginBottom: 10 }} />
        {!_.isNil(data) && (
        <Typography style={{
          whiteSpace: "pre-line", margin: 10, width: "calc(100% - 20px)", textAlign: "justify", wordBreak: "break-word",
        }}
        >
          {data.content}
        </Typography>
        )}
        {_.isNil(data) && (
        <Box style={{ marginBottom: 10 }}>
          <ModelActionButton
            type="add"
            model={models.NOTE}
            variant="fabh1"
            tooltip="add_note"
            callback={reload}
            initData={{ entryId }}
            options={{ isCompetition }}
          />
        </Box>
        )}
        {!_.isNil(data) && (
        <Box width="100%" display="flex" flexWrap="wrap" justifyContent="flex-end">
          <Box display="flex" flexWrap="wrap">
            <ModelActionButton
              type="edit"
              modelId={data._id}
              fontSize="inherit"
              variant="iconbutton"
              model={models.NOTE}
              tooltip="edit"
              callback={reload}
              options={{ isCompetition }}
            />
            <ModelActionButton
              remove={noteDelete}
              modelId={data._id}
              fontSize="inherit"
              callback={reload}
            />
            {!data.coachCanSee && (
            <Tooltip title={t("note_is_private")} placement="bottom" disableTouchListener>
              <IconButton style={{ cursor: "auto" }}>
                <LockIcon style={{ color: theme.palette.error.light }} />
              </IconButton>
            </Tooltip>
            )}
            {(data.coachCanSee || data.isPublic) && (
            // eslint-disable-next-line no-nested-ternary
            <Tooltip title={t(isCompetition ? "coach_can_see_this_note_competition" : (data.isPublic ? "note_public" : "coach_can_see_this_note"))} placement="bottom" disableTouchListener>
              <IconButton style={{ cursor: "auto" }}>
                <LockOpenIcon style={{ color: theme.palette.success.light }} />
              </IconButton>
            </Tooltip>
            )}
          </Box>
        </Box>
        )}
      </Box>
    </Paper>
  );
});
