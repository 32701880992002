import "./index.css";

import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ClientContextProvider } from "react-fetching-library";
import { I18nextProvider } from "react-i18next";

import { Error500, Main } from "./routes";
import { client } from "./services/api";
import { getLocale, logError } from "./services/utils";
import translationsEN from "./translations/en.json";
import translationsPL from "./translations/pl.json";

i18next.init({
  interpolation: { escapeValue: false },
  lng: window.localStorage.language || getLocale().language,
  fallbackLng: "en",
  resources: {
    pl: { translation: translationsPL },
    en: { translation: translationsEN },
  },
  react: {
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p"],
  },
});

// eslint-disable-next-line react/no-deprecated
ReactDOM.render((
  <ClientContextProvider client={client}>
    <I18nextProvider i18n={i18next}>
      <ErrorBoundary fallback={<Error500 />} onError={logError}>
        <Main />
      </ErrorBoundary>
    </I18nextProvider>
  </ClientContextProvider>
), document.getElementById("root"));
