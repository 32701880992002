import { Box } from "@mui/material";
import { blue } from "@mui/material/colors";
import { format } from "date-fns";
import React from "react";

import { localStorageDict } from "../../../../services/enums";
import { useLocalStorage } from "../../../../services/hooks";

export default React.memo(() => {
  const [hostConfig] = useLocalStorage(localStorageDict.HOST_CONFIG, { name: "", website: "", features: [] });

  return (
    <Box
      flex="0 1"
      style={{
        marginTop: 50,
        backgroundColor: blue[800],
        color: "white",
        textAlign: "center",
        padding: 20,
      }}
    >
      &copy;
      {" "}
      {hostConfig.name}
      {" "}
      {format(new Date(), "yyyy")}
    </Box>
  );
});
