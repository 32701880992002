import AccountCircle from "@mui/icons-material/AccountCircle";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ScoreIcon from "@mui/icons-material/Score";
import VideocamIcon from "@mui/icons-material/Videocam";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import {
  Box, Divider, IconButton, ListItemIcon, Menu, MenuItem,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { featureFlags } from "../../../../services/enums";
import { useAnchorControl, useFeatureFlag, useSizeStyle } from "../../../../services/hooks";
import {
  customersUrl, entriesUrl, financeUrl, mediaUrl, plansUrl, profileUrl, recordingUrl, rootUrl, statisticsUrl, subscriptionsUrl,
} from "../../../../services/routes";
// import { MessageButton } from "../../../message";
import { CompetitionSignUpButton } from "../../../competition";
import { CustomerViewMenuItem } from "../../../customer";
import {
  LanguageSwitchButton, LoginButton, PlanTermsButton, ThemeSwitchButton,
} from "../../buttons";
import { Logout } from "..";

export default React.memo(({
  competition = false, plan, callback, isCoach = false, isCustomer = false,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { select } = useSizeStyle({
    xs: true, sm: true, md: false, lg: false, xl: false, default: false,
  });
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();
  const { isEnabled } = useFeatureFlag(featureFlags.PAYMENTS);
  const RecordingFlag = useFeatureFlag(featureFlags.RECORDING);

  const navigate = url => {
    removeAnchor();
    history.push(url);
  };

  return (
    <>
      <IconButton size="large" aria-controls="header-menu-coach" aria-haspopup="true" onClick={setAnchor}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="header-menu-coach"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={removeAnchor}
      >
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !_.isNil(plan) && plan.terms && <PlanTermsButton plan={plan} />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !_.isNil(plan) && <CompetitionSignUpButton plan={plan} callback={callback} />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          {competition && !isCoach && !isCustomer && !_.isNil(plan) && <LoginButton />}
        </Box>
        )}
        {select() && (
        <Box width="100%" display="flex" justifyContent="center">
          <LanguageSwitchButton />
          <ThemeSwitchButton />
          {/* {(isCoach || isCustomer) && <MessageButton />} */}
        </Box>
        )}
        {select() && (isCoach || isCustomer) && <Divider />}

        {isCoach && (
        <MenuItem onClick={() => navigate(rootUrl())}>
          <ListItemIcon>
            <DashboardIcon fontSize="small" />
          </ListItemIcon>
          {t("main_panel")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(plansUrl())}>
          <ListItemIcon>
            <ViewModuleIcon fontSize="small" />
          </ListItemIcon>
          {t("plans")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(entriesUrl())}>
          <ListItemIcon>
            <LibraryBooksIcon fontSize="small" />
          </ListItemIcon>
          {t("entries")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(statisticsUrl())}>
          <ListItemIcon>
            <ScoreIcon fontSize="small" />
          </ListItemIcon>
          {t("statistics")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(customersUrl())}>
          <ListItemIcon>
            <GroupIcon fontSize="small" />
          </ListItemIcon>
          {t("customers")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(subscriptionsUrl())}>
          <ListItemIcon>
            <CreditCardIcon fontSize="small" />
          </ListItemIcon>
          {t("subscriptions")}
        </MenuItem>
        )}
        {isCoach && (
        <MenuItem onClick={() => navigate(mediaUrl())}>
          <ListItemIcon>
            <CameraAltIcon fontSize="small" />
          </ListItemIcon>
          {t("media")}
        </MenuItem>
        )}

        {(isCoach || isCustomer) && RecordingFlag.isEnabled() && (
        <MenuItem onClick={() => navigate(recordingUrl())}>
          <ListItemIcon>
            <VideocamIcon fontSize="small" />
          </ListItemIcon>
          {t("recording")}
        </MenuItem>
        )}

        {isCustomer && (
        <MenuItem onClick={() => navigate(rootUrl())}>
          <ListItemIcon>
            <DashboardIcon fontSize="small" />
          </ListItemIcon>
          {t("main_panel")}
        </MenuItem>
        )}

        {(isCoach || isCustomer) && <Divider />}
        {isCoach && isEnabled() && (
        <MenuItem onClick={() => navigate(financeUrl())}>
          <ListItemIcon>
            <AttachMoneyIcon fontSize="small" />
          </ListItemIcon>
          {t("finance")}
        </MenuItem>
        )}
        {(isCoach || isCustomer) && (
        <MenuItem onClick={() => navigate(profileUrl())}>
          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>
          {t("my_profile")}
        </MenuItem>
        )}
        {(isCoach || isCustomer) && <CustomerViewMenuItem callback={removeAnchor} />}
        {(isCoach || isCustomer) && <Divider />}
        {(isCoach || isCustomer) && <Logout />}
      </Menu>
    </>
  );
});
