import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box, Button, IconButton, Link, ListItemIcon, Menu, MenuItem,
} from "@mui/material";
import { differenceInCalendarDays } from "date-fns";
import _ from "lodash";
import React from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../../../../services/api";
import { planCustomerHidePut } from "../../../../../../services/api/plan";
import { intervals } from "../../../../../../services/enums";
import { useAnchorControl } from "../../../../../../services/hooks";
import { competitionAbsoluteUrl } from "../../../../../../services/routes";
import { DateSelectButton } from "../../../../../shared/buttons";

export default React.memo(({
  plan, shift, setShift, setPrevShift, reloadPlans,
}) => {
  const { t } = useTranslation();
  const { anchorEl, setAnchor, removeAnchor } = useAnchorControl();
  const { mutate, loading, status } = useMutation(apiAction);

  React.useEffect(() => {
    if (status === 200) {
      reloadPlans();
    }
  }, [status]);

  return (
    <>
      {_.isEqual(plan.interval, intervals.COMPETITION) && (
      <Box flex="0 0" display="flex" flexDirection="column" justifyContent="center">
        <Link href={competitionAbsoluteUrl(plan)} rel="noopener noreferrer">
          <Button variant="contained" style={{ whiteSpace: "nowrap" }}>{t("competition")}</Button>
        </Link>
      </Box>
      )}
      {_.isEqual(plan.interval, intervals.DAILY) && (
      <Box flex="0 0" display="flex" flexDirection="column" justifyContent="center">
        <DateSelectButton
          color="secondary"
          right={-36}
          top={-1}
          disableFuture
          onChange={val => {
            setPrevShift(shift);
            setShift(differenceInCalendarDays(val, new Date()));
          }}
        />
      </Box>
      )}
      <Box flex="0 0" display="flex" flexDirection="column" justifyContent="center">
        <IconButton aria-controls={`menu-${plan._id}`} aria-haspopup="true" onClick={setAnchor} style={{ marginLeft: "5px" }}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Menu
          id={`menu-${plan._id}`}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={removeAnchor}
        >
          <MenuItem
            data-testid="plan-shares"
            disabled={loading}
            onClick={() => {
              mutate(...planCustomerHidePut(plan._id));
              removeAnchor();
            }}
          >
            <ListItemIcon>
              <VisibilityOffIcon fontSize="small" />
            </ListItemIcon>
            {t("hide")}
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
});
