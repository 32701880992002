import { Typography } from "@mui/material";
import _ from "lodash";
import React from "react";

import { units } from "../../../../services/enums";
import { selectUnit, timeStringFormat } from "../../../../services/utils";

export default ({ unit = units.DESCRIPTION, data = {} }) => {
  switch (unit) {
    case units.WEIGHT:
    case units.DISTANCE:
    case units.REPS:
    case units.ROUNDS:
      return (
        <Typography color="primary" variant="h3" style={{ whiteSpace: "nowrap", margin: 10 }}>
          {data.valueA}
          {" "}
          <span style={{ fontSize: "1rem" }}>{selectUnit(unit)}</span>
        </Typography>
      );
    case units.TIME:
      return (
        <Typography color="primary" variant="h3" style={{ whiteSpace: "nowrap", margin: 10 }}>
          {!_.isNil(data.isTimeCap) && data.isTimeCap ? `CAP+${data.timeCapReps}` : timeStringFormat(data.valueA, data.valueB, data.valueC)}
        </Typography>
      );
    default:
      return (
        <Typography color="primary" variant="h6" style={{ margin: 10 }}>
          {data.valueA}
        </Typography>
      );
  }
};
