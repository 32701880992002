import { FormControl, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, fieldName, label,
  isOptional = false, type = "text", disabled = false, InputProps = {}, variant = "outlined", inputProps = {},
  helperText = "",
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <TextField
        inputProps={inputProps}
        variant={variant}
        disabled={disabled}
        type={type}
        error={isFieldError(fieldName)}
        helperText={fieldErrorMessage(fieldName) || (isOptional ? t("optional") : helperText)}
        label={t(label)}
        name={fieldName}
        value={form[fieldName]}
        onChange={e => setFieldValue(fieldName, e.target.value)}
        InputProps={InputProps}
      />
    </FormControl>
  );
};
