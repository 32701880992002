import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert, Box, InputAdornment, TextField,
} from "@mui/material";
import { addHours } from "date-fns";
import _ from "lodash";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { planTvPost } from "../../../services/api/plan";
import { useFormEngine } from "../../../services/hooks";
import { planTvAbsoluteUrl } from "../../../services/routes";
import { createFuncDef } from "../../../services/utils";
import { OpenTabButton, TextCopyButton } from "../../shared/buttons";
import { DateField } from "../../shared/fields";
import formSchema from "./_form-schema";

export default memo(({ plan }) => {
  const utcHours = new Date().getUTCHours();
  const { t } = useTranslation();
  const [link, setLink] = useState("");
  const callback = data => setLink(planTvAbsoluteUrl(data.tv));
  const {
    form, setFieldValues, handleSubmit, isLoading, isFieldError, fieldErrorMessage,
  } = useFormEngine(
    {}, planTvPost, null, null, {
      planId: plan._id, date: new Date(), start: addHours(new Date(), utcHours * -1), end: addHours(new Date(), 24 - utcHours),
    }, formSchema(t), [createFuncDef(callback)],
  );
  const setDateValues = (_, val) => {
    setFieldValues({
      date: val,
      start: addHours(val, utcHours * -1),
      end: addHours(val, 24 - utcHours),
    });
  };

  return (
    <Box display="flex" flexWrap="wrap">
      <Box flex="1 0 100%">
        <DateField
          fieldName="date"
          label="date"
          form={form}
          setFieldValue={setDateValues}
          isFieldError={isFieldError}
          fieldErrorMessage={fieldErrorMessage}
          disabled={isLoading()}
        />
      </Box>
      {!_.isEmpty(link) && (
        <Box flex="1 0 100%" marginBottom="17px" textAlign="center">
          <TextField
            style={{ width: "100%" }}
            variant="outlined"
            value={link}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <TextCopyButton textToCopy={link} />
                <OpenTabButton link={link} />
              </InputAdornment>,
            }}
            disabled={isLoading()}
          />
        </Box>
      )}
      <Box flex="1 0 100%" marginBottom="17px" textAlign="center">
        <LoadingButton
          variant="contained"
          loading={isLoading()}
          onClick={handleSubmit}
        >
          {t("generate_link")}
        </LoadingButton>
      </Box>
      <Box flex="1 0 100%">
        <Alert severity="info">
          {t("display_on_tv_desc")}
        </Alert>
      </Box>
    </Box>
  );
});
