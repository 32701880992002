import { differenceInHours } from "date-fns";

export default (createdAt, t) => {
  const diff = differenceInHours(new Date(), new Date(createdAt));

  if (diff <= 24) {
    return t("today");
  } else if (diff > 24 && diff <= 48) {
    return t("yesterday");
  } else if (diff > 48 && diff <= 168) {
    return t("x_days_ago", { x: Math.floor(diff / 24) });
  } else if (diff > 168 && diff <= 336) {
    return t("week_ago");
  } else if (diff > 336 && diff <= 720) {
    return t("x_weeks_ago", { x: Math.floor(diff / 168) });
  } else if (diff > 720 && diff <= 1440) {
    return t("month_ago");
  } else if (diff > 1440 && diff <= 8640) {
    return t("x_months_ago", { x: Math.floor(diff / 720) });
  }

  return t("x_years_ago", { x: Math.floor(diff / 8640) });
};
