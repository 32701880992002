import { Alert, useMediaQuery, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";

import { useOpenCloseControl } from "../../../../services/hooks";
import { FormDialogActions, GenericError, Loading } from "../../other";
import open from "./_open";
import useDuplicate from "./_use-duplicate";

export default React.memo(({ actions, callback }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { isOpen, handleOpen, handleClose } = useOpenCloseControl();
  const [duplicateData, setDuplicateData] = React.useState(null);
  const {
    loading, error, status, duplicate, errors,
  } = useDuplicate();

  actions.open = open(setDuplicateData, handleOpen);

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen()}
      onClose={handleClose}
    >
      <DialogTitle>{t("confirm_create_duplicate")}</DialogTitle>
      <DialogContent>
        {loading && <Loading />}
        {errors.length === 0 && error && <GenericError status={status} />}
        {errors.length > 0 && error && errors.map(err => <Alert key={err} severity="error">{t(err)}</Alert>)}
      </DialogContent>
      <FormDialogActions cancel={handleClose} confirm={() => duplicate(handleClose, callback, duplicateData)} />
    </Dialog>
  );
});
