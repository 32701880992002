export { default as base64MimeType } from "./base-64-mime-type";
export { default as confirm } from "./confirm";
export { default as confirmOption } from "./confirm-option";
export { default as createFuncDef } from "./create-func-def";
export { default as createKVObj } from "./create-kv-obj";
export { default as createUserDisplayName } from "./create-user-display-name";
export { default as dateToShortText } from "./date-to-short-text";
export { default as deepen } from "./deepen";
export { default as formatPrice } from "./format-price";
export { default as funcCaller } from "./func-caller";
export { default as getCroppedImg } from "./get-cropped-img";
export { default as getDefault } from "./get-default";
export { default as getLocale } from "./get-locale";
export { default as imageOrVideo } from "./image-or-video";
export { default as isInt } from "./is-int";
export { default as logError } from "./log-error";
export { default as normalizeHost } from "./normalize-host";
export { default as numberFormatWithMaxDigits } from "./number-format-with-max-digits";
export { default as onSuccessCallbacks } from "./on-success-callbacks";
export { default as safeUpperCase } from "./safe-uppercase";
export { default as selectUnit } from "./select-unit";
export { default as setDateByInterval } from "./set-date-by-interval";
export { default as strPadLeft } from "./str-pad-left";
export { default as stringFormatWithSpaces } from "./string-format-with-spaces";
export { default as timeStringFormat } from "./time-string-format";
export { default as wordCut } from "./word-cut";
export { default as writeStorage } from "./write-storage";

export const marginBottomForFields = () => 15;
