import { Box, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { intervals, models, roles } from "../../../services/enums";
import { createUserDisplayName } from "../../../services/utils";
import { MessageButton } from "../../message";
import {
  ConfirmedButton, LockButton, ModelActionButton, OpenTabButton,
} from "../../shared/buttons";
import getValue from "./_get-value";

export default ({
  item, entry, isRole, plan, reload, queryArgs, canEdit = true,
}) => {
  const { t } = useTranslation();

  return (
    <Box display="flex">
      <Box flexGrow={1}>
        <Typography variant="caption" color={item.user.itsMe ? "primary" : "inherit"}>
          {t("tie_break")}
          :
          {getValue(item, entry.tieBreakUnit)}
        </Typography>
      </Box>
      {isRole(roles.COACH) && (
        <Box justifyContent="flex-end" display="flex">
          {plan.interval !== intervals.COMPETITION && (
          <LockButton
            enabled={item.public}
            titleEnabled="score_is_public"
            titleDisabled="score_is_private"
            disabled
            buttonSize="small"
            fontSize="small"
          />
          )}
          {plan.interval === intervals.COMPETITION && entry.isDeadline && entry.isConfirmationRequired && (
          <ConfirmedButton
            enabled={item.isConfirmed}
            titleEnabled="score_confirmed"
            titleDisabled="score_not_confirmed"
            disabled
            buttonSize="small"
            fontSize="small"
          />
          )}
          {entry.isDeadline && !_.isNil(item.videoLink) && !_.isEmpty(item.videoLink) && (
            <OpenTabButton
              link={item.videoLink}
              tooltipTitle="video_link"
              placement="bottom"
            />
          )}
          {canEdit && (
            <ModelActionButton
              type="edit"
              modelId={item._id}
              buttonSize="small"
              fontSize="small"
              variant="iconbutton"
              model={models.SCORE}
              tooltip="edit"
              callback={() => reload(queryArgs)}
              options={{
                planInterval: plan?.interval, isDeadline: entry.isDeadline, isConfirmationRequired: entry.isConfirmationRequired, isTieBreak: true, isTimeCap: entry.isTimeCap,
              }}
            />
          )}
          <MessageButton entryId={entry._id} userName={createUserDisplayName(item.user)} subordinateId={item.user._id} />
        </Box>
      )}
    </Box>
  );
};
