import { Box, Button, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import React from "react";
import { useTranslation } from "react-i18next";

import { mediaDataPost, mediaDataPut, mediaOneGet } from "../../../services/api/media";
import { useFormEngine } from "../../../services/hooks";
import { createFuncDef, onSuccessCallbacks } from "../../../services/utils";
import { FileSelectButton } from "../../shared/buttons";
import { MultilineTextField, TextField } from "../../shared/fields";
import { FormDialogTemplate } from "../../shared/templates";
import formSchema from "./_form-schema";
import onLoadCallbacks from "./_on-load-callbacks";
import Preview from "./_preview";

export default React.memo(({ actions, callback }) => {
  const { t } = useTranslation();
  const [file, setFile] = React.useState({});
  const [type, setTyp] = React.useState(null);

  const {
    form, setFieldValue, setFieldValues, handleSubmit, isLoading, isError, errors, isOpen, handleClose, isFieldError, fieldErrorMessage,
  } = useFormEngine(actions, mediaDataPost, mediaDataPut, mediaOneGet, {
    _id: null,
    name: "",
    description: "",
    data: "",
    mime: "",
    fileId: "",
  }, formSchema(t), onSuccessCallbacks(callback), onLoadCallbacks(setFile), [], null, [createFuncDef(() => setTyp(null))]);

  return (
    <FormDialogTemplate
      maxWidth="md"
      isOpen={isOpen}
      onClose={handleClose}
      title="media"
      isPaper
      isError={isError}
      errors={errors}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      body={(
        <>
          <TextField
            label="name"
            fieldName="name"
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />
          <MultilineTextField
            fieldName="description"
            label="description"
            minRows={3}
            maxRows={15}
            form={form}
            isFieldError={isFieldError}
            fieldErrorMessage={fieldErrorMessage}
            setFieldValue={setFieldValue}
          />

          {type === null && (
          <Box display="flex" flexWrap="wrap">
            <Box flex="1 0">
              <Button variant="contained" fullWidth onClick={() => setTyp("image")}>{t("image_or_video")}</Button>
            </Box>
            <Box flex="1 0" display="flex" flexDirection="column" justifyContent="center">
              <Typography textAlign="center">{t("__or")}</Typography>
            </Box>
            <Box flex="1 0">
              <Button variant="contained" fullWidth onClick={() => setTyp("pdf")}>{t("PDF")}</Button>
            </Box>
          </Box>
          )}

          {type === "image" && (
          <FileSelectButton
            label="select_video_or_picture"
            accept="image/*,video/mp4"
            setFieldValue={setFieldValue}
            setFieldValues={setFieldValues}
          />
          )}

          {type === "pdf" && (
          <FileSelectButton
            label="select_pdf"
            accept="application/pdf"
            setFieldValue={setFieldValue}
            setFieldValues={setFieldValues}
            iconPdf
            iconCamera={false}
            type="pdf"
          />
          )}
          {isFieldError("data") && <Alert severity="error" style={{ marginTop: 20 }}>{fieldErrorMessage("data")}</Alert>}
        </>
)}
      comment={type === "image" && <Preview form={form} file={file} />}
    />
  );
});
