import { differenceInCalendarDays } from "date-fns";
import _ from "lodash";

import { fixed, intervals } from "../../../services/enums";

export default setFieldValues => item => {
  setFieldValues({
    order: item.order,
    // planId: item.planId,
    // planName: item.plan.name,
    planInterval: item.plan.interval,
    // date: new Date(item.date),
    isDeadline: _.isNil(item.isDeadline) ? false : item.isDeadline,
    deadlineDate: new Date(item.deadlineDate),
    title: item.title,
    content: item.content,
    comment: item.comment,
    isScore: _.isEqual(item.plan.interval, intervals.COMPETITION) ? true : item.isScore,
    isScoreHidden: _.isNil(item.isScoreHidden) ? false : item.isScoreHidden,
    isHidden: _.isNil(item.isHidden) ? false : item.isHidden,
    scoreUnit: _.isNil(item.scoreUnit) ? "" : item.scoreUnit,
    statisticId: item.statistic ? item.statistic._id : "",
    statisticName: item.statistic ? item.statistic.name : "",
    media: !_.isNil(item.media) && !_.isEmpty(item.media[0]) ? item.media : [],
    day: differenceInCalendarDays(new Date(item.date), new Date(fixed.STATIC_BLOCK_START_TIME)) + 2,
    competitionCategory: _.isNil(item.competitionCategory) ? null : item.competitionCategory,
    isTieBreak: _.isNil(item.isTieBreak) ? false : item.isTieBreak,
    tieBreakUnit: _.isNil(item.tieBreakUnit) ? "" : item.tieBreakUnit,
    tieBreakDesc: _.isNil(item.tieBreakDesc) ? "" : item.tieBreakDesc,
    isConfirmationRequired: _.isNil(item.isConfirmationRequired) ? false : item.isConfirmationRequired,
    isTimeCap: _.isNil(item.isTimeCap) ? false : item.isTimeCap,
    isInfo: _.isNil(item.isInfo) ? false : item.isInfo,
  });
};
