import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar, Box, Divider, IconButton, ListItem, ListItemText, Tooltip, Typography,
} from "@mui/material";
import _ from "lodash";
import React, { memo } from "react";
import { useMutation } from "react-fetching-library";
import { useTranslation } from "react-i18next";

import { apiAction } from "../../../services/api";
import { fileBlobGet } from "../../../services/api/file";
import { messageDelete } from "../../../services/api/message";
import { confirm, createUserDisplayName, dateToShortText } from "../../../services/utils";
import { Loading } from "../../shared/other";

export default memo(({ message, callback }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { mutate, loading } = useMutation(apiAction);

  return (
    <>
      <ListItem alignItems="flex-start" sx={{ paddingBottom: 0, position: "relative" }}>
        {loading && <Loading bgFrom="MuiDialog" />}
        <ListItemText
          sx={{
            marginBottom: 0, whiteSpace: "pre-line", width: "100%", wordBreak: "break-word",
          }}
          disableTypography
          primary={(
            <Box display="flex" alignItems="center" marginBottom="10px">
              <Avatar
                sx={{
                  width: "24px", height: "24px", fontSize: "0.75rem", marginRight: "10px",
                }}
                alt={createUserDisplayName(message.user)}
                src={_.isNil(message.user.avatar) ? "" : fileBlobGet(message.user.avatar)}
                title={createUserDisplayName(message.user)}
              >
                {createUserDisplayName(message.user).substr(0, 1)}
              </Avatar>
              <Typography
                variant="caption"
                fontSize="small"
                color="grey"
                style={{
                  whiteSpace: "pre-line", width: "100%", wordBreak: "break-word", paddingTop: "3px",
                }}
              >
                {createUserDisplayName(message.user)}
                {" "}
                —
                {" "}
                {dateToShortText(message.createdAt, t)}
              </Typography>
              {message.isMe && (
                <Tooltip title={t("delete")} placement="left" disableTouchListener>
                  <IconButton
                    color="error"
                    size="small"
                    style={{ left: "-15px", marginRight: "-15px" }}
                    onClick={async () => {
                      const c = await confirm("confirm_removal", { t, theme });

                      if (c === true) {
                        await mutate(...messageDelete(message._id));
                        callback();
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )}
          secondary={(
            <Box display="flex" flexDirection="column" sx={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
              { message.content }
            </Box>
          )}
        />
      </ListItem>
      <Divider variant="fullWidth" />
    </>
  );
});
