import { createFuncDef, createUserDisplayName } from "../../../services/utils";

export default setFieldValues => [
  createFuncDef((id, data) => {
    setFieldValues({
      _id: id,
      planId: data.plan._id,
      planName: data.plan.name,
      customerId: data.customer._id,
      customerName: createUserDisplayName(data.customer.user),
      start: data.start,
      end: data.end,
    });
  }),
];
