import { marginBottomForFields } from "../utils";
import { useSizeStyle } from ".";

export default () => {
  const mbff = marginBottomForFields();
  const inputBig = { width: "calc(100% - 5px)", marginRight: 5, marginBottom: mbff + 2 };
  const inputSmall = { width: "100%", marginBottom: mbff };
  const inputStyle = useSizeStyle({
    xs: inputSmall, sm: inputSmall, md: inputBig, lg: inputBig, xl: inputBig, default: inputSmall,
  });

  return { inputStyle };
};
