import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";

import { usePagination } from "../../../../services/hooks";
import { ModelActionButton } from "../../buttons";
import {
  GenericError, Loading, MyPagination, NoResultsFound,
} from "../../other";

export default React.memo(({
  use, filterGet, dataPrefix, sortDescs, filterDescs, model, panelItem, addTooltip, setTotal,
  bgFrom, disableAdding = false, disableSearch = false, disableFilter = false, actions = {},
  initData = {}, limit = 10, overrideSm = false,
}) => {
  const [data, setData] = React.useState([]);
  const {
    loading, payload, error, status, metadata, setMetadata, update, reload, searchWords,
  } = usePagination(filterGet, limit);

  actions.reload = React.useCallback(() => reload(), [reload]);

  React.useLayoutEffect(() => {
    if (!loading && !_.isNil(payload) && !_.isNil(payload[dataPrefix])) {
      setData(payload[dataPrefix].data);

      if (!_.isNil(payload[dataPrefix].metadata.total)) {
        if (_.isFunction(setTotal)) {
          setTotal(payload[dataPrefix].metadata.total);
        }
        setMetadata(payload[dataPrefix].metadata);
      }
    }
  }, [loading, payload, setData, setMetadata, dataPrefix, setTotal]);

  if (error) {
    return <GenericError status={status} />;
  }

  const PanelItem = panelItem;

  return (
    <Box position="relative" width="100%">
      {loading && <Loading bgFrom={bgFrom} />}
      <Box display="flex" width="100%" style={{ marginBottom: 20 }}>
        <MyPagination
          callback={update}
          metadata={metadata}
          limits={[10, 20, 50]}
          initLimit={limit}
          defaultSort={0}
          sorts={sortDescs}
          filtersInit={filterDescs}
          disableSearch={disableSearch}
          disableFilter={disableFilter}
          overrideSm={overrideSm}
        />
      </Box>
      <Box display="flex" width="100%" flexWrap="wrap" style={{ marginBottom: 20 }}>
        {!_.isNil(payload) && data.length === 0 && <NoResultsFound />}
        {_.map(data, item => (
          <PanelItem
            key={item._id}
            item={item}
            highlight={searchWords}
            use={use}
            callback={reload}
            actions={actions}
            overrideSm={overrideSm}
          />
        ))}
      </Box>
      {!disableAdding && (
        <Box width="100%" display="flex" flex="1" justifyContent="center">
          {!_.isNil(payload) && (
          <ModelActionButton
            type="add"
            model={model}
            variant="fabh1"
            tooltip={addTooltip}
            callback={reload}
            initData={initData}
          />
          )}
        </Box>
      )}
    </Box>
  );
});
