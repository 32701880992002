import { Box } from "@mui/material";
import _ from "lodash";
import React from "react";

import { localStorageDict } from "../../../../services/enums";
import { useLocalStorage } from "../../../../services/hooks";
import { assetUrl } from "../../../../services/routes";
import { LanguageSwitchButton } from "../../buttons";

export default React.memo(() => {
  const [theme] = useLocalStorage(localStorageDict.THEME.NAME, localStorageDict.THEME.VALUES.LIGHT);
  const [hostConfig] = useLocalStorage(localStorageDict.HOST_CONFIG, { name: "", website: "", features: [] });

  return (
    <Box flex="1" style={{ textAlign: "center", marginBottom: 10, marginTop: 10 }}>
      <a href={hostConfig.website}>
        <img
          src={_.isEqual(theme, localStorageDict.THEME.VALUES.DARK) ? assetUrl("logo_282x130_white.png") : assetUrl("logo_282x130_black.png")}
          alt="LOGO"
          style={{ maxWidth: 200 }}
        />
      </a>
      <Box position="absolute" top={0} right={0} display="flex" height="40px">
        <LanguageSwitchButton />
      </Box>
    </Box>
  );
});
