import { addDays, addHours, format } from "date-fns";
import _ from "lodash";
import * as R from "ramda";

export default (data, rows, locale, startingDate = new Date(), isStatic = false) => {
  const utcHours = startingDate.getUTCHours();
  const sod = val => R.pipe(addDays, date => addHours(date, utcHours * -1))(startingDate, val);
  const eod = val => R.pipe(addDays, date => addHours(date, 24 - utcHours))(startingDate, val);

  const schema = _(rows).map(val => {
    const sodValue = sod(val);
    const eodValue = eod(val);
    const isoValue = addHours(isStatic ? addDays(startingDate, val) : sodValue, 1);
    isoValue.setUTCHours(12);
    const dateValue = addDays(startingDate, val);
    dateValue.setUTCHours(12);

    return {
      "isoStart": sodValue.toISOString(),
      "isoEnd": eodValue.toISOString(),
      "iso": isoValue.toISOString(),
      "date": format(dateValue, "dd.MM.yyyy", { locale }),
      "day": format(dateValue, "i", { locale }),
      "present": isStatic ? false : val === 0,
      "items": [],
    };
  })
    .value();

  _(data).each(item => {
    _(schema).each(val => {
      if (_.isEqual(val.date, format(new Date(item.date), "dd.MM.yyyy", { locale }))) {
        val.items.push(item);
      }
    });
  });

  _(schema).each(item => {
    item.items = _.orderBy(item.items, ["order"], ["asc"]);
  });

  return schema;
};
