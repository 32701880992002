import _ from "lodash";

/**
 * @param {Object} user user object
 *
 * @returns {String} user's name depending on the informations he provided
 */
export default (user, customNameField = null, customEmailField = null) => {
  if (_.isNil(user) || _.isEmpty(user)) {
    return "";
  }

  if (!_.isNil(customNameField) && !_.isNil(customEmailField)) {
    return _.isNil(user[customNameField]) ? user[customEmailField] : user[customNameField];
  }

  return _.isNil(user.name) || _.isEmpty(user.name) ? user.email.split("@")[0] : user.name;
};
