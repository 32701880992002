import { methods } from "../enums";
import { normalizeHost } from "../utils";
import { openAction } from ".";

export const loginAction = body => ["/login", methods.POST, body, false];
export const registerAction = body => ["/register", methods.POST, body, false];
export const emailChangeConfirmationGet = hash => ({
  method: methods.GET,
  headers: {
    "Content-Type": "application/json",
  },
  endpoint: `/email-change-confirmation/${hash}`,
});
export const emailConfirmationGet = hash => ({
  method: methods.GET,
  headers: {
    "Content-Type": "application/json",
  },
  endpoint: `/email-confirmation/${hash}`,
});
export const openAssetGet = name => ({
  method: methods.GET,
  endpoint: `/asset/${normalizeHost(window.location.host)}/${name}`,
});
export const remindPasswordAction = body => ["/remind-password", methods.POST, body, false];
export const newPasswordAction = body => ["/new-password", methods.POST, body, false];
export const newCompetitorPost = body => ["/competition/new-competitor", methods.POST, body, false];
export const stripeLoginGet = (accountId, sessionId) => openAction(`/stripe-login/${accountId}/${sessionId}`);
