/* eslint-disable react/no-danger */
import React from "react";

export default ({ text }) => (
  <div dangerouslySetInnerHTML={{
    __html: text
      .replaceAll("[b]", "<b>")
      .replaceAll("[/b]", "</b>")
      .replaceAll("[h1]", "<h1 style='margin-top: 0px; margin-bottom: 0px'>")
      .replaceAll("[/h1]", "</h1>")
      .replaceAll("[h2]", "<h2 style='margin-top: 0px; margin-bottom: 0px'>")
      .replaceAll("[/h2]", "</h2>")
      .replaceAll("[center]", "<center>")
      .replaceAll("[/center]", "</center>"),
  }}
  />
);
