import {
  Box, Chip, FormControl, TextField,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, fieldName,
  label, disabled = false, variant = "outlined",
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();
  const [inputValue, setInputValue] = React.useState("");

  const handleDelete = item => () => {
    const newItems = [...form[fieldName]];
    newItems.splice(newItems.indexOf(item), 1);
    setFieldValue(fieldName, [...newItems]);
  };

  return (
    <FormControl style={inputStyle.select()}>
      <TextField
        multiline
        variant={variant}
        disabled={disabled}
        type="text"
        error={isFieldError(fieldName)}
        helperText={fieldErrorMessage(fieldName) || t("type_and_press_enter_to_add")}
        label={t(label)}
        name={fieldName}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onKeyUp={event => {
          const tag = event.target.value.trim();

          if (event.key === "Enter" && tag.length > 0) {
            setInputValue("");
            if (tag.length > 0) {
              setFieldValue(fieldName, _.isNil(form[fieldName]) ? [tag] : [...form[fieldName], tag]);
            }
          }
        }}
        InputProps={{
          startAdornment: <Box display="flex" flexWrap="wrap">
            {" "}
            {!_.isNil(form[fieldName]) && form[fieldName].map(item => (
              <Chip
                key={item}
                label={item}
                onDelete={handleDelete(item)}
                style={{ margin: 1 }}
              />
            ))}
          </Box>,
        }}
      />
    </FormControl>
  );
};
