import { base64MimeType } from "../../../../services/utils";

export default (t, enqueueSnackbar, setFieldValues, type, setName, file) => e => {
  const mime = base64MimeType(e.target.result);

  if (type === "image" && mime.indexOf("image") === -1 && mime !== "video/mp4") {
    enqueueSnackbar(t("invalid_media_format_available_any_image_or_mp4_video"), { variant: "warning" });
  } else if (type === "pdf" && mime !== "application/pdf") {
    enqueueSnackbar(t("invalid_media_format_must_be_pdf"), { variant: "warning" });
  } else {
    setFieldValues({
      data: e.target.result,
      mime,
    });
    setName(file.name);
    enqueueSnackbar(t("file_has_been_successfully_selected"), { variant: "success" });
  }
};
