import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

/**
 * Resolves description by theme breakpoint
 * @param {Object} descs Style description
 * @returns {Object} { select }
 */
export default (descs, force = null) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));
  const md = useMediaQuery(theme.breakpoints.only("md"));
  const lg = useMediaQuery(theme.breakpoints.only("lg"));
  const xl = useMediaQuery(theme.breakpoints.only("xl"));

  const select = React.useCallback((forcedWidth = null) => {
    if (force) {
      return descs[force];
    }

    if (forcedWidth) {
      return descs[forcedWidth];
    }

    if (xs) {
      return descs.xs;
    }
    if (sm) {
      return descs.sm;
    }
    if (md) {
      return descs.md;
    }
    if (lg) {
      return descs.lg;
    }
    if (xl) {
      return descs.xl;
    }

    return descs.default;
  }, [xs, sm, md, lg, xl]);

  return { select };
};
