import { FormControl, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGenericInputStyle } from "../../../../services/hooks";

export default ({
  form, isFieldError, fieldErrorMessage, setFieldValue, fieldName, label, minRows, maxRows,
}) => {
  const { t } = useTranslation();
  const { inputStyle } = useGenericInputStyle();

  return (
    <FormControl style={inputStyle.select()}>
      <TextField
        multiline
        minRows={minRows}
        maxRows={maxRows}
        type="text"
        error={isFieldError(fieldName)}
        helperText={fieldErrorMessage(fieldName)}
        label={t(label)}
        name={fieldName}
        value={form[fieldName]}
        onChange={e => setFieldValue(fieldName, e.target.value)}
      />
    </FormControl>
  );
};
