import { methods } from "../enums";
import { apiAction } from ".";

export const customerDataPost = data => ["/customer", methods.POST, data];
export const customerDataPut = data => [`/customer/${data._id}`, methods.PUT, data];
export const customerDelete = id => [`/customer/${id}`, methods.DELETE];
export const customerOneGet = id => apiAction(`/customer/one/${id}`);
export const customerFilterGet = (search = "", page = 1, limit = 100, sort = "", filter = "") => apiAction(
  `/customer/filter?search=${search}&page=${page}&limit=${limit}&sort=${sort}&filter=${filter}`,
);
