import {
  Button, DialogActions, ThemeProvider, useMediaQuery,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { confirmable } from "react-confirm";

export default confirmable(props => {
  const fullScreen = useMediaQuery(props.theme.breakpoints.down("xs"));

  return (
    <ThemeProvider theme={props.theme}>
      <Dialog
        fullScreen={fullScreen}
        fullWidth
        maxWidth="xs"
        open={props.show}
        onClose={() => props.proceed(0)}
      >
        <DialogTitle style={{ wordBreak: "break-word" }}>{props.t(props.title)}</DialogTitle>
        <DialogActions>
          {props.buttons.map((button, idx) => <Button key={`option_${idx + 1}`} onClick={() => props.proceed(idx + 1)} color="primary" variant="contained" aria-label="confirm">{props.t(button)}</Button>)}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
