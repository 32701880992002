import { Chip, Typography } from "@mui/material";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";

import { subscriptionDelete } from "../../../services/api/subscription";
import { models } from "../../../services/enums";
import { useCommonDateFormats } from "../../../services/hooks";
import { createUserDisplayName, safeUpperCase } from "../../../services/utils";
import { ItemFooter, ItemHeaderWithHighlight } from "../../shared/other";
import { ModelItemTemplate } from "../../shared/templates";

export default ({
  item, highlight = [], callback, use,
}) => {
  const { t } = useTranslation();
  const { formatWholeDate } = useCommonDateFormats();

  return (
    <ModelItemTemplate
      inModal={_.isFunction(use)}
      header={<ItemHeaderWithHighlight name={createUserDisplayName(item.customer.user)} highlight={highlight} />}
      body={(
        <>
          <Typography variant="caption">
            {t("from")}
            :
            {formatWholeDate(item.start)}
          </Typography>
          <Typography variant="caption" color={item.isActive ? "inherit" : "error"}>
            {t("to")}
            :
            {formatWholeDate(item.end)}
          </Typography>
        </>
)}
      footer={(
        <ItemFooter
          item={item}
          callback={callback}
          use={use}
          model={models.SUBSCRIPTION}
          modelDelete={subscriptionDelete}
          chip={<Chip padding="0" color="secondary" size="small" label={safeUpperCase(item.plan.name)} />}
        />
)}
    />
  );
};
