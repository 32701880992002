import { Box, Chip } from "@mui/material";
import _ from "lodash";
import React from "react";
import { Droppable } from "react-beautiful-dnd";

import { useSizeStyle } from "../../../services/hooks";
import { safeUpperCase } from "../../../services/utils";
import { EntryCoachBox } from "../../entry";
import ColHeader from "./_col-header";
import DroppableBox from "./_droppable-box";

export default React.memo(({
  plan, planIdx, reload, data, rows, canEdit = true, isShared = false, planCallback,
}) => {
  const { select } = useSizeStyle({
    xs: "0 0 100%", sm: "0 0 100%", md: "0 0 25%", lg: "0 0 20%", xl: "0 0 20%",
  });

  return data.map((col, colIdx) => (
    <Box
      key={`${plan.name}_col_${colIdx}`}
      display="flex"
      flex={select()}
      flexDirection="column"
      alignItems="center"
      position="relative"
      flexGrow={1}
    >
      <ColHeader plan={plan} col={col} colIdx={colIdx} rows={rows} canEdit={canEdit} planCallback={planCallback} />
      <Droppable
        key={`${plan._id}_${col.date}_${colIdx}`}
        droppableId={JSON.stringify({
          plan, col, colIdx, planIdx,
        })}
        type="entry"
      >
        {(provided, snapshot) => (
          <DroppableBox
            body={(
              <>
                {col.items.map((entry, entryIdx) => (
                  <EntryCoachBox
                    key={`${entry._id}_${colIdx}_${entryIdx}`}
                    entry={entry}
                    plan={plan}
                    idx={entryIdx}
                    callback={reload}
                    canEdit={canEdit}
                    isShared={isShared}
                    chip={!_.isNil(entry.competitionCategory) && <Chip padding="0" color="secondary" size="small" label={safeUpperCase(entry.competitionCategory)} />}
                  />
                ))}
                {provided.placeholder}
              </>
            )}
            provided={provided}
            snapshot={snapshot}
          />
        )}
      </Droppable>
    </Box>
  ));
});
