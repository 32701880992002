import { methods } from "../enums";
import { apiAction, openAction } from ".";

export const userMeGet = () => apiAction("/user/me");
export const userMeWeightsGet = () => apiAction("/user/me-weights");
export const userMePut = data => ["/user/me", methods.PUT, data];
export const userPanelCustomerGet = () => apiAction("/user/panel/customer");
export const userUpdateAvatarDataPut = data => ["/user/update/avatar", methods.PUT, data];
export const userUpdateBgDataPut = data => ["/user/update/bg", methods.PUT, data];
export const userUpdateLanguagePut = data => ["/user/update/language", methods.PUT, data];
export const userUpdateThemePut = data => ["/user/update/theme", methods.PUT, data];
export const userUpdateRoleCoachPut = () => ["/user/update/role-coach", methods.PUT, {}];
export const userUpdateRoleCustomerPut = () => ["/user/update/role-customer", methods.PUT, {}];
export const userChangePasswordPut = data => ["/user/me/change-password", methods.PUT, data];
export const userChangeEmailPut = data => ["/user/me/change-email", methods.PUT, data];
export const userRefreshTokenGet = () => apiAction("/user/refresh-token");
export const userShopByClientNumberGet = clientNumber => openAction(`/user/shop/${clientNumber}`);
export const userStripeAuthorizeGet = () => apiAction("/user/stripe/authorize");
export const userStripeOnboardingGet = () => apiAction("/user/stripe/onboarding");
export const userStripeLoginLinkGet = () => apiAction("/user/stripe/login-link");
export const userStripeStatusGet = () => apiAction("/user/stripe/status");
