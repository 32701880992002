import { Alert, Box } from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";

import { intervals } from "../../../../../services/enums";
import { useAuthenticated, useSizeStyle } from "../../../../../services/hooks";
import { createFuncDef, createUserDisplayName } from "../../../../../services/utils";
import { MediaPreviewPanel } from "../../../../media";
import { MessageButton } from "../../../../message";
import { UrlifiedTypography } from "../../../../shared/other";
import { PreviewDialogTemplate } from "../../../../shared/templates";
import PlanBoxNote from "../PlanBoxNote";
import PlanBoxNotes from "../PlanBoxNotes";
import PlanBoxScoreCard from "../PlanBoxScoreCard";
import PlanBoxScoresCard from "../PlanBoxScoresCard";

export default React.memo(({ actions = {} }) => {
  const LeftBoxFlex = useSizeStyle({
    xs: "1 0 100%", sm: "1 0 100%", md: "2 0", lg: "2 0", xl: "2 0",
  });
  const LeftBoxStyle = useSizeStyle({
    xs: {}, sm: {}, md: { marginLeft: "20px" }, lg: { marginLeft: "20px" }, xl: { marginLeft: "20px" },
  });
  const dialogActions = {};
  const [plan, setPlan] = React.useState({});
  const [entry, setEntry] = React.useState({});
  const [shift, setShift] = React.useState(0);
  const [callback, setCallback] = useState(createFuncDef(() => {}));
  const { getUserData } = useAuthenticated();

  actions.open = (plan, entry, shift, callbackFunc = createFuncDef(() => {})) => {
    setPlan(plan);
    setEntry(entry);
    setShift(shift);
    dialogActions.open();
    setCallback(createFuncDef(callbackFunc));
  };

  return (
    <PreviewDialogTemplate
      onCloseCallbacks={[callback]}
      actions={dialogActions}
      title={entry.title}
      titleButtons={<MessageButton entryId={entry?._id} subordinateId={getUserData()._id} userName={_.isNil(plan) ? "" : createUserDisplayName(plan?.user || "")} />}
      isBoldTitle
      content={(
        <Box flex="1 0" display="flex" flexWrap="wrap">
          <Box sx={{ position: "relative" }} flex={LeftBoxFlex.select()}>
            <UrlifiedTypography
              variant="h6"
              style={{
                whiteSpace: "pre-line", width: "100%", marginBottom: "20px", wordBreak: "break-word",
              }}
            >
              {entry.content}
            </UrlifiedTypography>
            {!_.isEmpty(entry.comment) && (
            <Box sx={{ position: "relative" }} minWidth="200px">
              <Alert
                icon={false}
                severity="info"
                style={{
                  whiteSpace: "pre-line", width: "100%", marginBottom: "10px", wordBreak: "break-word",
                }}
              >
                <UrlifiedTypography>{entry.comment}</UrlifiedTypography>
              </Alert>
            </Box>
            )}
            <Box flex="1 0" display="flex" flexWrap="wrap" sx={{ marginBottom: "20px", position: "relative" }}>
              {!_.isNil(entry.media) && !_.isEmpty(entry.media[0]) &&
                <MediaPreviewPanel data={entry.media} coach={false} />}
            </Box>
            <Box flex="1 0" display="flex" flexDirection="column">
              <PlanBoxNote actions={actions} entryId={entry._id} isCompetition={plan.interval === intervals.COMPETITION} />
              <PlanBoxNotes actions={actions} entryId={entry._id} isCompetition={plan.interval === intervals.COMPETITION} />
            </Box>
          </Box>
          {entry.isScore && (
            <Box flex="1 0" sx={LeftBoxStyle.select()}>
              <Box flex="1 0" display="flex" flexDirection="column">
                <PlanBoxScoreCard actions={actions} entry={entry} plan={plan} shift={shift} />
              </Box>
              {entry.isTieBreak && (
              <Box flex="1 0" display="flex" flexDirection="column">
                <PlanBoxScoreCard actions={actions} entry={entry} plan={plan} shift={shift} isTieBreak title="tie_break" />
              </Box>
              )}
              <PlanBoxScoresCard actions={actions} entry={entry} plan={plan} shift={shift} />
            </Box>
          )}
        </Box>
      )}
    />
  );
});
