import PowerSettingsNewSharpIcon from "@mui/icons-material/PowerSettingsNewSharp";
import { ListItemIcon, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { useTranslation } from "react-i18next";

import { localStorageDict } from "../../../../services/enums";
import { writeStorage } from "../../../../services/utils";

export default React.memo(() => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = () => {
    writeStorage(localStorageDict.JWT, null);
    writeStorage(localStorageDict.ROLE, null);
    writeStorage(localStorageDict.ROLE_BACK, null);
    enqueueSnackbar(t("logout_successfully"), { variant: "success" });
  };

  return (
    <MenuItem onClick={handleSubmit}>
      <ListItemIcon>
        <PowerSettingsNewSharpIcon color="secondary" fontSize="small" />
      </ListItemIcon>
      {t("logout")}
    </MenuItem>
  );
});
